import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import Feature1 from './../../assets/images/diwali.jpg';
import icatImage from './../../assets/images/icat.jpg';
import odImage from './../../assets/images/od.jpg';
import ogImage from './../../assets/images/og.jpg';
import tmpImage from './../../assets/images/tmp.jpg';

import * as ArEvents from './../../Constants/EventDetails';



export default function ArCarousel() {

    return (
        <Carousel autoPlay={true} infiniteLoop={true} showThumbs={false} showStatus={false} >
            <div>
                <img src={icatImage} alt="iCAT" style={{ width: "100%" }}/>
            </div>
            <div>
                <img src={odImage} alt="OD" style={{ width: "100%" }}/>
            </div>
            <div>
                <img src={ogImage} alt="OG" style={{ width: "100%" }}/>
            </div>
            <div>
                <img src={tmpImage} alt="TMP" style={{ width: "100%" }}/>
            </div>
        </Carousel>
    );
}