//All the API constants should be here

//Hosting path
//export const ApiHostingPath = "https://localhost:44314/";
//export const ApiHostingPath = "https://app-arfinancials-dev.azurewebsites.net/";
export const ApiHostingPath = "https://indanetrader.com/";

//User Related Apis
export const UserApis = {
    ValidateAccessPath: ApiHostingPath + "api/User/ValidateAccess",
    GetAllUsersPath : ApiHostingPath + "api/User/GetAllUsers",
    ValidateUserPath: ApiHostingPath + "api/User/ValidateUser",
    ForgotPasswordPath: ApiHostingPath + "api/User/ForgotPassword",
    ResetPasswordPath: ApiHostingPath + "api/User/ResetPassword",
    AddUserPath: ApiHostingPath + "api/User/AddUser",
    UpdateUserPath: ApiHostingPath + "api/User/UpdateUser",
};

export const CourseApis = {
    AddVideoPath: ApiHostingPath + "api/Courses/AddVideo",
    GetAllVideosPath: ApiHostingPath + "api/Courses/GetAllVideos",
    GetVideosByUser: ApiHostingPath + "api/Courses/GetVideosByUser",
    AddVideoAccessPath: ApiHostingPath + "api/Courses/AddVideoAccess",
    GetVideoAccessesByUserId: ApiHostingPath + "api/Courses/GetVideoAccessesByUserId",
    UpdateVideoAccess: ApiHostingPath + "api/Courses/UpdateVideoAccess",
    UpdateVideoPath: ApiHostingPath + "api/Courses/UpdateVideo",
};

export const EventApis = {
    AddEventPath: ApiHostingPath + "api/Events/AddEvent",
    GetAllEventsPath: ApiHostingPath + "api/Events/GetAllEvents",
    UpdateEventPath: ApiHostingPath + "api/Events/UpdateEvent",
    GetEventByNamePath: ApiHostingPath + "api/Events/GetEventByName",
}