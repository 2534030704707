
import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { isMobile } from "react-device-detect";
import axios from 'axios';

import { SectionProps } from './../utils/SectionProps';
import * as ArEvents from './../Constants/EventDetails';

import * as ApiConstants from './../Constants/ApiConstants'
import { Grid } from '@mui/material';
import CoachImg from './../assets/images/santosh.png';
import IcatBoxImg from './../assets/images/icat-box.png';
import MoneyBackImg from './../assets/images/money-back.png';
import Testimonial from '../components/sections/Testimonial';
import Review from '../components/sections/Review';


import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';

import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const propTypes = {
    ...SectionProps.types
}

const defaultProps = {
    ...SectionProps.defaults
}

var whatsAppRef = "https://web.whatsapp.com/send?phone='" + ArEvents.ContactDetails.Phone + "'&text=Hi Indane Trader";
if (isMobile) whatsAppRef = "whatsapp://send?phone='" + ArEvents.ContactDetails.Phone.replace(" ", "") + "'&text=Hi Indane Trader";

const Icat = ({
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    ...props
}) => {

    useEffect(() => {
        const anchor = (document).querySelector('#back-to-top-anchor');

        if (anchor) {
            anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
        GetIcatDetails();
    }, []);

    const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    const outerClasses = classNames(
        'hero section center-content',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        className
    );

    const innerClasses = classNames(
        'hero-inner section-inner',
        topDivider && 'has-top-divider',
        bottomDivider && 'has-bottom-divider'
    );

    const [icatEventdetails, setIcatEventdetails] = useState({
        eventPaymentLink: "",
        eventStartDate: "",
        eventStartTime: "",
        eventEndDate: "",
        eventEndTime: "",
        eventCourseFee: "",
        eventDiscountCourseFee: "",
        eventCoupen: "",
    });

    const GetIcatDetails = () => {
        let header = {
            params: { 'eventName': "icat" }
        };
        axios.get(ApiConstants.EventApis.GetEventByNamePath, header)
            .then(response => {
                let eventdata = response.data
                if (response.data != "") {
                    let startDate = (eventdata.eventStartDate != "") ? (new Date(eventdata.eventStartDate).getDate() + " " + (months[new Date(eventdata.eventStartDate).getMonth()]) + ", " + new Date(eventdata.eventStartDate).getFullYear() + "(" + days[new Date(eventdata.eventStartDate).getDay()] + ")") : "";
                    let endDate = (eventdata.eventEndDate != "") ? (new Date(eventdata.eventEndDate).getDate() + " " + (months[new Date(eventdata.eventEndDate).getMonth()]) + ", " + new Date(eventdata.eventEndDate).getFullYear() + "(" + days[new Date(eventdata.eventEndDate).getDay()] + ")") : "";
                    let startTime = (eventdata.eventStartTime != "") ? (new Date(eventdata.eventStartTime).getHours()).toString() + ":" + (new Date(eventdata.eventStartTime).getMinutes()).toString() : "";
                    let endTime = (eventdata.eventEndTime != "") ? (new Date(eventdata.eventEndTime).getHours()).toString() + ":" + (new Date(eventdata.eventEndTime).getMinutes()).toString() : "";
                    setIcatEventdetails({
                        eventPaymentLink: eventdata.eventPaymentLink,
                        eventStartDate: startDate,
                        eventStartTime: tConvert(startTime),
                        eventEndDate: endDate,
                        eventEndTime: tConvert(endTime),
                        eventCourseFee: eventdata.eventCourseFee,
                        eventDiscountCourseFee: eventdata.eventDiscountCourseFee,
                        eventCoupen: eventdata.eventCoupen,
                    });
                }


            })
            .catch(error => {
                console.log(error);
            });
    }

    function tConvert(time) {
        // Check correct time format and split into components
        let tt = time.split(":");
        if (tt.length > 1) {
            if (tt[1].length == 1) {
                tt[1] = "0" + tt[1];
            }
            if (tt[0].length == 1) {
                tt[0] = "0" + tt[0];
            }
            time = tt.join(":");
        }
        time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

        if (time.length > 1) { // If time format correct
            time = time.slice(1);  // Remove full string match value
            time[5] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
            time[0] = +time[0] % 12 || 12; // Adjust hours
        }
        return time.join(''); // return adjusted time or original string
    }

    return (
        <section
            {...props}
            className={outerClasses}
        >
            <div className="container-sm">
                <div className={innerClasses}>
                    <div className="hero-content">
                        <div className="container-sm">
                            <h2 className="text-color-primary">I Can Also Trade</h2>
                            <img src={IcatBoxImg} width="60%" alt="" />
                            <h3 className="text-color-secondary">A Simple Guide To Start Learning Trading In Stock Market </h3>


                            {
                                (icatEventdetails.eventStartDate != "") ?
                                    <h4>
                                        <span className="text-color-primary" >{icatEventdetails.eventStartDate + " to " + icatEventdetails.eventEndDate}</span>
                                        <br />
                                        {icatEventdetails.eventStartTime + " to " + icatEventdetails.eventEndTime}
                                    </h4>
                                    : null
                            }

                            <h5 className="text-color-secondary">You need to spend just 15 minutes per day for Trading</h5>

                            {
                                (icatEventdetails.eventCourseFee != "") ?
                                    <div>
                                        <h3 style={{ color: "red" }}><span className="strikethrough">{"₹" + icatEventdetails.eventCourseFee + "/-"}</span></h3>
                                        <h2 style={{ color: "limegreen", marginBottom: "2px" }}>{"₹" + icatEventdetails.eventDiscountCourseFee + "/-"}</h2>
                                        {/*<span style={{ color: "limegreen" }}><b>Including GST</b></span>*/}
                                        <h4 className="blink" style={{ color: "limegreen" }}>Offer available only for limited period</h4>
                                    </div>
                                    : null
                            }

                            {
                                (icatEventdetails.eventPaymentLink != "") ?
                                    <>
                                        <a className="button button-primary button-wide-mobile button-sm" href={icatEventdetails.eventPaymentLink} target="_blank">Enroll Now</a>
                                        <br />
                                        <img src={MoneyBackImg} alt="" width="15%" />
                                    </>
                                    :
                                    <EnquiryNow />
                            }

                            <br /><br />

                            <div>
                                <h3 className="text-color-secondary">
                                    Exclusive <span className="text-color-primary" >1to1</span> coaching for <span className="text-color-primary"><span className="cursive-italic">i</span>CAT</span> as per your convenience.
                                </h3>
                                <h3 style={{ color: "red" }}><span className="strikethrough">{"₹19999/-"}</span></h3>
                                <h2 style={{ color: "limegreen", marginBottom: "2px" }}>{"₹7999/-"}</h2>
                                <h4 className="blink" style={{ color: "limegreen" }}>Offer available only for limited period</h4>
                                <div>
                                    Contact here
                                    &nbsp;
                                    <Tooltip title="WhatsApp" placement="bottom" arrow>
                                        <a href={whatsAppRef} target="_blank" >
                                            <WhatsAppIcon style={{ fill: 'limegreen' }} />

                                        </a>
                                    </Tooltip>
                                    &nbsp;
                                    to confirm your schedule before paying.
                                </div>
                                <br />
                                <a className="button button-primary button-wide-mobile button-sm" href="https://imjo.in/Em7qze" target="_blank">Pay Now</a>
                                <br />
                                <img src={MoneyBackImg} alt="" width="15%" />
                            </div>


                        </div>
                        <br /><br /><br />
                        <div className="container-sm">
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <h1 className="text-color-primary">About Coach</h1>
                                </Grid>
                                <Grid item xs={12} className="text-center">
                                    <img style={{ width: "30%" }} src={CoachImg} />
                                </Grid>
                                <Grid item xs={12}>
                                    <h3 className="text-color-secondary">Santosh Choudhury</h3>
                                    <p>
                                        His superior knowledge and remarkable skill to simplify complex trading to school math that a 5th standard student can say <b className="text-color-primary">I can also Trade</b>.
                                    </p>
                                    <p>
                                        With the vision to provide the highest standard of powerful knowledge on World Class Trading in a simplest way. His mission begins with countries biggest Stock Market movement to coach
                                        <b className="text-color-primary"> 1 Million</b> people to take full control of their financial future.
                                    </p>
                                    <h4 className="text-color-secondary">Welcome to a New Journey</h4>
                                    <h4 className="text-color-secondary"><i>"Let's do a Business, Not Gambling"</i></h4>
                                    {
                                        (icatEventdetails.eventPaymentLink != "") ?
                                            <>
                                                <a className="button button-primary button-wide-mobile button-sm" href={icatEventdetails.eventPaymentLink} target="_blank">Enroll Now</a>
                                                <br />
                                                <img src={MoneyBackImg} alt="" width="15%" />
                                            </>
                                            : <EnquiryNow />
                                    }
                                </Grid>

                            </Grid>



                        </div>
                        <br /><br /><br />
                        <div className="container-sm">
                            <h2 className="text-color-primary">What you can learn ?</h2>

                            <h4>PROFESSIONAL TRADER MINDSET</h4>
                            <p>Training on Mindset to take control over your emotions (Greed & Fear) on your trades.</p>
                            <br />

                            <h4>THE SIMPLEST RISK MANAGEMENT FORMULA</h4>
                            <p>Learn the simplest Risk Management formula that can help you to take Calculated Risks.</p>
                            <br />

                            <h4>TRADING STRATEGIES IN EQUITY SEGMENT</h4>
                            <p>
                                Learn simple systematic strategies to find Entry & Exit.
                            </p>
                            <br />

                            <h4>WEALTH CREATION STRATEGY</h4>
                            <p>Learn to create a Passive source of Income where your money makes money for you.</p>
                            <br />

                            <h4>REGULAR INCOME STRATEGY</h4>
                            <p>A short-term strategy to generate consistent Regular Income.</p>
                            <br />



                            <h4>SUPPORT</h4>
                            <p>
                                Q&A session with <i>Santosh Choudhury</i>
                                <br />
                                Doubt clearing session with <i>Santosh Choudhury</i>
                            </p>
                            <br />

                            {/*<h4>EXTRA BENEFITS</h4>*/}
                            {/*<p className="text-color-secondary blink">*/}
                            {/*    <b>Free Lifetime Access to Basics of Stock Market Course (Bonus).</b>*/}
                            {/*    <br />*/}
                            {/*    <b>Free Lifetime Access to Basics of Futures & Options Course (Bonus).</b>*/}
                            {/*    <br />*/}
                            {/*    <b>One Mastermind Session for Market Analysis (Bonus).</b>*/}
                            {/*</p>*/}
                            <br />
                            {
                                (icatEventdetails.eventPaymentLink != "") ?
                                    <>
                                        <a className="button button-primary button-wide-mobile button-sm" href={icatEventdetails.eventPaymentLink} target="_blank">Enroll Now</a>
                                        <br />
                                        <img src={MoneyBackImg} alt="" width="15%" />
                                    </>
                                    : <EnquiryNow />
                            }

                           
                        </div>

                    </div>
                </div>
            </div>
            <br />
            <Review />
            <br />
            <Testimonial topDivider />
        </section>
    );
}
Icat.propTypes = propTypes;
Icat.defaultProps = defaultProps;

export default Icat;

function EnquiryNow() {
    return (
        <a className="button button-primary button-wide-mobile button-sm" href={whatsAppRef} target="_blank" >Enquiry Now</a>
    );
}