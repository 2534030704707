
import React, { useEffect } from 'react';
import classNames from 'classnames';
import { isMobile } from "react-device-detect";

import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';

import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

import { SectionProps } from '../../utils/SectionProps';
import * as ArEvents from './../../Constants/EventDetails';




const propTypes = {
    ...SectionProps.types
}

const defaultProps = {
    ...SectionProps.defaults
}

const ContactUs = ({
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    ...props
}) => {

    useEffect(() => {
        const anchor = (document).querySelector('#back-to-top-anchor');

        if (anchor) {
            anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    });

    const classes = classNames(
        'footer-social',
        className
    );

    const outerClasses = classNames(
        'hero section center-content',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        className
    );

    const innerClasses = classNames(
        'hero-inner section-inner',
        topDivider && 'has-top-divider',
        bottomDivider && 'has-bottom-divider'
    );

    var whatsAppRef = "https://web.whatsapp.com/send?phone='" + ArEvents.ContactDetails.Phone + "'&text=Hi Indane Trader";
    var mailRef = "mailto:" + ArEvents.ContactDetails.Email;
    if (isMobile) whatsAppRef = "whatsapp://send?phone='" + ArEvents.ContactDetails.Phone.replace(" ", "") + "'&text=Hi Indane Trader";


    return (
        <section
            {...props}
            className={outerClasses}
        >
            <div className="container-sm">
                <div className={innerClasses}>
                    <div className="hero-content">
                        <div className="container-xs">
                            <div>
                                <h2>Contact Us</h2>
                                <p>
                                    Do you have any questions about anything or need any help?
                                    <br />
                                    Then, we’re here for you 24/7.
                                    <br />
                                    WhatsApp: {ArEvents.ContactDetails.Phone}
                                    <br />
                                    E-Mail: {ArEvents.ContactDetails.Email}
                                    <br /><br />
                                    <p className={classes}>
                                        <ul className="list-reset">
                                            <li>
                                                <Tooltip title="WhatsApp" placement="bottom" arrow>
                                                    <a href={whatsAppRef} target="_blank" >
                                                        <Avatar sx={{ m: 1, bgcolor: 'limegreen' }}>
                                                            <WhatsAppIcon style={{ fill: '#fff' }} />
                                                        </Avatar>
                                                    </a>
                                                </Tooltip>
                                            </li>
                                            <li>
                                                <Tooltip title="Mail" placement="bottom" arrow>
                                                    <a href={mailRef} target="_blank" >
                                                        <Avatar sx={{ m: 1, bgcolor: 'red' }}>
                                                            <MailOutlineIcon style={{ fill: '#fff' }} />
                                                        </Avatar>
                                                    </a>
                                                </Tooltip>
                                            </li>
                                        </ul>
                                    </p>
                                    <br />
                                    <i>
                                        Note: Our goal is to reply to your support ticket within 12
                                        hours, but depending on how busy we are, it may be a lot quicker
                                        or take a little longer.
                                    </i>
                                </p>
                                <br />
                                <p>
                                    <b>Address</b><br />
                                    <b className="text-color-primary">Indane Trader</b> <br />
                                    Plot no 106/219 <br />
                                    Tankua<br />
                                    Jeypore, Koraput<br />
                                    Pin Code - 764003<br />
                                    Odisha, India
                                </p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

ContactUs.propTypes = propTypes;
ContactUs.defaultProps = defaultProps;

export default ContactUs;