import React, { useState } from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import img1 from '../../assets/images/testinomials/1-SantoshNaik.jpeg';
import img2 from '../../assets/images/testinomials/2-DeepakPattnaik.jpg';
import img3 from '../../assets/images/testinomials/3-KaniskPadhi.jpg';

import vdo1 from './../../assets/videos/SantoshNayak.mp4';
import vdo2 from './../../assets/videos/DeepakPattnaik.mp4';
import vdo3 from './../../assets/videos/KaniskPadhi.mp4';


const propTypes = {
    ...SectionTilesProps.types
}

const defaultProps = {
    ...SectionTilesProps.defaults
}

const Testimonial = ({
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    pushLeft,
    ...props
}) => {

    

    const outerClasses = classNames(
        'testimonial section',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        className
    );

    const innerClasses = classNames(
        'testimonial-inner section-inner',
        topDivider && 'has-top-divider',
        bottomDivider && 'has-bottom-divider'
    );

    const tilesClasses = classNames(
        'tiles-wrap',
        pushLeft && 'push-left'
    );

    const sectionHeader = {
        title: 'Students\' Speak',
        paragraph: ''
    };

    const videosList = [vdo1, vdo2, vdo3];

    const [video, setVideo] = useState("");

    const [videoModalActive, setVideomodalactive] = useState(false);

    const openVideoModal = (e) => {
        e.preventDefault();
        let index = e.target.id;
        setVideomodalactive(true);        
        setVideo(videosList[index]);
        setTimeout(function () {
            var v = document.getElementById('videoplayer');
            v.play();
        }, 2000)
    }

    const closeModal = (e) => {
        e.preventDefault();
        setVideomodalactive(false);
    }


    return (
        <section
            {...props}
            className={outerClasses}
        >
            <div className="container">
                <div className={innerClasses}>
                    <SectionHeader data={sectionHeader} className="center-content" />
                    <div className={tilesClasses}>

                        <div className="tiles-item reveal-from-right" data-reveal-delay="200">
                            <div className="tiles-item-inner">
                                <div className="testimonial-item-content">
                                    <p className="text-sm mb-0">
                                        <img src={img1} alt="" id="0" onClick={openVideoModal} />
                                    </p>
                                </div>
                                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider text-center">
                                    <span className="testimonial-item-name text-color-high text-color-primary">Santosh Nayak | Bengaluru</span>
                                </div>
                            </div>
                        </div>

                        <div className="tiles-item reveal-from-bottom">
                            <div className="tiles-item-inner">
                                <div className="testimonial-item-content" >
                                    <p className="text-sm mb-0">
                                        <img src={img2} alt="" id="1" onClick={openVideoModal} />
                                    </p>
                                </div>
                                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider text-center">
                                    <span className="testimonial-item-name text-color-high text-color-primary">Deepak Pattnaik | Jeypore</span>
                                    {/*<span className="text-color-low"> / </span>*/}
                                    {/*<span className="testimonial-item-link">*/}
                                    {/*  <a href="#0">AppName</a>*/}
                                    {/*</span>*/}
                                </div>
                            </div>
                        </div>

                        <div className="tiles-item reveal-from-left" data-reveal-delay="200">
                            <div className="tiles-item-inner">
                                <div className="testimonial-item-content">
                                    <p className="text-sm mb-0">
                                        <img src={img3} alt="" id="2" onClick={openVideoModal}/>
                                    </p>
                                </div>
                                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider text-center">
                                    <span className="testimonial-item-name text-color-high text-color-primary">Kanisk Padhi | Pune</span>
                                    {/*<span className="text-color-low"> / </span>*/}
                                    {/*<span className="testimonial-item-link">*/}
                                    {/*  <a href="#0">AppName</a>*/}
                                    {/*</span>*/}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <Dialog
                open={videoModalActive}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogContent>
                    <IconButton
                        aria-label="close"
                        onClick={closeModal}
                        sx={{
                            position: 'absolute',
                            right: 0,
                            top: 0,
                            padding: 0,
                            color: "#000",
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <div>
                        <video id="videoplayer" autoplay controls >
                            <source src={video} type="video/mp4" />
                        </video>
                    </div>

                </DialogContent>
            </Dialog>

        </section>
    );
}

Testimonial.propTypes = propTypes;
Testimonial.defaultProps = defaultProps;

export default Testimonial;