import React, { useRef, useEffect } from 'react';
import { useSelector } from "react-redux"
import { useLocation, Switch, BrowserRouter as Router } from 'react-router-dom';
import AppRoute from './utils/AppRoute';
import ScrollReveal from './utils/ScrollReveal';
import ReactGA from 'react-ga';

// Layouts
import LayoutDefault from './layouts/LayoutDefault';

//Views 
import Home from './views/Home';
import AboutUs from './../src/components/sections/AboutUs';
import ContactUs from './../src/components/sections/ContactUs';
import Dashboard from './views/Dashboard';
import PrivacyPolicy from './views/PrivacyPolicy'; 
import Disclaimer from './views/Disclaimer';
import Icat from './views/Icat';
import ControlPanel from './views/ControlPanel';
import Books from './views/Books';
import Od from './views/Od';
import Og from './views/Og';
import Tmp from './views/Tmp';
import Login from './components/Login/login';
import Header from './../src/components/layout/Header'
import Terms from './views/Terms'

//Initialize Google Analytics
ReactGA.initialize(process.env.REACT_APP_GA_CODE);

const trackPage = page => {
    ReactGA.set({ page });
    ReactGA.pageview(page);
};

const App = () => {

    const childRef = useRef();
    let location = useLocation();

    var isLoggedIn = useSelector(state => state.userReducer);

    //console.log({ "isloggedin": isLoggedIn });

    //useEffect(() => {
    //    if (localStorage.getItem("LoggedInUser") === null) {
    //        sessionStorage.clear();
    //        isLoggedIn = false;
    //    }
    //}, []);

    useEffect(() => {
        const page = location.pathname;
        document.body.classList.add('is-loaded')
        childRef.current.init();
        trackPage(page);
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    return (
        <ScrollReveal
            ref={childRef}
            children={() => (
                <Router>                  
                    <Switch>
                        <AppRoute exact path="/" component={Home} layout={LayoutDefault} />
                        <AppRoute path="/about-us" component={AboutUs} layout={LayoutDefault} />
                        <AppRoute path="/contact-us" component={ContactUs} layout={LayoutDefault} />
                        <AppRoute path="/privacy-policy" component={PrivacyPolicy} layout={LayoutDefault} />
                        <AppRoute path="/disclaimer" component={Disclaimer} layout={LayoutDefault} />
                        <AppRoute path="/icat" component={Icat} layout={LayoutDefault} />
                        <AppRoute path="/Dashboard" component={Dashboard} layout={LayoutDefault} />
                        <AppRoute path="/control-panel" component={ControlPanel} layout={LayoutDefault} />
                        <AppRoute path="/books" component={Books} layout={LayoutDefault} />
                        <AppRoute path="/options-trading" component={Od} layout={LayoutDefault} />
                        <AppRoute path="/options-gladiator" component={Og} layout={LayoutDefault} />
                        <AppRoute path="/tmp" component={Tmp} layout={LayoutDefault} />
                        <AppRoute path="/terms" component={Terms} layout={LayoutDefault} />
                        <AppRoute path="/login" component={Login} layout={LayoutDefault} isUserLoggedIn={Header.isUserLoggedIn} />
                    </Switch>
                </Router>
            )}
        />
    );
}

export default App;