import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Image from '../../elements/Image';
import logo from '../../../assets/images/indane-trader-logo.png' //logo.jpeg

const Logo = ({
    className,
    ...props
}) => {

    const classes = classNames(
        'brand',
        className
    );

    return (
        <div
            {...props}
            className={classes}
        >
            <h3 className="m-0">
                <Link to="/">
                    <Image src={logo}
                        alt="AR Financials"
                        width={150}
                         />
                    {/*AR <span className="text-color-primary">Financials</span>*/}
                </Link>
            </h3>
        </div>
    );
}

export default Logo;