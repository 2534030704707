
import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { SectionProps } from './../utils/SectionProps';
import axios from 'axios';
import { useHistory } from "react-router-dom";

import * as ApiConstants from './../Constants/ApiConstants'

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import Breadcrumbs from '@mui/material/Breadcrumbs';

import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';

import CloseIcon from '@mui/icons-material/Close';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import icatImage from './../assets/images/icat.jpg';
import motImage from './../assets/images/mot.jpg';




const propTypes = {
    ...SectionProps.types
}

const defaultProps = {
    ...SectionProps.defaults
}

const Dashboard = ({
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    pushLeft,
    ...props
}) => {

    //var vdo = '<iframe src="https://player.vdocipher.com/playerAssets/1.x/vdo/embed/index.html#otp=20160313versASE3232nPiSvdPGypFHXUYlv5dBesCbrRDBCB7FqUQ3IVsNdznnQ&playbackInfo=eyJ2aWRlb0lkIjoiMTNjNzI4NDc3MDUxYzFjM2I0NzU0Mjg1M2UzM2U1ZTEifQ==" style="border:0;height:360px;width:640px;max-width:100%" allowFullScreen="true" allow="encrypted-media"></iframe>'

    var authHeader = {};
    const history = useHistory();

    var data = localStorage.getItem("userInfo");

    if (typeof (data) != 'undefined' && data != null) {
        let userInfo = JSON.parse(localStorage.getItem("userInfo"));
        authHeader = {
            headers: {
                'Authorization': `Bearer ${userInfo.token}`
            }
        }
    };

    //console.log(authHeader);


    const [videosList, setVideosList] = useState([]);
    const [videosByUser, setVideosByUser] = useState([]);

    useEffect(() => {
        //debugger;
        let user = localStorage.getItem("userInfo");
        if (typeof (user) != 'undefined' && user != null) {
            let userInfo = JSON.parse(localStorage.getItem("userInfo"));
            authHeader.params = { 'userId': userInfo.userID }

            axios.get(ApiConstants.CourseApis.GetVideosByUser, authHeader)
                .then(response => {
                    setVideosByUser(response.data);
                })
                .catch(error => {
                    console.log(error);
                    if (error.status === 401) {
                        localStorage.clear();
                        history.push("/");
                    }
                });
        }



    }, []);

    const outerClasses = classNames(
        'testimonial section',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        className
    );

    const innerClasses = classNames(
        'testimonial-inner section-inner',
        topDivider && 'has-top-divider',
        bottomDivider && 'has-bottom-divider'
    );

    const tilesClasses = classNames(
        'tiles-wrap',
        pushLeft && 'push-left'
    );

    const [showIcat, setShowIcat] = useState(false);
    const [showDefaultCourses, setShowDefaultCourses] = useState(true);



    const handleShowDasboard = () => {
        setShowIcat(false);
        setShowDefaultCourses(true);
        setBreadcrumbs(breadcrumbsDefault);
    }

    var breadcrumbsDefault = [
        <Link underline="hover" key="1" color="inherit" onClick={handleShowDasboard}>
            Dashboard
        </Link>
    ];

    const [breadcrumbs, setBreadcrumbs] = useState(breadcrumbsDefault);

    const handleShowIcat = () => {
        setShowIcat(true);
        setShowDefaultCourses(false);
        let newBreadcrumbs = [
            <Link underline="hover" key="1" color="inherit" onClick={handleShowDasboard}>
                Dashboard
        </Link>,
            <Link underline="hover" key="1" color="inherit">
                iCAT
        </Link>
        ];
        setBreadcrumbs(newBreadcrumbs);
        //get icat videos
        let iCatVideos = videosByUser.filter(el => el.courseName.toLowerCase() == "icat");
        setVideosList(iCatVideos);
    }

    const [video, setVideo] = useState("");

    const [videoModalActive, setVideomodalactive] = useState(false);

    const openVideoModal = (e) => {
        e.preventDefault();
        setVideomodalactive(true);

        let id = e.target.name;
        let item = videosList.filter(ele => ele.videoId == parseInt(id));
        if (item.length > 0) {
            setVideo(item[0].videoLink);
        } else {
            setVideo('Something went wrong while palying video');
        }
    }

    const closeModal = (e) => {
        e.preventDefault();
        setVideomodalactive(false);
    }

    return (
        <section
            {...props}
            className={outerClasses}
        >
            <div className="container">
                <div style={{ width: "100%", position: "fixed", padding: "20px 0px", backgroundColor: "#fff" }}>
                    <Stack spacing={2}>
                        <Breadcrumbs
                            separator={<NavigateNextIcon fontSize="small" />}
                            aria-label="breadcrumb"
                            className="text-color-primary"
                        >
                            {breadcrumbs}
                        </Breadcrumbs>
                    </Stack>
                </div>
            </div>
            <div className="container" style={{ minHeight: "500px" }}>
                <br />
                <br /><br />
                {
                    showDefaultCourses ?
                        <div className={tilesClasses} style={{ justifyContent: "left" }}>

                            <div className="tiles-item center-content clickable" onClick={handleShowIcat} >
                                <div className="tiles-item-inner">
                                    <img src={icatImage} alt="iCAT" />
                                    <div className="testimonial-item-footer mt-32 mb-0 has-top-divider">
                                        <span className="text-sm">iCAT</span>
                                    </div>
                                </div>
                            </div>

                        </div>

                        : null
                }

                {
                    showIcat ?
                        <div className={tilesClasses} style={{ justifyContent: "left" }}>
                            {

                                videosList.map((video) => (
                                    <>
                                        {
                                            (video.validity.toLowerCase() != "validity expired") ?
                                                <div className="tiles-item center-content clickable" name={video.videoId} onClick={openVideoModal} >
                                                    <div className="tiles-item-inner" name={video.videoId}>
                                                        <img style={{ width: "250px" }} src={(video.videoImageName != "" && video.videoImageName != null) ? (ApiConstants.ApiHostingPath + 'static/media/videos/' + video.videoImageName) : icatImage} alt="iCAT" name={video.videoId} />
                                                        <div className="testimonial-item-footer mt-32 mb-0 has-top-divider" name={video.videoId}>
                                                            <span className="text-sm" name={video.videoId}>{video.videoName}</span>
                                                            <br />
                                                            <span className="text-sm" name={video.videoId}><i name={video.videoId}>{video.validity}</i></span>
                                                        </div>
                                                    </div>
                                                </div>
                                                : null
                                        }
                                    </>

                                ))

                            }


                        </div>
                        : null
                }
            </div>




            <Dialog
                open={videoModalActive}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogContent>
                    <IconButton
                        aria-label="close"
                        onClick={closeModal}
                        sx={{
                            position: 'absolute',
                            right: 0,
                            top: 0,
                            padding: 0,
                            color: "#000",
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <div dangerouslySetInnerHTML={{ __html: video }}></div>

                </DialogContent>
            </Dialog>


        </section>
    );
}

Dashboard.propTypes = propTypes;
Dashboard.defaultProps = defaultProps;

export default Dashboard;