
import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import axios from 'axios';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';

import Grid from '@mui/material/Grid';
import { DataGrid } from '@mui/x-data-grid';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import { ThemeProvider } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';



import * as ApiConstants from './../../Constants/ApiConstants'
import * as ArEvents from './../../Constants/EventDetails'

import CloseIcon from '@mui/icons-material/Close';
import VideoSettingsIcon from '@mui/icons-material/VideoSettings';


const propTypes = {
    ...SectionProps.types
}

const defaultProps = {
    ...SectionProps.defaults
}

function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

const VideoAccess = ({
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    ...props
}) => {

    const outerClasses = classNames(
        'hero section center-content',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        className
    );

    var authHeader = {};

    if (localStorage.getItem("userInfo") != undefined) {
        let userInfo = JSON.parse(localStorage.getItem("userInfo"));
        authHeader = {
            headers: {
                'Authorization': `Bearer ${userInfo.token}`
            }
        }
    };

    var userInfo = {};

    let loggedInUser = localStorage.getItem("userInfo");

    if (loggedInUser != undefined) {
        userInfo = JSON.parse(loggedInUser);
    };


    useEffect(() => {
        GetAllVideos();
        GetAllUsers();
    }, []);

    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    const [errorMessage, setErrorMessage] = useState("");

    const [videos, setvideos] = useState([]);
    const [defaultVideos, setdefaultVideos] = useState([]);
    const [user, setUser] = useState("");
    const [users, setUsers] = useState([]);
    const [videoAccessId, setVideoAccessId] = useState(0);
    const [checked, setChecked] = React.useState([]);
    const [left, setLeft] = React.useState([]);
    const [right, setRight] = React.useState([]);

    const [videoValidityDetails, setVideoValidityDetails] = useState([]);

    const GetAllUsers = () => {
        axios.get(ApiConstants.UserApis.GetAllUsersPath, authHeader)
            .then(response => {
                setUsers(response.data);
            })
            .catch(error => {
                console.log(error);
            });
    };

    const GetAllVideos = () => {
        axios.get(ApiConstants.CourseApis.GetAllVideosPath, authHeader)
            .then(response => {
                let videos = [];
                let keyArr = [];
                response.data.forEach(function (video) {
                    let item = {
                        "id": video.videoId,
                        "courseName": video.courseName,
                        "videoName": video.videoName
                    }
                    let id = video.videoId;

                    videos.push(item);
                    keyArr.push(id);
                })
                setvideos(videos);
                setdefaultVideos(keyArr);
                setLeft(keyArr);
            })
            .catch(error => {
                console.log(error);
            });
    };

    const handleUserChange = (event) => {
        let userId = event.target.value;
        setUser(userId);
        authHeader.params = { 'userId': userId }

        axios.get(ApiConstants.CourseApis.GetVideoAccessesByUserId, authHeader)
            .then(response => {
                let res = response.data;
                let rightArr = [];
                let defaultVdeos = JSON.parse(JSON.stringify(defaultVideos));
                if (typeof (res) != 'undefinded' && res !== null && res !== "") {
                    setVideoAccessId(res.videoAccessId);
                    let videos = (res.userVideoAccessesJson != "") ? JSON.parse(res.userVideoAccessesJson) : [];

                    videos.forEach(function (ele) {
                        var index = defaultVdeos.indexOf(ele.videoId);
                        if (index !== -1) {
                            defaultVdeos.splice(index, 1);
                            rightArr.push(ele.videoId);
                        }
                    });
                    setLeft(defaultVdeos);
                    setRight(rightArr);
                    handleValidityData(videos);
                } else {
                    setVideoAccessId(0);
                    setLeft(defaultVdeos);
                    setRight(rightArr);
                }
            })
            .catch(error => {
                console.log(error);
            });

    }



    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const handleAllRight = () => {
        setRight(right.concat(left));
        setLeft([]);
    };

    const handleCheckedRight = () => {
        setRight(right.concat(leftChecked));
        setLeft(not(left, leftChecked));
        setChecked(not(checked, leftChecked));
    };

    const handleCheckedLeft = () => {
        setLeft(left.concat(rightChecked));
        setRight(not(right, rightChecked));
        setChecked(not(checked, rightChecked));
    };

    const handleAllLeft = () => {
        setLeft(left.concat(right));
        setRight([]);
    };

    const customList = (items) => (
        <Paper sx={{ width: 200, height: 230, overflow: 'auto' }}>
            <List dense component="div" role="list">
                {items.map((value) => {
                    const labelId = `transfer-list-item-${value}-label`;
                    const itemId = videos.findIndex(itm => itm.id === value);
                    const labelName = ((itemId != -1) ? (videos[itemId].courseName + " - " + videos[itemId].videoName) : "")
                    return (
                        <ListItem
                            key={value}
                            role="listitem"
                            button
                            onClick={handleToggle(value)}
                        >
                            <ListItemIcon>
                                <Checkbox
                                    checked={checked.indexOf(value) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                        'aria-labelledby': labelId,
                                    }}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={labelName} />
                        </ListItem>
                    );
                })}
                <ListItem />
            </List>
        </Paper>
    );

    const handleVideoAccessSaveUpdate = () => {
        let items = [];

        if (videoAccessId === 0) {
            right.forEach(function (ele) {
                items.push({ videoId: ele, validity: '' });
            });
            let videoAccess = {
                videoAccessId: 0,
                userId: user,
                userVideoAccessesJson: ((items.length > 0) ? JSON.stringify(items) : ''),
                createdby: userInfo.email,
                lastModifiedBy: ''
            }

            axios.post(ApiConstants.CourseApis.AddVideoAccessPath, videoAccess, authHeader)
                .then(response => {
                    console.log(response);
                    if (response.status === 200) {
                        setOpenVideoAddedSuccess(true);
                        handleValidityData(items);
                    }

                })
                .catch(error => {
                    console.log(error);
                    setErrorMessage("Something went wrong !! Kindly contact Admin");
                });
        } else {

            right.forEach(function (ele) {
                let index = videoValidityDetails.findIndex(itm => itm.videoId === ele);
                let validity = "";
                if (index > -1) {
                    validity = videoValidityDetails[index].validity;
                }
                items.push({ videoId: ele, validity: validity });
            });

            let videoAccessUpdate = {
                videoAccessId: videoAccessId,
                userVideoAccessesJson: ((items.length > 0) ? JSON.stringify(items) : ''),
                lastModifiedBy: userInfo.email
            }

            axios.post(ApiConstants.CourseApis.UpdateVideoAccess, videoAccessUpdate, authHeader)
                .then(response => {
                    console.log(response);
                    if (response.status === 200) {
                        setOpenVideoAddedSuccess(true);
                        handleValidityData(items);
                    }

                })
                .catch(error => {
                    console.log(error);
                    setErrorMessage("Something went wrong !! Kindly contact Admin");
                });
        }
    }

    const [openVideoAddedSuccess, setOpenVideoAddedSuccess] = useState(false);

    const handleCloseVideoAddedSuccess = () => {
        setOpenVideoAddedSuccess(false);
    }

    const handleValidityData = (videoAccessDetails) => {
        let videoValidity = [];
        videoAccessDetails.forEach(function (ele) {
            let itemId = videos.findIndex(itm => itm.id === ele.videoId);
            let videoName = ((itemId != -1) ? (videos[itemId].courseName + " - " + videos[itemId].videoName) : "");
            let expiry = (ele.validity == "") ? 'Lifetime Access' : handleExpiryText(ele.validity);
            videoValidity.push({
                videoId: ele.videoId,
                videoName: videoName,
                validity: ele.validity,
                expiry: expiry
            });
        });
        setVideoValidityDetails(videoValidity);
    }

    const handleVideoValidityDate = (videoid, value) => {
        let index = videoValidityDetails.findIndex(itm => itm.videoId === videoid);

        let items = [...videoValidityDetails];
        let item = { ...items[index] };
        item.validity = value;
        items[index] = item;
        setVideoValidityDetails(items);
    }

    const handleVideoValiditySave = () => {
        let items = [];
        videoValidityDetails.forEach(function (ele) {
            items.push({
                videoId: ele.videoId,
                validity: ele.validity
            });
        });
        let videoAccessUpdate = {
            videoAccessId: videoAccessId,
            userVideoAccessesJson: ((items.length > 0) ? JSON.stringify(items) : ''),
            lastModifiedBy: userInfo.email
        }

        axios.post(ApiConstants.CourseApis.UpdateVideoAccess, videoAccessUpdate, authHeader)
            .then(response => {
                console.log(response);
                if (response.status === 200) {
                    setOpenVideoAddedSuccess(true);
                    handleValidityData(items);
                }

            })
            .catch(error => {
                console.log(error);
                setErrorMessage("Something went wrong !! Kindly contact Admin");
            });
    }

    const handleExpiryText = (date) => {
        const date1 = new Date();
        const date2 = new Date(date);
        const diffTime = Math.abs(date2 - date1);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

        if (diffDays == 0) return "Validity expiring today";
        if (diffDays == 1) return "Validity expiring tomorrow";
        if (diffDays > 1) return "Validity expiring in " + diffDays + " days";
        return "Validity expired";
    }


    return (
        <section
            {...props}
            className={outerClasses}
        >

            <div className="container" style={{ background: "#fff", color: "#555" }}>
                <div style={{ minHeight: 300, width: '100%', padding: '20px 10px' }}>
                    <div>
                        <FormControl fullWidth margin="normal" >
                            <InputLabel id="courseNameLabel">User</InputLabel>
                            <Select
                                labelId="courseNameLabel"
                                id="courseName"
                                label="Course"
                                required
                                onChange={handleUserChange}
                                value={user}
                            >
                                {users.map((u) => (
                                    <MenuItem
                                        value={u.id}
                                    >
                                        {u.firstName}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    <div>
                        <Grid container spacing={2} justifyContent="center" alignItems="center">
                            <Grid item>{customList(left)}</Grid>
                            <Grid item>
                                <Grid container direction="column" alignItems="center">
                                    <Button
                                        sx={{ my: 0.5 }}
                                        variant="outlined"
                                        size="small"
                                        onClick={handleAllRight}
                                        disabled={left.length === 0}
                                        aria-label="move all right"
                                    >
                                        ≫
                                    </Button>
                                    <Button
                                        sx={{ my: 0.5 }}
                                        variant="outlined"
                                        size="small"
                                        onClick={handleCheckedRight}
                                        disabled={leftChecked.length === 0}
                                        aria-label="move selected right"
                                    >
                                        &gt;
                                    </Button>
                                    <Button
                                        sx={{ my: 0.5 }}
                                        variant="outlined"
                                        size="small"
                                        onClick={handleCheckedLeft}
                                        disabled={rightChecked.length === 0}
                                        aria-label="move selected left"
                                    >
                                        &lt;
                                    </Button>
                                    <Button
                                        sx={{ my: 0.5 }}
                                        variant="outlined"
                                        size="small"
                                        onClick={handleAllLeft}
                                        disabled={right.length === 0}
                                        aria-label="move all left"
                                    >
                                        ≪
                                    </Button>
                                </Grid>
                            </Grid>
                            <Grid item>{customList(right)}</Grid>
                        </Grid>
                    </div>
                    <br />
                    <div>
                        <a className="button button-primary button-wide-mobile button-sm"
                            onClick={handleVideoAccessSaveUpdate}
                            disabled={user === ""}
                        >Save</a>
                    </div>
                </div>
            </div>
            <br />
            {
                (user != "") ?
                    <div className="container" style={{ background: "#fff", color: "#555" }}>
                        <div style={{ minHeight: 300, width: '100%', padding: '20px 10px' }}>
                            <h4 style={{ color: "#555" }}>Manage Validity</h4>


                            <Box sx={{ flexGrow: 1 }}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    {
                                        videoValidityDetails.map((video, index) => (
                                            <Grid container spacing={2} style={{ margin: "5px 0px", textAlign: "left" }}>
                                                <Grid item xs={4}>
                                                    <div style={{ marginTop: "15px" }}>
                                                        <span>{(index + 1) + ".   " + video.videoName}</span>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <DatePicker
                                                        label="Validity"
                                                        value={(video.validity == '') ? null : new Date(video.validity)}
                                                        onChange={(newValue) => {
                                                            handleVideoValidityDate(video.videoId, newValue);
                                                        }}
                                                        renderInput={(params) => <TextField id={"txt-data-" + index} {...params} />}
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <div style={{ marginTop: "15px" }}>
                                                        <span>{video.expiry}</span>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        ))
                                    }
                                </LocalizationProvider>
                            </Box>
                            <br />
                            <div>
                                <a className="button button-primary button-wide-mobile button-sm"
                                    onClick={handleVideoValiditySave}>
                                    Save
                        </a>
                            </div>
                        </div>
                    </div>
                    : null
            }

            <Snackbar
                open={openVideoAddedSuccess}
                autoHideDuration={6000}
                onClose={handleCloseVideoAddedSuccess}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
                <Alert onClose={handleCloseVideoAddedSuccess} severity="success" sx={{ width: '100%' }}>
                    User's video access has been saved sucessfully!
                </Alert>
            </Snackbar>

        </section>
    );
}

VideoAccess.propTypes = propTypes;
VideoAccess.defaultProps = defaultProps;

export default VideoAccess;

