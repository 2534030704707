import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import axios from 'axios';

import { DataGrid } from '@mui/x-data-grid';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import { ThemeProvider } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import TimePicker from '@mui/lab/TimePicker';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import CloseIcon from '@mui/icons-material/Close';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

import * as ApiConstants from './../../Constants/ApiConstants'
import * as ArEvents from './../../Constants/EventDetails'



const propTypes = {
    ...SectionTilesProps.types
}

const defaultProps = {
    ...SectionTilesProps.defaults
}

const User = ({
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    pushLeft,
    ...props
}) => {

    const outerClasses = classNames(
        'testimonial section',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        className
    );

    var authHeader = {};

    if (localStorage.getItem("userInfo") != undefined) {
        let userInfo = JSON.parse(localStorage.getItem("userInfo"));
        authHeader = {
            headers: {
                'Authorization': `Bearer ${userInfo.token}`
            }
        }
    };

    useEffect(() => {
        GetAllEvents();
    }, []);

    const [events, setEvents] = useState([]);
    const [defaultEvents, setDefaultEvents] = useState([]);

    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    var userInfo = {};

    let loggedInUser = localStorage.getItem("userInfo");

    if (loggedInUser != undefined) {
        userInfo = JSON.parse(loggedInUser);
    };

    const [openEventWindow, setOpenEventWindow] = useState(false);

    const handleCloseEventWindow = () => {
        setOpenEventWindow(false);
    }

    const handleOpenEventWindow = () => {
        setOpenEventWindow(true);
    }

    const [eventValues, setEventValues] = useState({
        eventId: 0,
        eventCourse: 'icat',
        eventPaymentLink: '',
        eventStartDate: null,
        eventStartTime: null,
        eventEndDate: null,
        eventEndTime: null,
        eventCourseFee: '',
        eventDiscountCourseFee: '',
        eventCoupen: '',
        eventStatus: 'Upcoming',
        lastModifiedBy: ''
    });
    const handleEventValueChange = (prop) => (event) => {
        setEventValues({ ...eventValues, [prop]: event.target.value });
    };

    const handleEventDateChange = (prop, value) => {
        setEventValues({ ...eventValues, [prop]: value });
    };

    const [openEventAddedSuccess, setOpenEventAddedSuccess] = useState(false);

    const handleCloseEventAddedSuccess = () => {
        setOpenEventAddedSuccess(false);
    }

    const handleEventSubmit = () => {
        let evnt = eventValues;

        if (userInfo != null) {
            evnt.lastModifiedBy = userInfo.email;
        };

        axios.post(ApiConstants.EventApis.AddEventPath, evnt, authHeader)
            .then(response => {
                if (response.status === 200) {
                    setOpenEventAddedSuccess(true);
                    handleCloseEventWindow();
                    GetAllEvents();
                }

            })
            .catch(error => {
                console.log(error);
                setErrorMessage("Something went wrong !! Kindly contact Admin");
            });
    }

    const [errorMessage, setErrorMessage] = useState("");

    const handleValidationError = (valueType) => {
        if (valueType === "eventPaymentLink") {
            if (eventValues.eventPaymentLink.trim() === "") return true;
        }
        return false;
    }

    const handleValidationErrorMessage = (valueType) => {
        if (valueType === "eventPaymentLink") {
            if (eventValues.eventPaymentLink.trim() === "") return "Payment Link can't be empty";
        }
        return "";
    }

    const GetAllEvents = () => {
        axios.get(ApiConstants.EventApis.GetAllEventsPath, authHeader)
            .then(response => {
                let evnts = [];
                setDefaultEvents(response.data);
                response.data.forEach(function (evnt) {
                    let item = {
                        "id": evnt.eventId,
                        "courseName": evnt.eventCourse,
                        "courseFee": evnt.eventCourseFee,
                        "eventStartDate": evnt.eventStartDate,
                        "eventStatus": evnt.eventStatus
                    }
                    evnts.push(item);
                })
                setEvents(evnts);
            })
            .catch(error => {
                console.log(error);
            });
    };

    const eventcolumns = [
        { field: 'id', headerName: 'Sl.No', flex: 0.5 },
        { field: 'courseName', headerName: 'Course', flex: 1 },
        { field: 'courseFee', headerName: 'Fees', flex: 1 },
        { field: 'eventStartDate', headerName: 'Start Date', flex: 1 },
        { field: 'eventStatus', headerName: 'Status', flex: 1 },
        {
            field: "edit",
            headerName: "Edit",
            sortable: false,
            renderCell: (params) => {
                const onClick = (e) => {
                    e.stopPropagation(); // don't select this row after clicking

                    const api = params.api;
                    let eventId = "";
                    api
                        .getAllColumns()
                        .filter((c) => c.field == "id")
                        .forEach(
                            (c) => (eventId = params.getValue(params.id, c.field))
                        );

                    let eventDet = defaultEvents.filter((u) => u.eventId == eventId);
                    let evntToedit = eventDet[0];

                    let evnt = {
                        eventId: evntToedit.eventId,
                        eventCourse: evntToedit.eventCourse,
                        eventPaymentLink: evntToedit.eventPaymentLink,
                        eventStartDate: evntToedit.eventStartDate,
                        eventStartTime: evntToedit.eventStartTime,
                        eventEndDate: evntToedit.eventEndDate,
                        eventEndTime: evntToedit.eventEndTime,
                        eventCourseFee: evntToedit.eventCourseFee,
                        eventDiscountCourseFee: evntToedit.eventDiscountCourseFee,
                        eventCoupen: evntToedit.eventCoupen,
                        eventStatus: evntToedit.eventStatus,
                        lastModifiedBy: ''
                    }
                    setEventValues(evnt);
                    setErrorMessage("");
                    setOpenEventWindow(true);
                    //console.log(JSON.stringify(vdoToedit));
                };

                return <a className="button button-primary button-wide-mobile button-sm" onClick={onClick}>Edit</a>;
            }
        },
    ];

    const handleUpdateEventSubmit = () => {
        let evnt = eventValues;

        if (userInfo != null) {
            evnt.lastModifiedBy = userInfo.email;
        };

        axios.post(ApiConstants.EventApis.UpdateEventPath, evnt, authHeader)
            .then(response => {
                console.log(response);
                if (response.status === 200) {
                    setOpenEventAddedSuccess(true);
                    handleCloseEventWindow();
                    GetAllEvents();
                }
            })
            .catch(error => {
                console.log(error);
                setErrorMessage("Something went wrong !! Kindly contact Admin");
            });
    }

    return (
        <section
            {...props}
            className={outerClasses}
        >
            <div className="container" style={{ textAlign: "right" }}>
                <Button variant="contained" className="button button-primary button-wide-mobile button-sm" onClick={handleOpenEventWindow} >Add New Event</Button>
            </div>
            <br />

            <div className="container ar-gray-back">
                <div style={{ height: 300, width: '100%' }}>
                    <DataGrid rows={events} columns={eventcolumns} />
                </div>
            </div>


            <Dialog
                open={openEventWindow}

                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <IconButton
                    aria-label="close"
                    onClick={handleCloseEventWindow}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>

                <ThemeProvider >
                    <Container component="main" maxWidth="xs">
                        <Box
                            sx={{
                                marginTop: 8,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
                                <LockOutlinedIcon />
                            </Avatar>
                            <h3 className="text-color-primary">Add Event</h3>
                            <Box component="form" onSubmit={handleEventSubmit} noValidate sx={{ mt: 1 }}>

                                <FormControl fullWidth >
                                    <InputLabel id="eventCourseLabel">Course</InputLabel>
                                    <Select
                                        labelId="eventCourseLabel"
                                        id="eventCourse"
                                        label="Course"
                                        value={eventValues.eventCourse}
                                        onChange={handleEventValueChange('eventCourse')}
                                        required
                                    >
                                        {ArEvents.ArCourses.map((course) => (
                                            <MenuItem
                                                value={course}
                                            >
                                                {course}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                <TextField
                                    sx={{ width: "100%" }}
                                    margin="normal"
                                    variant="outlined"
                                    id="eventPaymentLink"
                                    label="Payment Link"
                                    name="eventPaymentLink"
                                    required
                                    onChange={handleEventValueChange('eventPaymentLink')}
                                    value={eventValues.eventPaymentLink}
                                    error={handleValidationError('eventPaymentLink')}
                                    helperText={handleValidationErrorMessage('eventPaymentLink')}
                                />

                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <FormControl fullWidth margin="normal">
                                        <DatePicker
                                            label="Start Date"
                                            value={eventValues.eventStartDate}
                                            onChange={(newValue) => {
                                                handleEventDateChange('eventStartDate', newValue)
                                            }}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </FormControl>

                                    <FormControl fullWidth margin="normal">
                                        <DatePicker
                                            label="End Date"
                                            value={eventValues.eventEndDate}
                                            onChange={(newValue) => {
                                                handleEventDateChange('eventEndDate', newValue)
                                            }}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </FormControl>

                                    <FormControl fullWidth margin="normal">
                                        <TimePicker
                                            label="Start Time"
                                            value={eventValues.eventStartTime}
                                            onChange={(newValue) => {
                                                handleEventDateChange('eventStartTime', newValue)
                                            }}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </FormControl>

                                    <FormControl fullWidth margin="normal">
                                        <TimePicker
                                            label="End Time"
                                            value={eventValues.eventEndTime}
                                            onChange={(newValue) => {
                                                handleEventDateChange('eventEndTime', newValue)
                                            }}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </FormControl>
                                </LocalizationProvider>

                                <TextField
                                    sx={{ width: "100%" }}
                                    margin="normal"
                                    variant="outlined"
                                    id="eventCourseFee"
                                    label="Course Fee"
                                    name="eventCourseFee"
                                    required
                                    onChange={handleEventValueChange('eventCourseFee')}
                                    value={eventValues.eventCourseFee}
                                />

                                <TextField
                                    sx={{ width: "100%" }}
                                    margin="normal"
                                    variant="outlined"
                                    id="eventDiscountCourseFee"
                                    label="Discounted Course Fee"
                                    name="eventDiscountCourseFee"
                                    required
                                    onChange={handleEventValueChange('eventDiscountCourseFee')}
                                    value={eventValues.eventDiscountCourseFee}
                                />

                                <FormControl fullWidth margin="normal" >
                                    <InputLabel id="eventCourseStatusLabel">Event Status</InputLabel>
                                    <Select
                                        labelId="eventCourseStatusLabel"
                                        id="eventCourseStatus"
                                        label="CourseStatus"
                                        value={eventValues.eventStatus}
                                        onChange={handleEventValueChange('eventStatus')}
                                        required
                                    >
                                        {ArEvents.ArCourseStatus.map((status) => (
                                            <MenuItem
                                                value={status}
                                            >
                                                {status}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                {(errorMessage != "") ?
                                    <div className="error-message">
                                        <label style={{ color: 'red' }} >{errorMessage}</label>
                                    </div>
                                    : null}

                                {
                                    (eventValues.eventId == 0) ?
                                        <Button
                                            type="button"
                                            fullWidth
                                            variant="contained"
                                            sx={{ mt: 3, mb: 2 }}
                                            onClick={handleEventSubmit}
                                        >
                                            Add Event
                                        </Button>
                                        :
                                        <Button
                                            type="button"
                                            fullWidth
                                            variant="contained"
                                            sx={{ mt: 3, mb: 2 }}
                                            onClick={handleUpdateEventSubmit}
                                        >
                                            Update Event
                                        </Button>
                                }


                            </Box>
                        </Box>
                    </Container>
                </ThemeProvider>
            </Dialog>

            <Snackbar
                open={openEventAddedSuccess}
                autoHideDuration={5000}
                onClose={handleCloseEventAddedSuccess}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
                <Alert onClose={handleCloseEventAddedSuccess} severity="success" sx={{ width: '100%' }}>
                    {
                        (eventValues.eventId == 0) ?
                            <span>Event has been Added sucessfully!</span>
                            :
                            <span>Event details has been updated sucessfully!</span>
                    }
                </Alert>
            </Snackbar>

        </section>
    );
}

User.propTypes = propTypes;
User.defaultProps = defaultProps;

export default User;