
import React, { useState } from 'react';
import classNames from 'classnames';
import { SectionProps } from './../utils/SectionProps';


import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import User from './../components/sections/User';
import Events from './../components/sections/Events';
import Video from './../components/sections/Video';
import VideoAccess from './../components/sections/VideoAccess'

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}



const propTypes = {
    ...SectionProps.types
}

const defaultProps = {
    ...SectionProps.defaults
}

const ControlPanel = ({
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    ...props
}) => {

    const outerClasses = classNames(
        'hero section center-content',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        className
    );

    const innerClasses = classNames(
        'hero-inner section-inner',
        topDivider && 'has-top-divider',
        bottomDivider && 'has-bottom-divider'
    );


    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };



    return (
        <section
            {...props}
            className={outerClasses}
        >
            <div className="container">
                <br /><br />
                <div className="container">
                    <Box sx={{ width: '100%' }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs textColor="inherit" value={value} onChange={handleChange} aria-label="basic tabs example">
                                <Tab style={{ cursor: "pointer" }} label="Manage Users" {...a11yProps(0)} />
                                <Tab style={{ cursor: "pointer" }} label="Manage Videos" {...a11yProps(1)} />
                                <Tab style={{ cursor: "pointer" }} label="Manage Access" {...a11yProps(2)} />
                                <Tab style={{ cursor: "pointer" }} label="Manage Events" {...a11yProps(3)} />
                            </Tabs>
                        </Box>
                        <TabPanel value={value} index={0}>
                            <User />
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <Video />
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                            <VideoAccess />
                        </TabPanel>
                        <TabPanel value={value} index={3}>
                            <Events />
                        </TabPanel>
                    </Box>
                </div>
            </div>
        </section>
    );
}

ControlPanel.propTypes = propTypes;
ControlPanel.defaultProps = defaultProps;

export default ControlPanel;

