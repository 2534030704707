
import React, { useState } from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import Image from '../elements/Image';
import SectionHeader from '../sections/partials/SectionHeader';

import Grid from '@mui/material/Grid';

import UpstoxImg from '../../assets/images/upstox-logo.jpg'
import ZerodhaImg from '../../assets/images/zerodha-logo.jpg'
import FyresImg from '../../assets/images/fyres-logo.jpg'




const propTypes = {
    ...SectionProps.types
}

const defaultProps = {
    ...SectionProps.defaults
}

const Brokers = ({
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    ...props
}) => {

    const outerClasses = classNames(
        'hero section center-content',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        className
    );

    const sectionHeader = {
        title: 'Open Your Trading Account',
        paragraph: ''
    };

    return (
        <section
            {...props}
            className={outerClasses}
        >
            <div className="container">
                <div className="has-top-divider">
                    <br />
                    <SectionHeader data={sectionHeader} className="center-content" />
                    <div className="tiles-wrap center-content">
                        <div className="tiles-item reveal-from-bottom">
                            <div className="tiles-item-inner">
                                <div className="features-tiles-item-content">
                                    <div style={{ width: '100%' }}>
                                        <a href="https://bit.ly/35yrjgq" target="_blank">
                                            <Image src={ZerodhaImg} alt="Zerodha" />
                                        </a>
                                    </div>
                                    <div className="brokerage" style={{ width: '100%' }}>
                                        <h5 className="text-color-secondary">Zero brokerage on equity delivery</h5>
                                        <h5 className="text-color-secondary">Flat ₹20 or 0.03% (whichever is lower) for Intraday and F&O trades</h5>
                                        <h5 className="text-color-secondary">₹0 commisions on Mutual Funds </h5>
                                        <a className="button button-primary button-wide-mobile button-sm" href="https://bit.ly/35yrjgq" target="_blank">Open Account In Zerodha</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tiles-item reveal-from-bottom">
                            <div className="tiles-item-inner">
                                <div className="features-tiles-item-content">
                                    <div style={{ width: '100%' }}>
                                        <a href="https://bit.ly/3HaS5t2" target="_blank">
                                            <Image src={UpstoxImg} alt="Upstox" />
                                        </a>
                                    </div>
                                    <div className="brokerage" style={{ width: '100%' }}>
                                        <h5 className="text-color-secondary">₹20 or 2.5% (whichever is lower) on equity delivery</h5>
                                        <h5 className="text-color-secondary">Flat ₹20 or 0.05% (whichever is lower) for Intraday and F&O trades</h5>
                                        <h5 className="text-color-secondary">₹0 commisions on Mutual Funds & IPOs</h5>
                                        <a className="button button-primary button-wide-mobile button-sm" href="https://bit.ly/3HaS5t2" target="_blank">Open Account In Upstox</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tiles-item reveal-from-bottom">
                            <div className="tiles-item-inner">
                                <div className="features-tiles-item-content">
                                    <div style={{ width: '100%' }}>
                                        <a href="https://bit.ly/3AJbzmo" target="_blank">
                                            <Image src={FyresImg} alt="Fyres" />
                                        </a>
                                    </div>
                                    <div className="brokerage" style={{ width: '100%' }}>
                                        <h5 className="text-color-secondary">Zero brokerage on equity delivery</h5>
                                        <h5 className="text-color-secondary">₹20 across all other segments</h5>
                                        <h5 className="text-color-secondary">ZERO Annual Maintainence Charges for Lifetime</h5>
                                        <br /><br />
                                        <a className="button button-primary button-wide-mobile button-sm" href="https://bit.ly/3AJbzmo" target="_blank">Open Account In Fyres</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <label><i>Note: Indane Trader helps you to select from the best discount brokers of India. We personally prefer using Zerodha, Fyers and Upstox. The T&C of these brokers' changes time to time, so we recommend you to go through the latest offers.</i></label>
                    </div>                   
                </div>                
            </div>
        </section>
    );
}

Brokers.propTypes = propTypes;
Brokers.defaultProps = defaultProps;

export default Brokers;