import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

const FooterNav = ({
    className,
    ...props
}) => {

    const classes = classNames(
        'footer-nav',
        className
    );

    const GoToTop = (event) => {
        const anchor = (event.target.ownerDocument || document).querySelector('#back-to-top-anchor');

        if (anchor) {
            anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    };

    return (
        <nav
            {...props}
            className={classes}
        >
            <ul className="list-reset">
                <li>
                    <Link to="/privacy-policy" onClick={GoToTop} >Privacy Policy</Link>
                </li>
                <li>
                    <Link to="/disclaimer" onClick={GoToTop} >Disclaimer</Link>
                </li>
                <li>
                    <Link to="/terms" onClick={GoToTop} >Terms</Link>
                </li>
                {/*<li>*/}
                {/*  <Link to="#0">FAQ's</Link>*/}
                {/*</li>*/}
                {/*<li>*/}
                {/*  <Link to="#0">Support</Link>*/}
                {/*</li>*/}
            </ul>
        </nav>
    );
}

export default FooterNav;