import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';



import WifiProtectedSetupIcon from '@mui/icons-material/WifiProtectedSetup';
import ConstructionIcon from '@mui/icons-material/Construction';
import SelfImprovementIcon from '@mui/icons-material/SelfImprovement';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import AddchartIcon from '@mui/icons-material/Addchart';
import BoltIcon from '@mui/icons-material/Bolt';

import Avatar from '@mui/material/Avatar';
import * as ArStyles from '../../Constants/ArStyleConstants';
import { ThemeProvider } from '@mui/material/styles';

const propTypes = {
    ...SectionTilesProps.types
}

const defaultProps = {
    ...SectionTilesProps.defaults
}
const FeaturesTiles = ({
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    pushLeft,
    ...props
}) => {

    const outerClasses = classNames(
        'features-tiles section',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        className
    );

    const innerClasses = classNames(
        'features-tiles-inner section-inner pt-0',
        topDivider && 'has-top-divider',
        bottomDivider && 'has-bottom-divider'
    );

    const tilesClasses = classNames(
        'tiles-wrap center-content',
        pushLeft && 'push-left'
    );

    const sectionHeader = {
        title: 'Why Indane Trader?',
        paragraph: ''
    };

    //const sectionHeader = {
    //    title: 'Coach Santosh Choudhury',
    //    paragraph: 'Santosh is one of the best coaches to provide premium content of Stock Market. He is also a great motivational speaker. He always says stock trading is so easy that even a 10 year old can to it.'
    //};

    return (
        <ThemeProvider theme={ArStyles.ArTheme}>
            <section
                {...props}
                className={outerClasses}
            >
                <div className="container">
                    <div className={innerClasses}>
                        <SectionHeader data={sectionHeader} className="center-content" />
                        <div className={tilesClasses}>


                            <div className="tiles-item reveal-from-bottom">
                                <div className="tiles-item-inner">
                                    <div className="features-tiles-item-header">
                                        <div className="features-tiles-item-image mb-16">
                                            <Avatar sx={{ m: 1 }} className="avatar-bg">
                                                <SelfImprovementIcon />
                                            </Avatar>
                                        </div>
                                    </div>
                                    <div className="features-tiles-item-content">
                                        <h4 className="mt-0 mb-8">
                                            Trader Mindset
                                        </h4>
                                        <p className="m-0 text-sm">
                                            Making money is not tough in stock market, but all you need is a Trader Mindset. Our courses will train you to control your emotions on your trades.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
                                <div className="tiles-item-inner">
                                    <div className="features-tiles-item-header">
                                        <div className="features-tiles-item-image mb-16">
                                            <Avatar sx={{ m: 1 }} className="avatar-bg">
                                                <TrendingDownIcon />
                                            </Avatar>
                                        </div>
                                    </div>
                                    <div className="features-tiles-item-content">
                                        <h4 className="mt-0 mb-8">
                                            Risk Management
                                        </h4>
                                        <p className="m-0 text-sm">
                                            How to manage your risk? How to find out how much quantity to buy? All these are very easy with our formula based system.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
                                <div className="tiles-item-inner">
                                    <div className="features-tiles-item-header">
                                        <div className="features-tiles-item-image mb-16">
                                            <Avatar sx={{ m: 1 }} className="avatar-bg">
                                                <ConstructionIcon />
                                            </Avatar>
                                        </div>
                                    </div>
                                    <div className="features-tiles-item-content">
                                        <h4 className="mt-0 mb-8">
                                            Simple Strategies
                                        </h4>
                                        <p className="m-0 text-sm">
                                            All strategies are so simple that anyone with basic mathematical knowledge can do trading.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="tiles-item reveal-from-bottom">
                                <div className="tiles-item-inner">
                                    <div className="features-tiles-item-header text-center">
                                        <div className="features-tiles-item-image mb-16">
                                            <Avatar sx={{ m: 1 }} className="avatar-bg">
                                                <WifiProtectedSetupIcon />
                                            </Avatar>
                                        </div>
                                    </div>
                                    <div className="features-tiles-item-content">
                                        <h4 className="mt-0 mb-8">
                                            Process Oriented
                                    </h4>
                                        <p className="m-0 text-sm">
                                            All strategies are process oriented. you just need to follow the process and the process will make money for you.
                                    </p>
                                    </div>
                                </div>
                            </div>                           

                            <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
                                <div className="tiles-item-inner">
                                    <div className="features-tiles-item-header">
                                        <div className="features-tiles-item-image mb-16">
                                            <Avatar sx={{ m: 1 }} className="avatar-bg">
                                                <AddchartIcon />
                                            </Avatar>
                                        </div>
                                    </div>
                                    <div className="features-tiles-item-content">
                                        <h4 className="mt-0 mb-8">
                                            Back Test Proven
                                    </h4>
                                        <p className="m-0 text-sm">
                                            All our strategies has been back-tested and has given good results. How to do back-testing is also a part of our all courses.
                                    </p>
                                    </div>
                                </div>
                            </div>

                            

                            <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
                                <div className="tiles-item-inner">
                                    <div className="features-tiles-item-header">
                                        <div className="features-tiles-item-image mb-16">
                                            <Avatar sx={{ m: 1 }} className="avatar-bg">
                                                <BoltIcon />
                                            </Avatar> 
                                        </div>
                                    </div>
                                    <div className="features-tiles-item-content">
                                        <h4 className="mt-0 mb-8">
                                            Flawless Execution
                                    </h4>
                                        <p className="m-0 text-sm">
                                            Executing the strategies at correct time gives you fruitful returns. Our courses includes self assessment trainings to boost your confidence on trading.
                                    </p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </ThemeProvider>



    );
}

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default FeaturesTiles;