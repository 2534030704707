
import React, { useState } from 'react';
import classNames from 'classnames';
import { SectionProps } from './../utils/SectionProps';



const propTypes = {
    ...SectionProps.types
}

const defaultProps = {
    ...SectionProps.defaults
}

const Disclaimer = ({
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    ...props
}) => {

    const outerClasses = classNames(
        'hero section center-content',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        className
    );

    const innerClasses = classNames(
        'hero-inner section-inner',
        topDivider && 'has-top-divider',
        bottomDivider && 'has-bottom-divider'
    );

    return (
        <section
            {...props}
            className={outerClasses}
        >
            <div className="container-sm">
                <div className={innerClasses}>
                    <div className="hero-content">
                        <div className="container-sm">
                            <h3>Disclaimer</h3>
                            <p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="400" style={{ textAlign: "justify" }}>
                                We don’t believe in “<b>GET RICH</b>” programs – only in passion, hard work, value added process,
                                building a real and professional career, and serving others with continuous improvement. 
                                Our courses are intended to help you share your message with a wider audience and to make a 
                                difference in the world while growing your personal growth. Our programs take a lot of work and discipline 
                                just like any worthwhile endeavor or professional continuing education program. Please don’t enroll in our 
                                programs if you believe in the “<b>MONEY FOR NOTHING/GET RICH QUICK</b>” myth or ideology; We only want serious 
                                people dedicated to real personal and professional development, who want to add value and move humanity 
                                forward. As stipulated by law, we cannot and do not make any guarantees about your ability to get results 
                                or make any money with our education, information, tools and techniques or strategies. We don’t know you 
                                personally and, besides, your results in life are depends upon you. We just want to help by giving great 
                                education, direction, and strategies. You should know that all products and services by our company are 
                                for educational and informational purposes only. Nothing on this website, or any of our content or 
                                curriculum is a promise or guarantee of results or future earnings, and we do not offer any legal, 
                                medical, tax, or other professional advice. Any financial numbers referenced here, are illustrative of 
                                concepts only and should not be considered average earnings, exact earnings, or promises for actual or 
                                future performance. Making decisions based on any information presented in our products, courses, services, 
                                or Sites, should be done with the knowledge that you could experience risk or losses just like any 
                                entrepreneurial endeavor. Use caution and always consult your financial advisor, lawyer, or professional 
                                advisor before acting on this or any information related to a lifestyle change or your business or finances. 
                                You alone are responsible and accountable for your decisions, actions, and results in life, 
                                and by your registration here you agree not to attempt to hold us liable for your decisions, 
                                actions, or results, at any time, and under any circumstance.
                                 
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

Disclaimer.propTypes = propTypes;
Disclaimer.defaultProps = defaultProps;

export default Disclaimer;