import React, { useEffect} from 'react';
// import sections
import Hero from '../components/sections/Hero';
import FeaturesTiles from '../components/sections/FeaturesTiles';
import FeaturesSplit from '../components/sections/FeaturesSplit';
import Testimonial from '../components/sections/Testimonial';
import Cta from '../components/sections/Cta';
import Brokers from '../components/sections/Brokers';
import Review from '../components/sections/Review';

const Home = () => {
    useEffect(() => {
        const anchor = (document).querySelector('#back-to-top-anchor');

        if (anchor) {
            anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    });
    return (
        <>
            <Hero className="illustration-section-01" />
            <FeaturesTiles />
            <FeaturesSplit invertMobile topDivider imageFill className="illustration-section-02" />
            <Review />
            <br />
            <Testimonial topDivider />
        </>
    );
}

export default Home;