
import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { isMobile } from "react-device-detect";
import axios from 'axios';

import { SectionProps } from './../utils/SectionProps';
import * as ArEvents from './../Constants/EventDetails';

import * as ApiConstants from './../Constants/ApiConstants';
import { Grid } from '@mui/material';
import CoachImg from './../assets/images/santosh.png';
import OgBoxImg from './../assets/images/og-box.png'

const propTypes = {
    ...SectionProps.types
}

const defaultProps = {
    ...SectionProps.defaults
}

var whatsAppRef = "https://web.whatsapp.com/send?phone='" + ArEvents.ContactDetails.Phone + "'&text=Hi AR Financials";
if (isMobile) whatsAppRef = "whatsapp://send?phone='" + ArEvents.ContactDetails.Phone.replace(" ", "") + "'&text=Hi AR Financials";

const Og = ({
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    ...props
}) => {

    useEffect(() => {
        GetIcatDetails();
    }, []);

    const outerClasses = classNames(
        'hero section center-content',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        className
    );

    const innerClasses = classNames(
        'hero-inner section-inner',
        topDivider && 'has-top-divider',
        bottomDivider && 'has-bottom-divider'
    );

    const [icatEventdetails, setIcatEventdetails] = useState({
        eventPaymentLink: "",
        eventStartDate: "",
        eventStartTime: "",
        eventEndDate: "",
        eventEndTime: "",
        eventCourseFee: "",
        eventDiscountCourseFee: "",
        eventCoupen: "",
    });

    const GetIcatDetails = () => {
        let header = {
            params: { 'eventName': "og" }
        };
        axios.get(ApiConstants.EventApis.GetEventByNamePath, header)
            .then(response => {
                
                let eventdata = response.data

                if (eventdata != "") {
                    let startDate = (eventdata.eventStartDate != "") ? (new Date(eventdata.eventStartDate).getDate() + "/" + (new Date(eventdata.eventStartDate).getMonth() + 1) + "/" + new Date(eventdata.eventStartDate).getFullYear()) : "";
                    let endDate = (eventdata.eventEndDate != "") ? (new Date(eventdata.eventEndDate).getDate() + "/" + (new Date(eventdata.eventEndDate).getMonth() + 1) + "/" + new Date(eventdata.eventEndDate).getFullYear()) : "";
                    let startTime = (eventdata.eventStartTime != "") ? (new Date(eventdata.eventStartTime).getHours()).toString() + ":" + (new Date(eventdata.eventStartTime).getMinutes()).toString() : "";
                    let endTime = (eventdata.eventEndTime != "") ? (new Date(eventdata.eventEndTime).getHours()).toString() + ":" + (new Date(eventdata.eventEndTime).getMinutes()).toString() : "";
                    setIcatEventdetails({
                        eventPaymentLink: eventdata.eventPaymentLink,
                        eventStartDate: startDate,
                        eventStartTime: tConvert(startTime),
                        eventEndDate: endDate,
                        eventEndTime: tConvert(endTime),
                        eventCourseFee: eventdata.eventCourseFee,
                        eventDiscountCourseFee: eventdata.eventDiscountCourseFee,
                        eventCoupen: eventdata.eventCoupen,
                    });
                }
            })
            .catch(error => {
                console.log(error);
            });
    }

    function tConvert(time) {
        // Check correct time format and split into components
        let tt = time.split(":");
        if (tt.length > 1) {
            if (tt[1].length == 1) {
                tt[1] = "0" + tt[1];
            }
            if (tt[0].length == 1) {
                tt[0] = "0" + tt[0];
            }
            time = tt.join(":");
        }
        time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

        if (time.length > 1) { // If time format correct
            time = time.slice(1);  // Remove full string match value
            time[5] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
            time[0] = +time[0] % 12 || 12; // Adjust hours
        }
        return time.join(''); // return adjusted time or original string
    }

    return (
        <section
            {...props}
            className={outerClasses}
        >
            <div className="container-sm">
                <div className={innerClasses}>
                    <div className="hero-content">
                        <div className="container-sm">
                            <h2 className="text-color-primary">Options Gladiator</h2>
                            <img src={OgBoxImg} width="60%" alt="" />
                            <h3>Simple strategies for exponential returns</h3>


                            {
                                (icatEventdetails.eventStartDate != "" && icatEventdetails.eventStartDate != undefined) ?
                                    <h4>
                                        <span className="text-color-primary" >{icatEventdetails.eventStartDate + " to " + icatEventdetails.eventEndDate}</span>
                                        <br />
                                        {icatEventdetails.eventStartTime + " to " + icatEventdetails.eventEndTime}
                                    </h4>
                                    : null
                            }



                            {
                                (icatEventdetails.eventCourseFee != "") ?
                                    <div>
                                        <h3 style={{ color: "red" }}><span className="strikethrough">{"₹" + icatEventdetails.eventCourseFee + "/-"}</span></h3>
                                        <h2 style={{ color: "limegreen" }}>{"₹" + icatEventdetails.eventDiscountCourseFee + "/-"}</h2>
                                        <h4 className="blink text-color-primary">Offer available only for limited period</h4>
                                    </div>
                                    : null
                            }

                            {
                                (icatEventdetails.eventPaymentLink != "") ?
                                    <a className="button button-primary button-wide-mobile button-sm" href={icatEventdetails.eventPaymentLink} target="_blank">Enroll Now</a>
                                    :
                                    <EnquiryNow />
                            }




                        </div>
                        <br /><br /><br />
                        <div className="container-sm">
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <h1 className="text-color-primary">About Coach</h1>
                                </Grid>
                                <Grid item xs={12} className="text-center">
                                    <img style={{ width: "30%" }} src={CoachImg} />
                                </Grid>
                                <Grid item xs={12}>
                                    <h3 className="text-color-secondary">Santosh Choudhury</h3>
                                    <p>
                                        His superior knowledge and remarkable skill to simplify complex trading to school math that a 5th standard student can say <b className="text-color-primary">I can also Trade</b>.
                                    </p>
                                    <p>
                                        With the vision to provide the highest standard of powerful knowledge on World Class Trading in a simplest way. His mission begins with countries biggest Stock Market movement to coach
                                        <br />
                                        <b className="text-color-primary">1 Million</b>
                                        <br />
                                        people to take full control of their financial future.
                                    </p>
                                    <h4 className="text-color-secondary">Welcome to a New Journey</h4>
                                    <h4 className="text-color-secondary"><i>"Let's do a Business, Not Gambling"</i></h4>
                                    {
                                        (icatEventdetails.eventPaymentLink != "") ?
                                            <a className="button button-primary button-wide-mobile button-sm" href={icatEventdetails.eventPaymentLink} target="_blank">Enroll Now</a>
                                            : <EnquiryNow />
                                    }
                                </Grid>

                            </Grid>
                        </div>
                        <br /><br /><br />
                        <div className="container-sm">
                            <h2 className="text-color-primary">What you can learn ?</h2>
                            <h4>BANKNIFTY OPTIONS BUYING STRATEGY</h4>

                            <h4>NIFTY OPTIONS BUYING STRATEGY</h4>


                            <h4>INDEX OPTIONS BUYING STRAGETY</h4>


                            <h4>BANKNIFTY OPTIONS SELLING STRATEGY</h4>


                            <h4>NIFTY OPTIONS SELLING STRATEGY</h4>


                            <h4>INDEX OPTIONS SELLING STRAGETY</h4>


                            <h4>INDEX WEEKLY AND MONTHLY EXPIRY SPECIAL </h4>


                            <h4>NIFTY EXPIRY SPECIAL STRATEGY</h4>


                            <h4>BANK NIFTY EXPIRY SPECIAL STRATEGY</h4>


                            <h4>POSITIONAL STOCK OPTION BUYING & SELLING STRATEGY</h4>


                            <h4>INTRADAY INDEX OPTIONS AND STOCK OPTIONS BUYING STRATEGY</h4>


                            <h4>EVENT SPECIAL OPTION TRADING STRATEGY</h4>


                            <h4>PORTFOLIO HEDGING STRATEGY</h4>

                            <h4>PYRAMYDING ENTRY AND EXIT STRATEGY</h4>

                            <h4>HIGH PERFORMANCE TRADER - MINDSET COURSE</h4>

                            <h4>SUPPORT</h4>
                            <p>
                                Q&A session with <i>Santosh Choudhury</i>
                                <br />
                                Doubt clearing session with <i>Santosh Choudhury</i>
                            </p>
                            <br />

                            <h4>EXTRA BENEFITS</h4>
                            <p className="text-color-primary blink">
                                <b>Free Lifetime Access to Basics of Futures & Options Course (Bonus).</b>
                                <br />
                                <b>One Mastermind Session for Market Analysis (Bonus).</b>
                            </p>
                            <br />
                            {
                                (icatEventdetails.eventPaymentLink != "") ?
                                    <a className="button button-primary button-wide-mobile button-sm" href={icatEventdetails.eventPaymentLink} target="_blank" >Enroll Now</a>
                                    : <EnquiryNow />
                            }
                        </div>

                    </div>
                </div>
            </div>
        </section>
    );
}
Og.propTypes = propTypes;
Og.defaultProps = defaultProps;

export default Og;

function EnquiryNow() {
    return (
        <a className="button button-primary button-wide-mobile button-sm" href={whatsAppRef} target="_blank" >Enquiry Now</a>
    );
}