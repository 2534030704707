
import React, { useState } from 'react';
import classNames from 'classnames';
import { SectionProps } from './../utils/SectionProps';



const propTypes = {
    ...SectionProps.types
}

const defaultProps = {
    ...SectionProps.defaults
}

const PrivacyPolicy = ({
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    ...props
}) => {

    const outerClasses = classNames(
        'hero section center-content',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        className
    );

    const innerClasses = classNames(
        'hero-inner section-inner',
        topDivider && 'has-top-divider',
        bottomDivider && 'has-bottom-divider'
    );

    return (
        <section
            {...props}
            className={outerClasses}
        >
            <div className="container-sm">
                <div className={innerClasses}>
                    <div className="hero-content">
                        <div className="container-sm">
                            <h3>Privacy Policy</h3>
                            <p style={{ textAlign: "justify", fontSize: "16px" }}>
                                This Privacy Policy governs the manner in which this website
                                collects, uses, maintains and discloses information collected from
                                users (each, a “User”) of the <a href="https://indanetrader.com/"> https://indanetrader.com/ </a> website
                                (“Site”). This privacy policy applies to the Site and all products and
                                services offered by Nalla Santosh Choudhury.
                                
                                <h5>Personal identification information</h5>
                                
                                We may collect personal identification information from Users in a
                                variety of ways, including, but not limited to when Users visit our
                                site, register on the site, and in connection with other activities,
                                services, features or resources we make available on our Site. Users
                                may be asked for, as appropriate, name, email address, mailing
                                address, phone number. Users may, however, visit our Site
                                anonymously. We will collect personal identification information
                                from Users only if they voluntarily submit such information to us.
                                Users can always refuse to supply personally identification
                                information, except that it may prevent them from engaging in certain
                                Site related activities.

                                <h5>Non-personal identification information</h5>

                                We may collect non-personal identification information about Users
                                whenever they interact with our Site. Non-personal identification
                                information may include the browser name, the type of computer and
                                technical information about Users means of connection to our Site,
                                such as the operating system and the Internet service providers
                                utilized and other similar information.

                                <h5>Web browser cookies</h5>

                                Our Site may use “cookies” to enhance the User experience. User’s
                                web browser places cookies on their hard drive for record-keeping
                                purposes and sometimes to track information about them. Users may
                                choose to set their web browser to refuse cookies or to alert you when
                                cookies are being sent. If they do so, note that some parts of the Site
                                may not function properly.

                                <h5>How we use collected information</h5>

                                <a href="https://indanetrader.com">Indane Trader</a> collects and uses Users’ personal
                                information for the following purposes:

                                – To personalize user experience
                                We may use information in the aggregate to understand how our
                                Users as a group use the services and resources provided on our Site.
                                – To improve our Site
                                We continually strive to improve our website offerings based on
                                the information and feedback we receive from you.
                                – To improve customer service
                                Your information helps us to more effectively respond to your
                                customer service requests and support needs.
                                – To send periodic emails
                                The email address Users provide will only be used to respond to
                                their inquiries, and/or other requests or questions.

                                <h5>How we protect your information</h5>

                                We adopt appropriate data collection, storage and processing practices
                                and security measures to protect against unauthorized access,
                                alteration, disclosure or destruction of your personal information,
                                username, password, transaction information and data stored on our
                                Site.

                                <h5>Sharing your personal information</h5>

                                We do not sell, trade, or rent Users’ personal identification
                                information to others. We may share generic aggregated demographic
                                information not linked to any personal identification information
                                regarding visitors and users with our business partners, trusted
                                affiliates and advertisers for the purposes outlined above.
                                Advertising

                                Ads appearing on our site may be delivered to Users by advertising
                                partners, who may set cookies. These cookies allow the ad server to
                                recognize your computer each time they send you an online
                                advertisement to compile non-personal identification information
                                about you or others who use your computer. This information allows
                                ad networks to, among other things, deliver targeted advertisements
                                that they believe will be of most interest to you. This privacy policy
                                does not cover the use of cookies by any advertisers.

                                <h5>Google Adsense</h5>

                                Some of the ads may be served by Google. Google’s use of the DART
                                cookie enables it to serve ads to Users based on their visit to our Site
                                and other sites on the Internet. DART uses “non personally
                                identifiable information” and does NOT track personal information

                                <br/>
                                about you, such as your name, email address, physical address, etc.
                                You may opt-out of the use of the DART cookie by visiting the
                                Google ad and content network privacy policy at
                                http://www.google.com/privacy_ads.html
                                Changes to this privacy policy

                                <br/>

                                <a href="https://indanetrader.com">Indane Trader</a> has the discretion to update this privacy
                                policy at any time. When we do, we will post a notification on the
                                main page of our Site, revise the updated date at the bottom of this
                                page. We encourage Users to frequently check this page for any
                                changes to stay informed about how we are helping to protect the
                                personal information we collect. You acknowledge and agree that it is
                                your responsibility to review this privacy policy periodically and
                                become aware of modifications.

                                <h5>Your acceptance of these terms</h5>

                                By using this Site, you signify your acceptance of this policy. If you
                                do not agree to this policy, please do not use our Site. Your continued
                                use of the Site following the posting of changes to this policy will be
                                deemed your acceptance of those changes.
                                Contacting us
                                <br />
                                If you have any questions about this Privacy Policy, the practices of
                                this site, or your dealings with this site, please contact us at:
                                <br />
                                <b>Address</b><br />
                                <b className="text-color-primary">Indane Trader</b> <br />
                                Plot no 106/219 <br />
                                Tankua<br />
                                Jeypore, Koraput<br />
                                Pin Code - 764003<br />
                                Odisha, India

                                <br /><br />
                                <i>This document was last updated on March 15, 2022</i>
                                <br />
                                <b>The Vision of this Community is to help people to achieve their FINANCIAL FREEDOM.</b>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

PrivacyPolicy.propTypes = propTypes;
PrivacyPolicy.defaultProps = defaultProps;

export default PrivacyPolicy;