import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import axios from 'axios';
import Grid from '@mui/material/Grid';
import { DataGrid } from '@mui/x-data-grid';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import { ThemeProvider } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';




import * as ApiConstants from './../../Constants/ApiConstants'
import * as ArEvents from './../../Constants/EventDetails'

import CloseIcon from '@mui/icons-material/Close';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const propTypes = {
    ...SectionTilesProps.types
}

const defaultProps = {
    ...SectionTilesProps.defaults
}

const User = ({
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    pushLeft,
    ...props
}) => {


    var authHeader = {};

    if (localStorage.getItem("userInfo") != undefined) {
        let userInfo = JSON.parse(localStorage.getItem("userInfo"));
        authHeader = {
            headers: {
                'Authorization': `Bearer ${userInfo.token}`
            }
        }
    };

    const [users, setUsers] = React.useState({});

    const columns = [
        { field: 'firstName', headerName: 'First Name', flex: 1 },
        { field: 'lastName', headerName: 'Last Name', flex: 1 },
        { field: 'email', headerName: 'Email', flex: 1 },
        { field: 'contactNumber', headerName: 'Contact', flex: 1 },
        { field: 'role', headerName: 'Role', flex: 1 },
        { field: 'isActive', headerName: 'Active', flex: 1 },
        { field: 'lastLoggedIn', headerName: 'Last Loggedin', flex: 1 },
        {
            field: "edit",
            headerName: "Edit",
            sortable: false,
            renderCell: (params) => {
                const onClick = (e) => {
                    e.stopPropagation(); // don't select this row after clicking

                    const api = params.api;
                    let emailId = "";
                    api
                        .getAllColumns()
                        .filter((c) => c.field == "email")
                        .forEach(
                            (c) => (emailId = params.getValue(params.id, c.field))
                        );

                    let userDet = users.filter((u) => u.email == emailId);
                    let userToedit = userDet[0];

                    let useractive = ((userToedit.isActive == "Yes") ? true : false);

                    let edit = {
                        userId: userToedit.id,
                        firstName: userToedit.firstName,
                        lastName: userToedit.lastName,
                        userEmail: userToedit.email,
                        contactNumber: userToedit.contactNumber,
                        userRole: userToedit.role,
                        isUserActive: useractive,
                    }
                    setAddUserValues(edit);
                    setOpenAddUserWindow(true);

                    //console.log(JSON.stringify(userToedit));
                };

                return <a className="button button-primary button-wide-mobile button-sm" onClick={onClick}>Edit</a>;
            }
        },
    ];

    const outerClasses = classNames(
        'testimonial section',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        className
    );

    useEffect(() => {
        GetAllUsers();
    }, []);

    const GetAllUsers = () => {
        axios.get(ApiConstants.UserApis.GetAllUsersPath, authHeader)
            .then(response => {
                setUsers(response.data);
            })
            .catch(error => {
                console.log(error);
            });
    };

    const [openAddUserWindow, setOpenAddUserWindow] = useState(false);

    const handleCloseAddUserWindow = () => {
        setOpenAddUserWindow(false);
    }

    const handleOpenAddUserWindow = () => {
        setAddUserValues({
            userId: 0,
            firstName: '',
            lastName: '',
            userEmail: '',
            contactNumber: '',
            userRole: '',
            isUserActive: true,
        });
        setOpenAddUserWindow(true);
        setErrorMessage("");
    }

    const [addUserValues, setAddUserValues] = useState({
        userId: 0,
        firstName: '',
        lastName: '',
        userEmail: '',
        contactNumber: '',
        userRole: '',
        isUserActive: true,
    });

    const handleAddUserValueChange = (prop) => (event) => {
        setAddUserValues({ ...addUserValues, [prop]: event.target.value });
    };

    const handleAddUserSubmit = () => {
        // event.preventDefault();
        let user = addUserValues;
        axios.post(ApiConstants.UserApis.AddUserPath, user, authHeader)
            .then(response => {
                console.log(response);
                if (response.status === 200) {
                    setOpenUserAddedSuccess(true);
                    handleCloseAddUserWindow();
                    GetAllUsers();
                    setErrorMessage("");
                }

            })
            .catch(error => {
                console.log(error);
                setErrorMessage("Something went wrong !! Kindly contact Admin");
            });
    }

    const handleUpdateUserSubmit = () => {
        // event.preventDefault();addUserValues
        let lastModified = '';
        if (localStorage.getItem("userInfo") != undefined) {
            let userInfo = JSON.parse(localStorage.getItem("userInfo"));
            lastModified = userInfo.email;
        };
        let user = {
            userId: addUserValues.userId,
            firstName: addUserValues.firstName,
            lastName: addUserValues.lastName,
            userEmail: addUserValues.userEmail,
            contactNumber: addUserValues.contactNumber,
            userRole: addUserValues.userRole,
            isUserActive: addUserValues.isUserActive,
            lastModifiedBy: lastModified
        }

        axios.post(ApiConstants.UserApis.UpdateUserPath, user, authHeader)
            .then(response => {
                console.log(response);
                if (response.status === 200) {
                    setOpenUserAddedSuccess(true);
                    handleCloseAddUserWindow();
                    GetAllUsers();
                    setErrorMessage("");
                }

            })
            .catch(error => {
                console.log(error);
                setErrorMessage("Something went wrong !! Kindly contact Admin");
            });
    }

    const [openUserAddedSuccess, setOpenUserAddedSuccess] = useState(false);

    const handleCloseUserAddedSuccess = () => {
        setOpenUserAddedSuccess(false);
    }
    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    const [errorMessage, setErrorMessage] = useState("");



    return (
        <section
            {...props}
            className={outerClasses}
        >
            <div className="container" style={{ textAlign: "right" }}>
                <a className="button button-primary button-wide-mobile button-sm" onClick={handleOpenAddUserWindow} >Add New User</a>
            </div>

            <br />

            <div className="container ar-gray-back">

                <div style={{ height: 300, width: '100%' }}>
                    <DataGrid rows={users} columns={columns} />
                </div>
            </div>

            <Dialog
                open={openAddUserWindow}

                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <IconButton
                    aria-label="close"
                    onClick={handleCloseAddUserWindow}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>

                <ThemeProvider >
                    <Container component="main" maxWidth="xs">
                        <Box
                            sx={{
                                marginTop: 8,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
                                <AccountCircleIcon />
                            </Avatar>

                            <Box sx={{ mt: 1 }}>

                                <TextField
                                    sx={{ width: "100%" }}
                                    margin="normal"
                                    variant="outlined"
                                    id="firstName"
                                    label="First Name"
                                    name="firstName"
                                    required
                                    onChange={handleAddUserValueChange('firstName')}
                                    value={addUserValues.firstName}
                                />
                                {/*error={handleValidationError('eventPaymentLink')}*/}
                                {/*    helperText={handleValidationErrorMessage('eventPaymentLink')}*/}

                                <TextField
                                    sx={{ width: "100%" }}
                                    margin="normal"
                                    variant="outlined"
                                    id="lastName"
                                    label="Last Name"
                                    name="lastName"
                                    onChange={handleAddUserValueChange('lastName')}
                                    value={addUserValues.lastName}
                                />
                                <TextField
                                    sx={{ width: "100%" }}
                                    margin="normal"
                                    variant="outlined"
                                    id="userEmail"
                                    label="Email"
                                    name="userEmail"
                                    required
                                    onChange={handleAddUserValueChange('userEmail')}
                                    value={addUserValues.userEmail}
                                />
                                <TextField
                                    sx={{ width: "100%" }}
                                    margin="normal"
                                    variant="outlined"
                                    id="contactNumber"
                                    label="Contact Number"
                                    name="contactNumber"
                                    required
                                    onChange={handleAddUserValueChange('contactNumber')}
                                    value={addUserValues.contactNumber}
                                />

                                <FormControl fullWidth margin="normal" >
                                    <InputLabel id="userRoleLabel">Role</InputLabel>
                                    <Select
                                        labelId="userRoleLabel"
                                        id="userRole"
                                        label="Role"
                                        value={addUserValues.userRole}
                                        onChange={handleAddUserValueChange('userRole')}
                                        required
                                    >
                                        {ArEvents.ArRoles.map((role) => (
                                            <MenuItem
                                                value={role}
                                            >
                                                {role}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                <FormControlLabel margin="normal" control={<Checkbox onChange={handleAddUserValueChange('isUserActive')} checked={addUserValues.isUserActive} />} label="Is Active" />

                                {(errorMessage != "") ?
                                    <div className="error-message">
                                        <label style={{ color: 'red' }} >{errorMessage}</label>
                                    </div>
                                    : null}

                                {(addUserValues.userId == 0) ?
                                    <Button
                                        type="button"
                                        fullWidth
                                        variant="contained"
                                        sx={{ mt: 3, mb: 2 }}
                                        onClick={handleAddUserSubmit}
                                        disabled={addUserValues.userRole === "" || addUserValues.firstName.trim() === "" || addUserValues.userEmail.trim() === "" || addUserValues.contactNumber.trim() === ""}
                                    >
                                        ADD USER
                                </Button>
                                    :
                                    <div>
                                        <Button
                                            type="button"
                                            fullWidth
                                            variant="contained"
                                            sx={{ mt: 3, mb: 2 }}
                                            onClick={handleUpdateUserSubmit}
                                            disabled={addUserValues.userRole === "" || addUserValues.firstName.trim() === "" || addUserValues.userEmail.trim() === "" || addUserValues.contactNumber.trim() === ""}
                                        >
                                            UPDATE
                                        </Button>
                                    </div>
                                }


                            </Box>
                        </Box>
                    </Container>
                </ThemeProvider>

            </Dialog>

            <Snackbar
                open={openUserAddedSuccess}
                autoHideDuration={6000}
                onClose={handleCloseUserAddedSuccess}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
                <Alert onClose={handleCloseUserAddedSuccess} severity="success" sx={{ width: '100%' }}>
                    {
                        (addUserValues.userId == 0) ?
                            <span>User has been Added sucessfully!</span>
                            :
                            <span>User has been updated sucessfully!</span>
                    }
                    
                </Alert>
            </Snackbar>
        </section>
    );
}

User.propTypes = propTypes;
User.defaultProps = defaultProps;

export default User;