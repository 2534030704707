
import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { isMobile } from "react-device-detect";
import axios from 'axios';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

import { SectionProps } from './../utils/SectionProps';
import * as ArEvents from './../Constants/EventDetails';

import * as ApiConstants from './../Constants/ApiConstants';
import { Grid } from '@mui/material';
import CoachImg from './../assets/images/santosh.png';
import OdBoxImg from './../assets/images/od-box.png';
import OgBoxImg from './../assets/images/og-box.png';
import MoneyBackImg from './../assets/images/money-back.png';

const propTypes = {
    ...SectionProps.types
}

const defaultProps = {
    ...SectionProps.defaults
}

var whatsAppRef = "https://web.whatsapp.com/send?phone='" + ArEvents.ContactDetails.Phone + "'&text=Hi AR Financials";
if (isMobile) whatsAppRef = "whatsapp://send?phone='" + ArEvents.ContactDetails.Phone.replace(" ", "") + "'&text=Hi AR Financials";

const Od = ({
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    ...props
}) => {

    useEffect(() => {
        //GetEventDetails();
    }, []);

    const outerClasses = classNames(
        'hero section center-content',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        className
    );

    const innerClasses = classNames(
        'hero-inner section-inner',
        topDivider && 'has-top-divider',
        bottomDivider && 'has-bottom-divider'
    );

    const [eventdetails, setEventdetails] = useState({
        eventPaymentLink: "",
        eventStartDate: "",
        eventStartTime: "",
        eventEndDate: "",
        eventEndTime: "",
        eventCourseFee: "",
        eventDiscountCourseFee: "",
        eventCoupen: "",
    });

    const GetEventDetails = () => {
        let header = {
            params: { 'eventName': "od" }
        };
        axios.get(ApiConstants.EventApis.GetEventByNamePath, header)
            .then(response => {
                let eventdata = response.data
                if (eventdata != "") {
                    let startDate = (eventdata.eventStartDate != "") ? (new Date(eventdata.eventStartDate).getDate() + "/" + (new Date(eventdata.eventStartDate).getMonth() + 1) + "/" + new Date(eventdata.eventStartDate).getFullYear()) : "";
                    let endDate = (eventdata.eventEndDate != "") ? (new Date(eventdata.eventEndDate).getDate() + "/" + (new Date(eventdata.eventEndDate).getMonth() + 1) + "/" + new Date(eventdata.eventEndDate).getFullYear()) : "";
                    let startTime = (eventdata.eventStartTime != "") ? (new Date(eventdata.eventStartTime).getHours()).toString() + ":" + (new Date(eventdata.eventStartTime).getMinutes()).toString() : "";
                    let endTime = (eventdata.eventEndTime != "") ? (new Date(eventdata.eventEndTime).getHours()).toString() + ":" + (new Date(eventdata.eventEndTime).getMinutes()).toString() : "";
                    setEventdetails({
                        eventPaymentLink: eventdata.eventPaymentLink,
                        eventStartDate: startDate,
                        eventStartTime: tConvert(startTime),
                        eventEndDate: endDate,
                        eventEndTime: tConvert(endTime),
                        eventCourseFee: eventdata.eventCourseFee,
                        eventDiscountCourseFee: eventdata.eventDiscountCourseFee,
                        eventCoupen: eventdata.eventCoupen,
                    });
                }
            })
            .catch(error => {
                console.log(error);
            });
    }

    function tConvert(time) {
        // Check correct time format and split into components
        let tt = time.split(":");
        if (tt.length > 1) {
            if (tt[1].length == 1) {
                tt[1] = "0" + tt[1];
            }
            if (tt[0].length == 1) {
                tt[0] = "0" + tt[0];
            }
            time = tt.join(":");
        }
        time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

        if (time.length > 1) { // If time format correct
            time = time.slice(1);  // Remove full string match value
            time[5] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
            time[0] = +time[0] % 12 || 12; // Adjust hours
        }
        return time.join(''); // return adjusted time or original string
    }

    return (
        <section
            {...props}
            className={outerClasses}
        >
            <div className="container-sm">
                <div className="hero-content">
                    {/*<div className="container-sm">*/}
                    {/*<h2 className="text-color-primary">OPTIONS TRADING</h2>*/}

                    {/*<h3>A Simple Guide To Start Learning Trading In Stock Market </h3>*/}


                    {/*{*/}
                    {/*    (eventdetails.eventStartDate != "" && eventdetails.eventStartDate != "undefined") ?*/}
                    {/*        <h4>*/}
                    {/*            <span className="text-color-primary" >{eventdetails.eventStartDate + " to " + eventdetails.eventEndDate}</span>*/}
                    {/*            <br />*/}
                    {/*            {eventdetails.eventStartTime + " to " + eventdetails.eventEndTime}*/}
                    {/*        </h4>*/}
                    {/*        : null*/}
                    {/*}*/}

                    {/*<h5>You just need to spend 15 minutes per day for Trading</h5>*/}

                    {/*{*/}
                    {/*    (eventdetails.eventCourseFee != "") ?*/}
                    {/*        <div>*/}
                    {/*            <h3 style={{ color: "red" }}><span className="strikethrough">{"₹" + eventdetails.eventCourseFee + "/-"}</span></h3>*/}
                    {/*            <h2 style={{ color: "limegreen" }}>{"₹" + eventdetails.eventDiscountCourseFee + "/-"}</h2>*/}
                    {/*            <h4 className="blink text-color-primary">Offer available only for limited period</h4>*/}
                    {/*        </div>*/}
                    {/*        : null*/}
                    {/*}*/}

                    {/*{*/}
                    {/*    (eventdetails.eventPaymentLink != "") ?*/}
                    {/*        <a className="button button-primary button-wide-mobile button-sm" href={eventdetails.eventPaymentLink} target="_blank">Enroll Now</a>*/}
                    {/*        :*/}
                    {/*        <EnquiryNow />*/}
                    {/*}*/}




                    {/* </div>*/}


                    <div className="container-sm">
                        <h2 className="text-color-primary">OPTIONS TRADING</h2>

                        <Grid container spacing={2}>
                            {/*    <Grid item xs={6}>*/}
                            {/*        <h4 style={{ marginTop: "20%" }} >STRATEGIES</h4>*/}
                            {/*    </Grid>*/}
                            {/*    <Grid item xs={3}>*/}
                            {/*        <img src={OdBoxImg} width="100%" alt="" />*/}
                            {/*    </Grid>*/}
                            {/*    <Grid item xs={3}>*/}
                            {/*        <img src={OgBoxImg} width="100%" alt="" />*/}
                            {/*    </Grid>*/}

                            {/*    <Grid item xs={6}>*/}
                            {/*        INDEX OPTIONS BUYING STRATEGIES*/}
                            {/*    </Grid>*/}
                            {/*    <Grid item xs={3}>*/}
                            {/*        <CheckIcon className="limegreen" />*/}
                            {/*    </Grid>*/}
                            {/*    <Grid item xs={3}>*/}
                            {/*        <CheckIcon className="limegreen" />*/}
                            {/*    </Grid>*/}

                            {/*    <Grid item xs={6}>*/}
                            {/*        EXPIRY DAY TRADING STRATEGY-I*/}
                            {/*    </Grid>*/}
                            {/*    <Grid item xs={3}>*/}
                            {/*        <CheckIcon className="limegreen" />*/}
                            {/*    </Grid>*/}
                            {/*    <Grid item xs={3}>*/}
                            {/*        <CheckIcon className="limegreen" />*/}
                            {/*    </Grid>*/}




                            {/*    <Grid item xs={6}>*/}
                            {/*        EXPIRY DAY TRADING STRATEGY-II*/}
                            {/*    </Grid>*/}
                            {/*    <Grid item xs={3}>*/}
                            {/*        <CloseIcon className="red" />*/}
                            {/*    </Grid>*/}
                            {/*    <Grid item xs={3}>*/}
                            {/*        <CheckIcon className="limegreen" />*/}
                            {/*    </Grid>*/}




                            {/*    <Grid item xs={6}>*/}
                            {/*        EXPIRY DAY TRADING STRATEGY-III*/}

                            {/*    </Grid>*/}
                            {/*    <Grid item xs={3}>*/}
                            {/*        <CloseIcon className="red" />*/}
                            {/*    </Grid>*/}
                            {/*    <Grid item xs={3}>*/}
                            {/*        <CheckIcon className="limegreen" />*/}
                            {/*    </Grid>*/}



                            {/*    <Grid item xs={6}>*/}
                            {/*        EXPIRY DAY TRADING STRATEGY-IV*/}
                            {/*    </Grid>*/}
                            {/*    <Grid item xs={3}>*/}
                            {/*        <CloseIcon className="red" />*/}
                            {/*    </Grid>*/}
                            {/*    <Grid item xs={3}>*/}
                            {/*        <CheckIcon className="limegreen" />*/}
                            {/*    </Grid>*/}




                            {/*    <Grid item xs={6}>*/}
                            {/*        EXPIRY DAY TRADING STRATEGY-V*/}
                            {/*    </Grid>*/}
                            {/*    <Grid item xs={3}>*/}
                            {/*        <CloseIcon className="red" />*/}
                            {/*    </Grid>*/}
                            {/*    <Grid item xs={3}>*/}
                            {/*        <CheckIcon className="limegreen" />*/}
                            {/*    </Grid>*/}




                            {/*    <Grid item xs={6}>*/}
                            {/*        STOCK OPTIONS BUYING STRATEGIES*/}
                            {/*    </Grid>*/}
                            {/*    <Grid item xs={3}>*/}
                            {/*        <CheckIcon className="limegreen" />*/}
                            {/*    </Grid>*/}
                            {/*    <Grid item xs={3}>*/}
                            {/*        <CheckIcon className="limegreen" />*/}
                            {/*    </Grid>*/}




                            {/*    <Grid item xs={6}>*/}
                            {/*        OPTIONS TRADING WITH GAPS*/}
                            {/*    </Grid>*/}
                            {/*    <Grid item xs={3}>*/}
                            {/*        <CheckIcon className="limegreen" />*/}
                            {/*    </Grid>*/}
                            {/*    <Grid item xs={3}>*/}
                            {/*        <CheckIcon className="limegreen" />**/}
                            {/*    </Grid>*/}




                            {/*    <Grid item xs={6}>*/}
                            {/*        OPTIONS TRADING WITH TRAPS*/}
                            {/*    </Grid>*/}
                            {/*    <Grid item xs={3}>*/}
                            {/*        <CheckIcon className="limegreen" />*/}
                            {/*    </Grid>*/}
                            {/*    <Grid item xs={3}>*/}
                            {/*        <CheckIcon className="limegreen" />**/}
                            {/*    </Grid>*/}




                            {/*    <Grid item xs={6}>*/}
                            {/*        INDEX  & STOCK OPTIONS SELLING STRATEGIES*/}
                            {/*    </Grid>*/}
                            {/*    <Grid item xs={3}>*/}
                            {/*        <CheckIcon className="limegreen" />*/}
                            {/*    </Grid>*/}
                            {/*    <Grid item xs={3}>*/}
                            {/*        <CheckIcon className="limegreen" />**/}
                            {/*    </Grid>*/}




                            {/*    <Grid item xs={6}>*/}
                            {/*        OPTIONS WRITING STRATEGY-I*/}
                            {/*    </Grid>*/}
                            {/*    <Grid item xs={3}>*/}
                            {/*        <CheckIcon className="limegreen" />*/}
                            {/*    </Grid>*/}
                            {/*    <Grid item xs={3}>*/}
                            {/*        <CheckIcon className="limegreen" />**/}
                            {/*    </Grid>*/}




                            {/*    <Grid item xs={6}>*/}
                            {/*        OPTIONS WRITING STRATEGY-II*/}
                            {/*    </Grid>*/}
                            {/*    <Grid item xs={3}>*/}
                            {/*        <CheckIcon className="limegreen" />*/}
                            {/*    </Grid>*/}
                            {/*    <Grid item xs={3}>*/}
                            {/*        <CheckIcon className="limegreen" />**/}
                            {/*    </Grid>*/}




                            {/*    <Grid item xs={6}>*/}
                            {/*        SPECIAL EVENTS TRADING STRATEGIES*/}
                            {/*    </Grid>*/}
                            {/*    <Grid item xs={3}>*/}
                            {/*        <CloseIcon className="red" />*/}
                            {/*    </Grid>*/}
                            {/*    <Grid item xs={3}>*/}
                            {/*        <CheckIcon className="limegreen" />*/}
                            {/*    </Grid>*/}




                            {/*    <Grid item xs={6}>*/}
                            {/*        CHOPPY MARKET TRADING STRATEGIES*/}
                            {/*    </Grid>*/}
                            {/*    <Grid item xs={3}>*/}
                            {/*        <CloseIcon className="red" />*/}
                            {/*    </Grid>*/}
                            {/*    <Grid item xs={3}>*/}
                            {/*        <CheckIcon className="limegreen" />*/}
                            {/*    </Grid>*/}




                            {/*    <Grid item xs={6}>*/}
                            {/*        INDEX OPTIONS SELLING SPECIAL STRATEGY-I*/}
                            {/*    </Grid>*/}
                            {/*    <Grid item xs={3}>*/}
                            {/*        <CloseIcon className="red" />*/}
                            {/*    </Grid>*/}
                            {/*    <Grid item xs={3}>*/}
                            {/*        <CheckIcon className="limegreen" />*/}
                            {/*    </Grid>*/}



                            {/*    <Grid item xs={6}>*/}
                            {/*        INDEX OPTIONS SELLING SPECIAL STRATEGY-II*/}
                            {/*    </Grid>*/}
                            {/*    <Grid item xs={3}>*/}
                            {/*        <CloseIcon className="red" />*/}
                            {/*    </Grid>*/}
                            {/*    <Grid item xs={3}>*/}
                            {/*        <CheckIcon className="limegreen" />*/}
                            {/*    </Grid>*/}




                            {/*    <Grid item xs={6}>*/}
                            {/*        COVERED CALL STRATEGY*/}
                            {/*    </Grid>*/}
                            {/*    <Grid item xs={3}>*/}
                            {/*        <CheckIcon className="limegreen" />*/}
                            {/*    </Grid>*/}
                            {/*    <Grid item xs={3}>*/}
                            {/*        <CheckIcon className="limegreen" />*/}
                            {/*    </Grid>*/}




                            {/*    <Grid item xs={6}>*/}
                            {/*        COVERED PUT TRADING STRATEGY*/}
                            {/*    </Grid>*/}
                            {/*    <Grid item xs={3}>*/}
                            {/*        <CloseIcon className="red" />*/}
                            {/*    </Grid>*/}
                            {/*    <Grid item xs={3}>*/}
                            {/*        <CheckIcon className="limegreen" />*/}
                            {/*    </Grid>*/}




                            {/*    <Grid item xs={6}>*/}
                            {/*        PYRAMIDING STRATEGIES*/}
                            {/*    </Grid>*/}
                            {/*    <Grid item xs={3}>*/}
                            {/*        <CheckIcon className="limegreen" />*/}
                            {/*    </Grid>*/}
                            {/*    <Grid item xs={3}>*/}
                            {/*        <CheckIcon className="limegreen" />*/}
                            {/*    </Grid>*/}




                            {/*    <Grid item xs={6}>*/}
                            {/*        EXIT STRATEGIES*/}
                            {/*    </Grid>*/}
                            {/*    <Grid item xs={3}>*/}
                            {/*        <CheckIcon className="limegreen" />*/}
                            {/*    </Grid>*/}
                            {/*    <Grid item xs={3}>*/}
                            {/*        <CheckIcon className="limegreen" />*/}
                            {/*    </Grid>*/}





                            {
                                (isMobile) ?
                                    <>

                                        <Grid item xs={6}>
                                            <img src={OdBoxImg} width="100%" alt="Options Dominator" />
                                            <h5 style={{ color: "red" }}><span className="strikethrough">{"₹79,999/-"}</span></h5>
                                            <h4 style={{ color: "limegreen" }}>{"₹19,698/-"}</h4>


                                            {
                                                (true) ?
                                                    <>
                                                        <a className="button button-primary button-wide-mobile button-sm" href="https://imjo.in/jPUZFM" target="_blank">Enroll Now</a>
                                                        <br />
                                                        <img src={MoneyBackImg} alt="" width="35%" />
                                                    </>
                                                    : <EnquiryNow />
                                            }
                                        </Grid>
                                        <Grid item xs={6}>
                                            <img src={OgBoxImg} width="100%" alt="Options Gladiator" />
                                            <h5 style={{ color: "red" }}><span className="strikethrough">{"₹1,59,999/-"}</span></h5>
                                            <h4 style={{ color: "limegreen" }}>{"₹53,898/-"}</h4>


                                            {
                                                (true) ?
                                                    <>
                                                    <a className="button button-primary button-wide-mobile button-sm" href="https://imjo.in/MCn5by" target="_blank">Enroll Now</a>

                                                    <br />
                                                        <img src={MoneyBackImg} width="35%" alt="" />
                                                    </>: <EnquiryNow />
                                            }
                                        </Grid>

                                    </>
                                    :
                                    <>
                                        <Grid item xs={6}>
                                            <img src={OdBoxImg} width="100%" alt="Options Dominator" />
                                            <h5 style={{ color: "red" }}><span className="strikethrough">{"₹79,999/-"}</span></h5>
                                            <h4 style={{ color: "limegreen" }}>{"₹19,698/-"}</h4>


                                            {
                                                (true) ?
                                                    <>
                                                        <a className="button button-primary button-wide-mobile button-sm" href="https://imjo.in/jPUZFM" target="_blank">Enroll Now</a>
                                                        <br />
                                                        <img src={MoneyBackImg} width="25%" alt="" />
                                                    </>
                                                    : <EnquiryNow />
                                            }
                                        </Grid>
                                        <Grid item xs={6}>
                                            <img src={OgBoxImg} width="100%" alt="Options Gladiator" />
                                            <h5 style={{ color: "red" }}><span className="strikethrough">{"₹1,59,999/-"}</span></h5>
                                            <h4 style={{ color: "limegreen" }}>{"₹53,898/-"}</h4>


                                            {
                                                (true) ?
                                                    <>
                                                    <a className="button button-primary button-wide-mobile button-sm" href="https://imjo.in/MCn5by" target="_blank">Enroll Now</a>
                                                    <br />
                                                        <img src={MoneyBackImg} width="25%" alt="" />
                                                    </>
                                                    : <EnquiryNow />
                                            }
                                        </Grid>

                                    </>
                            }





                        </Grid>

                        {/*<h4>BANKNIFTY OPTIONS BUYING STRATEGY</h4>*/}


                        {/*<h4>NIFTY OPTIONS BUYING STRATEGY</h4>*/}


                        {/*<h4>INDEX OPTIONS BUYING STRAGETY</h4>*/}


                        {/*<h4>BANKNIFTY OPTIONS SELLING STRATEGY</h4>*/}


                        {/*<h4>NIFTY OPTIONS SELLING STRATEGY</h4>*/}


                        {/*<h4>INDEX OPTIONS SELLING STRAGETY</h4>*/}


                        {/*<h4>INDEX WEEKLY AND MONTHLY EXPIRY SPECIAL </h4>*/}


                        {/*<h4>NIFTY EXPIRY SPECIAL STRATEGY</h4>*/}


                        {/*<h4>BANK NIFTY EXPIRY SPECIAL STRATEGY</h4>*/}


                        {/*<h4>POSITIONAL STOCK OPTION BUYING & SELLING STRATEGY</h4>*/}


                        {/*<h4>INTRADAY INDEX OPTIONS AND STOCK OPTIONS BUYING STRATEGY</h4>*/}


                        {/*<h4>EVENT SPECIAL OPTION TRADING STRATEGY</h4>*/}


                        {/*<h4>PORTFOLIO HEDGING STRATEGY</h4>*/}


                        {/*<h4>PYRAMYDING ENTRY AND EXIT STRATEGY</h4>*/}

                        {/*<h4>HIGH PERFORMANCE TRADER - MINDSET COURSE</h4>*/}
                        {/*<br />*/}

                        {/*<h4>SUPPORT</h4>*/}
                        {/*<p>*/}
                        {/*    Q&A session with <i>Santosh Choudhury</i>*/}
                        {/*    <br />*/}
                        {/*    Doubt clearing session with <i>Santosh Choudhury</i>*/}
                        {/*</p>*/}
                        {/*<br />*/}

                        {/*<h4>EXTRA BENEFITS</h4>*/}
                        {/*<p className="text-color-primary blink">*/}
                        {/*    <b>Free Access to High Performance Trader Mindset Course (Bonus)</b>*/}
                        {/*    <br />*/}
                        {/*    <b>Free Lifetime Access to Basics of Futures & Options Course (Bonus).</b>*/}
                        {/*    <br />*/}
                        {/*    <b>One Mastermind Session for Market Analysis (Bonus).</b>*/}
                        {/*</p>*/}

                    </div>

                </div>

            </div>
        </section>
    );
}
Od.propTypes = propTypes;
Od.defaultProps = defaultProps;

export default Od;

function EnquiryNow() {
    return (
        <a className="button button-primary button-wide-mobile button-sm" href={whatsAppRef} target="_blank" >Enquiry Now</a>
    );
}