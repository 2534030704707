import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import * as serviceWorker from './serviceWorker';
import rootReducer from './redux/reducers'
import { createStore } from 'redux';
import { Provider } from "react-redux"

import { createTheme, ThemeProvider } from '@mui/material/styles';


import App from './App';

//import './App.css';
import './assets/scss/style.scss';

const history = createBrowserHistory();

const store = createStore(rootReducer)


export const theme = createTheme({
    palette: {
        primary: {
            main: "#5658DD" // This is an orange looking color
        },
        secondary: {
            main: "#ECEDED" //Another orange-ish color
        }
    },
});

ReactDOM.render(
    <Provider store={store}>
        <Router history={history}>

            <ThemeProvider theme={theme}>
                <App />
            </ThemeProvider>

        </Router>
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
