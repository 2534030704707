import React from 'react';
import classNames from 'classnames';

import Avatar from '@mui/material/Avatar';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import TelegramIcon from '@mui/icons-material/Telegram';
import InstagramIcon from '@mui/icons-material/Instagram';
import Tooltip from '@mui/material/Tooltip';

const FooterSocial = ({
    className,
    ...props
}) => {

    const classes = classNames(
        'footer-social',
        className
    );

    return (
        <div
            {...props}
            className={classes}
        >
            <ul className="list-reset">
                <li>
                    <Tooltip title="Facebook" placement="top" arrow>
                        <a href="https://www.facebook.com/indanetrader/" target="_blank">
                            <Avatar sx={{ m: 1, bgcolor: 'blue' }}>
                                <FacebookIcon style={{ fill: '#fff' }} />
                            </Avatar>
                        </a>
                    </Tooltip>
                </li>
                <li>
                    <Tooltip title="YouTube" placement="top" arrow>
                        <a href="https://www.youtube.com/channel/UCbqThWosc3wP7YJ0a2T5EnQ" target="_blank" >
                            <Avatar sx={{ m: 1, bgcolor: 'red' }}>
                                <YouTubeIcon style={{ fill: '#fff' }} />
                            </Avatar>

                        </a>
                    </Tooltip>
                </li>
                <li>
                    <Tooltip title="Telegram" placement="top" arrow>
                        <a href="http://t.me/santoshchoudhury87" target="_blank">
                            <Avatar sx={{ m: 1, bgcolor: '#2e87ca' }}>
                                <TelegramIcon style={{ fill: '#fff' }} />
                            </Avatar>
                        </a>
                    </Tooltip>
                </li>
                <li>
                    <Tooltip title="Instagram" placement="top" arrow>
                        <a href="https://www.instagram.com/santoshchoudhury87/" target="_blank">
                            <Avatar sx={{ m: 1, bgcolor: '#E1306C' }}>
                                <InstagramIcon style={{ fill: '#fff' }} />
                            </Avatar>
                        </a>
                    </Tooltip>
                </li>
            </ul>
        </div>
    );
}

export default FooterSocial;