
import React, { useState } from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import Image from '../elements/Image';
import SectionHeader from '../sections/partials/SectionHeader';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close'



import review1 from '../../assets/images/reviews/trustpilot-review-1.png';
import review2 from '../../assets/images/reviews/trustpilot-review-2.png';
import review3 from '../../assets/images/reviews/trustpilot-review-3.png';
import review4 from '../../assets/images/reviews/trustpilot-review-4.png';
import review5 from '../../assets/images/reviews/trustpilot-review-5.png';
import review6 from '../../assets/images/reviews/trustpilot-review-6.png';



const propTypes = {
    ...SectionProps.types
}

const defaultProps = {
    ...SectionProps.defaults
}

const Review = ({
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    pushLeft,
    ...props
}) => {

    const outerClasses = classNames(
        'hero section center-content',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        className
    );

    const innerClasses = classNames(
        'testimonial-inner section-inner',
        topDivider && 'has-top-divider',
        bottomDivider && 'has-bottom-divider'
    );

    const tilesClasses = classNames(
        'tiles-wrap',
        pushLeft && 'push-left'
    );

    const sectionHeader = {
        title: 'Students\' Reviews',
        paragraph: ''
    };

    const reviewImgsList = [review1, review2, review3, review4, review5, review6];

    const [reviewImg, setreviewImg] = useState("");

    const [reviewImgModalActive, setreviewImgmodalactive] = useState(false);

    const openreviewImgModal = (e) => {
        e.preventDefault();
        let index = e.target.id;
        setreviewImgmodalactive(true);
        setreviewImg(reviewImgsList[index]);
        
    }

    const closeModal = (e) => {
        e.preventDefault();
        setreviewImgmodalactive(false);
    }

    return (
        <section
            {...props}
            className={outerClasses}
        >
            <div className="container">
                <div className="has-top-divider">
                    <br />
                    <SectionHeader data={sectionHeader} className="center-content" />
                    <div className="center-content">
                        <div className="trustpilot-widget" data-locale="en-US" data-template-id="5419b6a8b0d04a076446a9ad" data-businessunit-id="622ed0ef535b43c191ffb5fe" data-style-height="24px" data-style-width="100%" data-theme="light" data-min-review-count="10">
                            <a href="https://www.trustpilot.com/review/indanetrader.com" target="_blank" rel="noopener">Trustpilot</a>
                        </div>
                    </div>
                    
                </div>
            </div>


            <div className="container">
                <div className={innerClasses}>
                    
                    <div className={tilesClasses}>

                        <div className="tiles-item reveal-from-right" data-reveal-delay="200">
                            <div className="tiles-item-inner">
                                <div className="testimonial-item-content">
                                    <p className="text-sm mb-0">
                                        <img src={review1} alt="" id="0" onClick={openreviewImgModal} />
                                    </p>
                                </div>
                                
                            </div>
                        </div>

                        <div className="tiles-item reveal-from-bottom">
                            <div className="tiles-item-inner">
                                <div className="testimonial-item-content" >
                                    <p className="text-sm mb-0">
                                        <img src={review2} alt="" id="1" onClick={openreviewImgModal} />
                                    </p>
                                </div>
                                
                            </div>
                        </div>

                        <div className="tiles-item reveal-from-left" data-reveal-delay="200">
                            <div className="tiles-item-inner">
                                <div className="testimonial-item-content">
                                    <p className="text-sm mb-0">
                                        <img src={review3} alt="" id="2" onClick={openreviewImgModal} />
                                    </p>
                                </div>
                               
                            </div>
                        </div>

                        <div className="tiles-item reveal-from-left" data-reveal-delay="200">
                            <div className="tiles-item-inner">
                                <div className="testimonial-item-content">
                                    <p className="text-sm mb-0">
                                        <img src={review4} alt="" id="3" onClick={openreviewImgModal} />
                                    </p>
                                </div>

                            </div>
                        </div>

                        <div className="tiles-item reveal-from-left" data-reveal-delay="200">
                            <div className="tiles-item-inner">
                                <div className="testimonial-item-content">
                                    <p className="text-sm mb-0">
                                        <img src={review5} alt="" id="4" onClick={openreviewImgModal} />
                                    </p>
                                </div>

                            </div>
                        </div>

                        <div className="tiles-item reveal-from-left" data-reveal-delay="200">
                            <div className="tiles-item-inner">
                                <div className="testimonial-item-content">
                                    <p className="text-sm mb-0">
                                        <img src={review6} alt="" id="5" onClick={openreviewImgModal} />
                                    </p>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <Dialog
                open={reviewImgModalActive}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogContent>
                    <IconButton
                        aria-label="close"
                        onClick={closeModal}
                        sx={{
                            position: 'absolute',
                            right: 0,
                            top: 0,
                            padding: 0,
                            color: "#000",
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <div>
                        <img src={reviewImg} alt="" />
                    </div>

                </DialogContent>
            </Dialog>

        </section>
    );
}

Review.propTypes = propTypes;
Review.defaultProps = defaultProps;

export default Review;