import { createTheme } from '@mui/material/styles';

export const PrimaryColor = "#5658DD";

export const ArTheme = createTheme({
    palette: {
        primary: {
            main: PrimaryColor // This is an orange looking color
        },
        secondary: {
            main: "#ffcc80" //Another orange-ish color
        }
    },
});