import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';
import { Link } from 'react-router-dom';

import icatImage from './../../assets/images/icat.jpg';
import odImage from './../../assets/images/od.jpg';
import ogImage from './../../assets/images/og.jpg';
import tmpImage from './../../assets/images/tmp.jpg';

import * as ArEvents from './../../Constants/EventDetails';

const propTypes = {
    ...SectionSplitProps.types
}

const defaultProps = {
    ...SectionSplitProps.defaults
}

const FeaturesSplit = ({
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    invertMobile,
    invertDesktop,
    alignTop,
    imageFill,
    ...props
}) => {

    const outerClasses = classNames(
        'features-split section',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        className
    );

    const innerClasses = classNames(
        'features-split-inner section-inner',
        topDivider && 'has-top-divider',
        bottomDivider && 'has-bottom-divider'
    );

    const splitClasses = classNames(
        'split-wrap',
        invertMobile && 'invert-mobile',
        invertDesktop && 'invert-desktop',
        alignTop && 'align-top'
    );

    const sectionHeader = {
        title: 'Our Courses',
        paragraph: ''
    };

    return (
        <section
            {...props}
            className={outerClasses}
        >
            <div className="container">
                <div className={innerClasses}>
                    <SectionHeader data={sectionHeader} className="center-content" />
                    <div className={splitClasses}>

                        <div className="split-item">
                            <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item" style={{ textAlign: "justify" }}>
                                <h3 className="mt-0 mb-12">
                                    I Can also Trade
                                </h3>
                                <p className="m-0">
                                    This course includes all the foundation of stock market and simple strategies to start trading and investing.
                                    You will learn how to develop your mindset in trading and other aspects of your life and how to grow in your life and achieve <b>FINANCIAL FREEDOM</b>.
                                    <br />
                                    {
                                        (ArEvents.IcatEvent.EventDate != "") ?
                                            <span>Upcoming iCAT batch is starting from <b className="text-color-primary blink" >{ArEvents.IcatEvent.EventDate} from {ArEvents.IcatEvent.EventTime}</b></span>
                                            : null
                                    }
                                    <br />
                                    <Link to="/icat" className="button button-primary button-wide-mobile button-sm">Know More</Link>
                                </p>
                            </div>
                            <div className={
                                classNames(
                                    'split-item-image center-content-mobile reveal-from-bottom',
                                    imageFill && 'split-item-image-fill'
                                )}
                                data-reveal-container=".split-item">
                                <Image
                                    src={icatImage}
                                    alt="iCAT"
                                     />
                            </div>
                        </div>

                        <div className="split-item">
                            <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item" style={{ textAlign: "justify" }}>
                                
                                <h3 className="mt-0 mb-12">
                                    Options Dominator
                                </h3>
                                <p className="m-0">
                                    This Course comes with the foundation of the Derivatives Market (Futures & Options) and Basic Simple Options Trading Strategies at a beginner level which will lead you to learn Options Trading with proper risk management. This Course also includes dozens of powerful videos on mindset to improve your performance in your Trading Career/Business.
                                    <br /><br />
                                    <Link to="/options-trading" className="button button-primary button-wide-mobile button-sm">Know More</Link>
                                </p>
                                

                            </div>
                            <div className={
                                classNames(
                                    'split-item-image center-content-mobile reveal-from-bottom',
                                    imageFill && 'split-item-image-fill'
                                )}
                                data-reveal-container=".split-item">
                                <Image
                                    src={odImage}
                                    alt="Options Dominator"
                                     />
                            </div>
                        </div>

                        <div className="split-item">
                            <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item" style={{ textAlign: "justify" }}>
                                
                                <h3 className="mt-0 mb-12">
                                    Options Gladiator
                                </h3>
                                <p className="m-0">
                                    This Course also comes with the foundation of the Derivatives Market (Futures & Options) and Advanced Simple Options Trading Strategies at an expert level which will lead you to Trade Options like a professional trader with proper risk management. You can trade options all around the year with all kinds of market scenarios and earn exponential returns. This Course also includes dozens of powerful videos on mindset to improve your performance in your Trading Career/Business.
                                    <br /><br />
                                    <Link to="/options-trading" className="button button-primary button-wide-mobile button-sm">Know More</Link>
                                </p>
                            </div>
                            <div className={
                                classNames(
                                    'split-item-image center-content-mobile reveal-from-bottom',
                                    imageFill && 'split-item-image-fill'
                                )}
                                data-reveal-container=".split-item">
                                <Image
                                    src={ogImage}
                                    alt="Options Gladiator"
                                     />
                                
                            </div>
                        </div>

                        <div className="split-item">
                            <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item" style={{ textAlign: "justify" }}>
                                
                                <h3 className="mt-0 mb-12">
                                    Trader Mastery Program
                                </h3>
                                <p className="m-0">
                                    This is a yearly subscription-based mentorship course that comes with everything about Trading/Investing. This course will also take you through personal growth. This is not WHAT YOU DO but WHAT YOU ARE. You will learn to trade in Cash, F&O, Commodity, Currency & Crypto Markets. You will learn to trade Intraday, BTST, STBT, Short Term, Swing Trades, Positional Trades and Investing. You will get Live Trading support, Regular Masterminds and QnA sessions and other software support from us.
                                </p>
                                {
                                    (ArEvents.FttEvent.EventDate == "") ?
                                        <h3 className="text-color-primary blink" > <i>Coming Soon...</i> </h3>
                                        : null
                                }
                            </div>
                            <div className={
                                classNames(
                                    'split-item-image center-content-mobile reveal-from-bottom',
                                    imageFill && 'split-item-image-fill'
                                )}
                                data-reveal-container=".split-item">
                                <Image
                                    src={tmpImage}
                                    alt="Trader Mastery Program"
                                     />
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </section>
    );
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;