import React, { useState, Component } from 'react';
import axios from 'axios';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { ThemeProvider } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import CircularProgress from '@mui/material/CircularProgress';

import * as ApiConstants from './../../Constants/ApiConstants';

import VpnKeyIcon from '@mui/icons-material/VpnKey';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';


export default function ForgotPassword(props) {
    const [openResetMsg, setOpenResetMsg] = useState(false);
    const [currentUserEmail, setUserEmail] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [showResetPwdWindow, setShowResetPwdWindow] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleCloseResetMsg = () => {
        setOpenResetMsg(false);
    };
    const [values, setValues] = useState({
        password: '',
        confirmpassword: '',
        showPassword: false,
        otp: '',
        email: ''
    });
    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });

    };
    const handleClickShowPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
        });
    };
    const handleClickShowCurrentPassword = () => {
        setValues({
            ...values,
            showCurrentPassword: !values.showCurrentPassword,
        });
    };
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const checkEmailValidationError = (email) => {
        if (email.trim() === "") {
            return true;
        }
        else if (!(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/.test(email))) {
            return true;
        }

        return false;
    };
    const checkEmailValidation = (email) => {
        if (email === "") return "Please enter Email";
        if (/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/.test(email)) {
            return "";
        } else {

            return 'Please enter correct email adress'
        }
    };


    const handleEmailSubmit = (event) => {
        event.preventDefault();
        setIsLoading(true);
        const email = values.email;
        setUserEmail(values.email);
        axios.post(ApiConstants.UserApis.ForgotPasswordPath, null, {
            params: {
                email
            }
        })
            .then(response => {
                setIsLoading(false);
                setShowResetPwdWindow(true);
            })
            .catch(function (error) {
                setIsLoading(false);
                try {
                    if (error.response.data != undefined) {
                        setErrorMessage(error.response.data);
                    }
                    else {
                        setErrorMessage("Something went wrong ! Please contact Admin.");
                    }
                }
                catch (e) {
                    setErrorMessage("Something went wrong ! Please contact Admin.");
                }
            });
    };

    const handleMouseDownCurrentPassword = (event) => {
        event.preventDefault();
    };
    const handleResetPassword = (event) => {
        event.preventDefault();
        setIsLoading(true);
        const resetParam = {
            userEmail: currentUserEmail,
            otp: values.otp,
            Password: values.password,
        }
        console.log(resetParam);

        axios
            .post(ApiConstants.UserApis.ResetPasswordPath, resetParam)
            .then(response => {
                setIsLoading(false);
                console.log(response.data);               
                setShowResetPwdWindow(false);

                props.openResetSuccessAlert();
                props.closeForgotPassword();
                
            })
            .catch(function (error) {                
                setIsLoading(false);
                console.log(error);
                try {
                    if (error.response.data != undefined) {
                        setErrorMessage(error.response.data);
                    }
                    else {
                        setErrorMessage("Something went wrong ! Please contact Admin.");
                    }
                }
                catch (e) {
                    setErrorMessage("Something went wrong ! Please contact Admin.");
                }
            });

    }
    const CheckPassword = (pwd) => {
        if (pwd === "") return "Please enter password";
        const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%\^&\*])(?=.{8,})");
        if (!strongRegex.test(pwd)) return "Password should contain alteast 1 lower letter, 1 upper letter, 1 special character and minimum of 8 character";
        return "";
    }
    const CheckCurrentPassword = () => {
        if (values.password !== values.confirmpassword) return "Password & Confirm Password is not matching"

    };
    
  


    return (
        <ThemeProvider >
            <Container component="main" maxWidth="xs">
                {!showResetPwdWindow ?
                    <Box
                        sx={{
                            marginTop: 8,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
                            <VpnKeyIcon />
                        </Avatar>
                        <h3 className="text-color-primary">Forgot Password</h3>
                        <Box component="form" onSubmit={handleEmailSubmit} noValidate sx={{ mt: 1 }}>
                            <TextField
                                sx={{ width: "100%" }}
                                margin="normal"
                                variant="outlined"
                                id="email"
                                label="Email Address"
                                name="email"
                                required
                                autoComplete="email"
                                error={checkEmailValidationError(values.email)}
                                onChange={handleChange('email')}
                                value={values.email}
                                helperText={checkEmailValidation(values.email)}
                            />
                            {(errorMessage != "") ?
                                <div className="error-message">
                                    <label style={{ color: 'red' }} >{errorMessage}</label>
                                </div>
                                : null}
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                                disabled={checkEmailValidationError(values.email)}
                            >
                                {
                                    isLoading ? <CircularProgress /> : "Submit"
                                }
                                
                            </Button>

                        </Box>
                    </Box>
                    : null
                }

                {showResetPwdWindow ?
                    <Box
                        sx={{
                            marginTop: 8,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
                            <VpnKeyIcon />
                        </Avatar>
                        <h3 className="text-color-primary">Reset Password</h3>
                        <Box component="form" onSubmit={handleResetPassword} noValidate sx={{ mt: 1 }}>

                            <TextField
                                sx={{ width: "100%" }}
                                margin="normal"
                                variant="outlined"
                                id="otp"
                                label="OTP"
                                name="otp"
                                required
                                autoComplete="new-otp"
                                error={values.otp === ""}
                                onChange={handleChange('otp')}
                                helperText={(values.otp === "") ? "Please enter OTP" : ""}
                            />

                            <TextField
                                sx={{ width: "100%" }}
                                margin="normal"
                                variant="outlined"
                                id="password"
                                label="Password"
                                name="password"
                                required
                                autoComplete="new-password"
                                type={values.showPassword ? 'text' : 'password'}
                                value={values.password}
                                onChange={handleChange('password')}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {values.showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>,
                                }}
                                error={values.password === "" || (values.password !== values.confirmpassword) || (CheckPassword(values.password) !== "")}
                                helperText={CheckPassword(values.password)}
                            />
                            <TextField
                                sx={{ width: "100%" }}
                                margin="normal"
                                variant="outlined"
                                id="confirmpassword"
                                label="Confirm Password"
                                name="Confirm Password"
                                required
                                autoComplete="new-password"
                                type={values.showCurrentPassword ? 'text' : 'password'}
                                value={values.confirmpassword}
                                onChange={handleChange('confirmpassword')}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowCurrentPassword}
                                            onMouseDown={handleMouseDownCurrentPassword}
                                            edge="end"
                                        >
                                            {values.showCurrentPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>,
                                }}
                                error={values.password === "" || (values.password !== values.confirmpassword)}
                                helperText={CheckCurrentPassword()}
                            />
                            {(errorMessage != "") ?
                                <div className="error-message">
                                    <label style={{ color: 'red' }} >{errorMessage}</label>
                                </div>
                                : null}
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                                disabled={values.confirmpassword.trim() === "" || (values.password !== values.confirmpassword) || values.otp === "" || (CheckPassword(values.password) !== "")}
                            >
                                {
                                    isLoading ? <CircularProgress /> : "Submit"
                                }
                            </Button>
                        </Box>
                    </Box>
                    : null}


            </Container>
        </ThemeProvider>
    );
}