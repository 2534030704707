
import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import axios from 'axios';

import Grid from '@mui/material/Grid';
import { DataGrid } from '@mui/x-data-grid';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import { ThemeProvider } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';


import * as ApiConstants from './../../Constants/ApiConstants'
import * as ArEvents from './../../Constants/EventDetails'

import CloseIcon from '@mui/icons-material/Close';
import VideoSettingsIcon from '@mui/icons-material/VideoSettings';


const propTypes = {
    ...SectionProps.types
}

const defaultProps = {
    ...SectionProps.defaults
}

const Video = ({
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    ...props
}) => {

    var authHeader = {};

    if (localStorage.getItem("userInfo") != undefined) {
        let userInfo = JSON.parse(localStorage.getItem("userInfo"));
        authHeader = {
            headers: {
                'Authorization': `Bearer ${userInfo.token}`
            }
        }
    };

    useEffect(() => {
        GetAllVideos();
    }, []);

    var userInfo = {};

    let loggedInUser = localStorage.getItem("userInfo");

    if (loggedInUser != undefined) {
        userInfo = JSON.parse(loggedInUser);
    };

    const outerClasses = classNames(
        'hero section center-content',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        className
    );

    const innerClasses = classNames(
        'hero-inner section-inner',
        topDivider && 'has-top-divider',
        bottomDivider && 'has-bottom-divider'
    );

    const [openAddVideoWindow, setOpenAddVideoWindow] = useState(false);

    const handleCloseAddVideoWindow = () => {
        setOpenAddVideoWindow(false);
    }

    const handleOpenAddVideoWindow = () => {
        setAddVideoValues({
            videoId: 0,
            courseName: '',
            videoName: '',
            videoLink: '',
            videoImageName: '',
            createdby: ''
        });
        setOpenAddVideoWindow(true);
        setErrorMessage("");
    }

    const [addVideoValues, setAddVideoValues] = useState({
        videoId: 0,
        courseName: '',
        videoName: '',
        videoLink: '',
        videoImageName: '',
        createdby: ''
    });

    const handleAddVideoValueChange = (prop) => (event) => {
        setAddVideoValues({ ...addVideoValues, [prop]: event.target.value });
    };

    const handleAddVideoSubmit = () => {

        let video = addVideoValues;

        if (userInfo != null) {
            video.createdby = userInfo.email;
        };

        axios.post(ApiConstants.CourseApis.AddVideoPath, video, authHeader)
            .then(response => {
                console.log(response);
                if (response.status === 200) {
                    setOpenVideoAddedSuccess(true);
                    handleCloseAddVideoWindow();
                    GetAllVideos();
                }

            })
            .catch(error => {
                console.log(error);
                setErrorMessage("Something went wrong !! Kindly contact Admin");
            });
    }

    const handleUpdateVideoSubmit = () => {
        let video = addVideoValues;

        if (userInfo != null) {
            video.lastModifiedBy = userInfo.email;
        };

        axios.post(ApiConstants.CourseApis.UpdateVideoPath, video, authHeader)
            .then(response => {
                console.log(response);
                if (response.status === 200) {
                    setOpenVideoAddedSuccess(true);
                    handleCloseAddVideoWindow();
                    GetAllVideos();
                }

            })
            .catch(error => {
                console.log(error);
                setErrorMessage("Something went wrong !! Kindly contact Admin");
            });
    }

    const [openVideoAddedSuccess, setOpenVideoAddedSuccess] = useState(false);

    const handleCloseVideoAddedSuccess = () => {
        setOpenVideoAddedSuccess(false);
    }
    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    const [errorMessage, setErrorMessage] = useState("");

    const [videos, setvideos] = useState([]);
    const [defaultVideos, setDefaultVideos] = useState([]);

    const GetAllVideos = () => {
        axios.get(ApiConstants.CourseApis.GetAllVideosPath, authHeader)
            .then(response => {
                let videos = [];
                setDefaultVideos(response.data);
                response.data.forEach(function (video) {
                    let item = {
                        "id": video.videoId,
                        "courseName": video.courseName,
                        "videoName": video.videoName
                    }
                    videos.push(item);
                })
                setvideos(videos);
            })
            .catch(error => {
                console.log(error);
            });
    };

    const videocolumns = [
        { field: 'id', headerName: 'Sl.No', flex: 0.5 },
        { field: 'courseName', headerName: 'Course', flex: 1 },
        { field: 'videoName', headerName: 'Video Name', flex: 1 },
        {
            field: "edit",
            headerName: "Edit",
            sortable: false,
            renderCell: (params) => {
                const onClick = (e) => {
                    e.stopPropagation(); // don't select this row after clicking

                    const api = params.api;
                    let videoid = "";
                    api
                        .getAllColumns()
                        .filter((c) => c.field == "id")
                        .forEach(
                            (c) => (videoid = params.getValue(params.id, c.field))
                        );

                    let vdoDet = defaultVideos.filter((u) => u.videoId == videoid);
                    let vdoToedit = vdoDet[0];

                    let vdo = {
                        videoId: vdoToedit.videoId,
                        courseName: vdoToedit.courseName,
                        videoName: vdoToedit.videoName,
                        videoLink: vdoToedit.videoLink,
                        videoImageName: vdoToedit.videoImageName,
                        lastModifiedBy: ''
                    }
                    setAddVideoValues(vdo);
                    setErrorMessage("");
                    setOpenAddVideoWindow(true);
                    //console.log(JSON.stringify(vdoToedit));
                };

                return <a className="button button-primary button-wide-mobile button-sm" onClick={onClick}>Edit</a>;
            }
        },
    ];

    return (
        <section
            {...props}
            className={outerClasses}
        >

            <div className="container" style={{ textAlign: "right" }}>
                <a className="button button-primary button-wide-mobile button-sm" onClick={handleOpenAddVideoWindow} >Add New Video</a>
            </div>

            <br />

            <div className="container ar-gray-back">
                <div style={{ height: 300, width: '100%' }}>
                    <DataGrid rows={videos} columns={videocolumns} />
                </div>
            </div>

            <Dialog
                open={openAddVideoWindow}

                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <IconButton
                    aria-label="close"
                    onClick={handleCloseAddVideoWindow}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>

                <ThemeProvider >
                    <Container component="main" maxWidth="xs">
                        <Box
                            sx={{
                                marginTop: 8,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
                                <VideoSettingsIcon />
                            </Avatar>
                            <Box sx={{ mt: 1 }}>
                                <FormControl fullWidth margin="normal" >
                                    <InputLabel id="courseNameLabel">Course</InputLabel>
                                    <Select
                                        labelId="courseNameLabel"
                                        id="courseName"
                                        label="Course"
                                        value={addVideoValues.courseName}
                                        onChange={handleAddVideoValueChange('courseName')}
                                        required
                                    >
                                        {ArEvents.ArCourses.map((course) => (
                                            <MenuItem
                                                value={course}
                                            >
                                                {course}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <TextField
                                    sx={{ width: "100%" }}
                                    margin="normal"
                                    variant="outlined"
                                    id="videoName"
                                    label="Video Name"
                                    name="videoName"
                                    required
                                    onChange={handleAddVideoValueChange('videoName')}
                                    value={addVideoValues.videoName}
                                />
                                {/*error={handleValidationError('eventPaymentLink')}*/}
                                {/*helperText={handleValidationErrorMessage('eventPaymentLink')}*/}

                                <TextField
                                    sx={{ width: "100%" }}
                                    margin="normal"
                                    variant="outlined"
                                    id="videoLink"
                                    label="Video Link"
                                    name="videoLink"
                                    onChange={handleAddVideoValueChange('videoLink')}
                                    value={addVideoValues.videoLink}
                                />


                                <TextField
                                    sx={{ width: "100%" }}
                                    margin="normal"
                                    variant="outlined"
                                    id="videoImageName"
                                    label="Video Image Name"
                                    name="videoImageName"
                                    onChange={handleAddVideoValueChange('videoImageName')}
                                    value={addVideoValues.videoImageName}
                                />


                                {(errorMessage != "") ?
                                    <div className="error-message">
                                        <label style={{ color: 'red' }} >{errorMessage}</label>
                                    </div>
                                    : null
                                }

                                {
                                    (addVideoValues.videoId == 0) ?
                                        <Button
                                            type="button"
                                            fullWidth
                                            variant="contained"
                                            sx={{ mt: 3, mb: 2 }}
                                            onClick={handleAddVideoSubmit}
                                            disabled={addVideoValues.courseName === "" || addVideoValues.videoName.trim() === "" || addVideoValues.videoLink.trim() === ""}
                                        >
                                            Add Video
                                    </Button>
                                        :
                                        <Button
                                            type="button"
                                            fullWidth
                                            variant="contained"
                                            sx={{ mt: 3, mb: 2 }}
                                            onClick={handleUpdateVideoSubmit}
                                            disabled={addVideoValues.courseName === "" || addVideoValues.videoName.trim() === "" || addVideoValues.videoLink.trim() === ""}
                                        >
                                            Update
                                    </Button>
                                }



                            </Box>
                        </Box>
                    </Container>
                </ThemeProvider>


            </Dialog>

            <Snackbar
                open={openVideoAddedSuccess}
                autoHideDuration={6000}
                onClose={handleCloseVideoAddedSuccess}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
                <Alert onClose={handleCloseVideoAddedSuccess} severity="success" sx={{ width: '100%' }}>
                    {
                        (addVideoValues.videoId == 0) ?
                            <span>Video has been Added sucessfully!</span>
                            :
                            <span>Video has been updated sucessfully!</span>
                    }
                </Alert>
            </Snackbar>

        </section>
    );
}

Video.propTypes = propTypes;
Video.defaultProps = defaultProps;

export default Video;