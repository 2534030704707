import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import Login from './../Login/login';
import Logo from './partials/Logo';
import classNames from 'classnames';

import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Tooltip from '@mui/material/Tooltip';

import AccountCircle from '@mui/icons-material/AccountCircle';
import CloseIcon from '@mui/icons-material/Close';

import axios from 'axios';
import * as ApiConstants from './../../Constants/ApiConstants';



const propTypes = {
    navPosition: PropTypes.string,
    hideNav: PropTypes.bool,
    hideSignin: PropTypes.bool,
    bottomOuterDivider: PropTypes.bool,
    bottomDivider: PropTypes.bool
}

const defaultProps = {
    navPosition: '',
    hideNav: false,
    hideSignin: false,
    bottomOuterDivider: false,
    bottomDivider: false
}

const Header = ({
    className,
    navPosition,
    hideNav,
    hideSignin,
    bottomOuterDivider,
    bottomDivider,
    ...props
}) => {


    const [isActive, setIsactive] = useState(false);


    var userInfo = {};
    const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);

    let loggedInUser = localStorage.getItem("userInfo");
    console.log("Head");
    if (typeof (loggedInUser) != 'undefined' && loggedInUser != null) {
        userInfo = JSON.parse(loggedInUser);
        let authHeader = {
            headers: {
                'Authorization': `Bearer ${userInfo.token}`
            }
        }
        console.log("validating access")
        axios.get(ApiConstants.UserApis.ValidateAccessPath, authHeader)
            .then(response => {
                console.log("active");
                setIsUserLoggedIn(true);
                if (userInfo.role == "Admin") setIsAdmin(true);
            })
            .catch(error => {
                console.log("expired");
                localStorage.clear();
                //console.log(error);
                setIsUserLoggedIn(false);
                setIsAdmin(false);
                history.push("/");
            });
    };

    const nav = useRef(null);
    const hamburger = useRef(null);
    const history = useHistory();
    const dispatch = useDispatch();
    useEffect(() => {
        isActive && openMenu();
        document.addEventListener('keydown', keyPress);
        document.addEventListener('click', clickOutside);

        return () => {

            document.removeEventListener('keydown', keyPress);
            document.removeEventListener('click', clickOutside);
            closeMenu();
        };

    });

    const openMenu = () => {
        document.body.classList.add('off-nav-is-active');
        nav.current.style.maxHeight = nav.current.scrollHeight + 'px';
        setIsactive(true);
    }

    const closeMenu = () => {
        document.body.classList.remove('off-nav-is-active');
        nav.current && (nav.current.style.maxHeight = null);
        setIsactive(false);
    }

    const keyPress = (e) => {
        isActive && e.keyCode === 27 && closeMenu();
    }

    const clickOutside = (e) => {
        if (!nav.current) return
        if (!isActive || nav.current.contains(e.target) || e.target === hamburger.current) return;
        closeMenu();
    }

    const classes = classNames(
        'site-header',
        bottomOuterDivider && 'has-bottom-divider',
        className
    );

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const [anchorEl, setAnchorEl] = useState("");

    const logout = () => {
        localStorage.clear();
        setAnchorEl(null);
        setOpenLogoutSuccess(true);
        dispatch({
            type: 'UserLoggedOut'
        });
        setIsUserLoggedIn(false);
        setIsAdmin(false);
        history.push("/");
    };

    const profileOpen = Boolean(anchorEl);
    const profileClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const ProfileClose = () => {
        setAnchorEl(null);
    };

    const [openLogoutSuccess, setOpenLogoutSuccess] = useState(false);
    const CloseLogoutSuccess = () => {
        setOpenLogoutSuccess(false);
    }
    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });
    return (
        <header
            {...props}
            className={classes}
        >
            <div className="container">
                <div className={
                    classNames(
                        'site-header-inner',
                        bottomDivider && 'has-bottom-divider'
                    )}>
                    <Logo />
                    {!hideNav &&
                        <>
                            <button
                                ref={hamburger}
                                className="header-nav-toggle"
                                onClick={isActive ? closeMenu : openMenu}
                            >
                                <span className="screen-reader">Menu</span>
                                <span className="hamburger">
                                    <span className="hamburger-inner"></span>
                                </span>
                            </button>
                            <nav
                                ref={nav}
                                className={
                                    classNames(
                                        'header-nav',
                                        isActive && 'is-active'
                                    )}>
                                <div className="header-nav-inner">
                                    <ul className={
                                        classNames(
                                            'list-reset text-xs',
                                            navPosition && `header-nav-${navPosition}`
                                        )}>
                                        {isUserLoggedIn ?
                                            <li>
                                                <Link to="/Dashboard" onClick={closeMenu} title="Dashboard"><b>Dashboard</b></Link>
                                            </li>
                                            : null
                                        }
                                        <li>
                                        <Tooltip title="I Can Also Trade" placement="bottom" arrow>
                                            <Link to="/icat" onClick={closeMenu} ><b><span className="cursive-italic" style={{ fontSize: "larger" }}>i</span>CAT</b></Link>
                                            </Tooltip>
                                        </li>


                                        <li>
                                            <Tooltip title="Options" placement="bottom" arrow>
                                            <Link to="/options-trading" onClick={closeMenu} ><b>Options Trading</b></Link>
                                            </Tooltip>
                                    </li>
                                    {/*<li>*/}
                                    {/*    <Tooltip title="Options Gladiator" placement="bottom" arrow>*/}
                                    {/*        <Link to="/options-gladiator" onClick={closeMenu} ><b>OG</b></Link>*/}
                                    {/*    </Tooltip>*/}
                                    {/*</li>*/}
                                        <li>
                                            <Tooltip title="Trader Mastery Program" placement="bottom" arrow>
                                            <Link to="/tmp" onClick={closeMenu} ><b>TMP</b></Link>
                                            </Tooltip>
                                        </li>

                                        {/*<li>*/}
                                    {/*    <Link to="/books" onClick={closeMenu} ><b>Books</b></Link>*/}
                                        {/*</li>*/}

                                        <li>
                                            {!isAdmin ?
                                                <Link to="/about-us" onClick={closeMenu}><b>About Us</b></Link>
                                                : null
                                            }
                                        </li>
                                        <li>
                                            {!isAdmin ?
                                            <Link to="/contact-us" onClick={closeMenu}><b>Contact Us</b></Link>
                                                : null
                                            }
                                        </li>

                                        <li>
                                        {(isUserLoggedIn && isAdmin) ? <Link to="/control-panel" onClick={closeMenu}><b>Control Panel</b></Link> :
                                                null
                                            }
                                        </li>
                                        <li>
                                            {isUserLoggedIn ? ("Welcome  " + userInfo.userName) : ""
                                                /*<a className="button button-primary button-wide-mobile button-sm" onClick={closeMenu} onClick={handleClickOpen}>Sign In</a>*/
                                            }
                                        </li>
                                        <li >
                                            {isUserLoggedIn ?
                                                <AccountCircle style={{ cursor: "pointer" }} onClick={profileClick} />
                                                : null
                                            }
                                            <Menu
                                                id="demo-positioned-menu"
                                                aria-labelledby="demo-positioned-button"
                                                anchorEl={anchorEl}
                                                open={profileOpen}
                                                onClose={ProfileClose}
                                            >
                                                <MenuItem onClick={logout}>Sign Out</MenuItem>
                                            </Menu>
                                        </li>

                                    </ul>
                                </div>
                            </nav>
                        </>}
                </div>
            </div>

            <Dialog
                open={open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <Login closeLogin={handleClose} isUserLoggedIn={setIsUserLoggedIn} isAdmin={setIsAdmin} ></Login>
            </Dialog>

            <Snackbar
                open={openLogoutSuccess}
                autoHideDuration={6000}
                onClose={CloseLogoutSuccess}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
                <Alert onClose={CloseLogoutSuccess} severity="success" sx={{ width: '100%' }}>
                    You have been Logged Out sucessfully!
                </Alert>
            </Snackbar>

        </header>
    );
}

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

export default Header;
