import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import axios from 'axios';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { ThemeProvider } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import CircularProgress from '@mui/material/CircularProgress';

import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import CloseIcon from '@mui/icons-material/Close';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

import ForgotPassword from './forgot-password'
import * as ApiConstants from './../../Constants/ApiConstants'
import * as ArStyles from './../../Constants/ArStyleConstants'


export default function Login(props) {
    const history = useHistory();
    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(false);

    const [values, setValues] = useState({
        email: '',
        password: '',
        showPassword: false,
    });

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };
    const handleClickShowPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
        });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const checkEmailValidation = (email) => {
        if (email === "") return "Please enter Email";
        if (/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/.test(email)) {
            return "";
        } else {

            return 'Please enter correct email adress'
        }

    };

    const [openForgotPwdWindow, setForgotPwdWindow] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const checkEmailValidationError = (email) => {
        if (email.trim() === "") {
            return true;
        }
        else if (!(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/.test(email))) {
            return true;
        }

        return false;
    };

    const handleLoginSubmit = (event) => {
        event.preventDefault();
        setIsLoading(true);

        let credentials = {
            UserName: values.email,
            Password: values.password,
        }
        axios
            .post(ApiConstants.UserApis.ValidateUserPath, credentials)
            .then(function (response) {
                if (response.status === 200) {
                    //setIsLoading(false);
                    let userInfo = response.data;
                    localStorage.setItem('userInfo', JSON.stringify(response.data));

                    dispatch({
                        type: 'UserLoggedIn'
                    });

                    //props.isUserLoggedIn(true);
                    //if (userInfo.role == "Admin") props.isAdmin(true);
                    //props.closeLogin();
                    history.push("/Dashboard");
                };
            })
            .catch(function (error) {
                setIsLoading(false);
                try {
                    if (error.response.data != undefined) {
                        setErrorMessage(error.response.data);
                    }
                    else {
                        setErrorMessage("Something went wrong!!. Please contact Admin.");
                    }
                }
                catch (e) {
                    setErrorMessage("Something went wrong!!. Please contact Admin.");
                }
            });
    };

    const handleCloseForgotPwdWindow = () => setForgotPwdWindow(false);
    const handleOpnForgotPwdWindow = () => setForgotPwdWindow(true);

    const [openResetPwdSuccess, setOpenResetPwdSuccess] = useState(false);
    const handleCloseResetPwdSuccess = () => {
        setOpenResetPwdSuccess(false);
    }
    const handleOpenResetSuccess = () => setOpenResetPwdSuccess(true);

    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    return (
        <ThemeProvider theme={ArStyles.ArTheme}>
            <Container component="main" maxWidth="xs">
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        boxShadow: 3,
                        padding: 2
                    }}
                >
                    <Avatar sx={{ m: 1 }} className="avatar-bg">
                        <LockOutlinedIcon />
                    </Avatar>
                    <h3 className="text-color-primary">Sign In</h3>
                    <Box component="form" onSubmit={handleLoginSubmit} noValidate sx={{ mt: 1 }}>

                        <TextField
                            sx={{ width: "100%" }}
                            margin="normal"
                            variant="outlined"
                            id="email"
                            label="Email Address"
                            name="email"
                            required
                            autoComplete="email"
                            error={checkEmailValidationError(values.email)}
                            onChange={handleChange('email')}
                            value={values.email}
                            helperText={checkEmailValidation(values.email)}
                        />

                        <TextField
                            sx={{ width: "100%" }}
                            margin="normal"
                            variant="outlined"
                            id="password"
                            label="Password"
                            name="password"
                            required
                            autoComplete="new-password"
                            type={values.showPassword ? 'text' : 'password'}
                            value={values.password}
                            onChange={handleChange('password')}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {values.showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>

                            }}
                            error={values.password === ""}
                            helperText={(values.password.trim() === "") ? "Please enter Password" : ""}
                        />

                        <br />

                        {(errorMessage != "") ?
                            <div className="error-message">
                                <label style={{ color: 'red' }} >{errorMessage}</label>
                            </div>
                            : null}
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            disabled={values.password.trim() === "" || checkEmailValidationError(values.email)}
                        >
                            {
                                isLoading ? <CircularProgress /> : "Sign In"
                            }
                            
                        </Button>
                        <Grid container>
                            <Grid item xs>
                                <Link onClick={handleOpnForgotPwdWindow} variant="body2" style={{ cursor: "pointer" }}>
                                    <span className="text-color-primary">Forgot password?</span>
                                </Link>
                            </Grid>
                        </Grid>
                        <br />
                    </Box>
                </Box>
            </Container>

            <Dialog
                open={openForgotPwdWindow}

                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <IconButton
                    aria-label="close"
                    onClick={handleCloseForgotPwdWindow}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>

                <ForgotPassword openResetSuccessAlert={handleOpenResetSuccess} closeForgotPassword={handleCloseForgotPwdWindow}></ForgotPassword>
            </Dialog>

            <Snackbar
                open={openResetPwdSuccess}
                autoHideDuration={6000}
                onClose={handleCloseResetPwdSuccess}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
                <Alert onClose={handleCloseResetPwdSuccess} severity="success" sx={{ width: '100%' }}>
                    Password has been reset sucessfully!
                    </Alert>
            </Snackbar>

        </ThemeProvider>

    );
}