
import React, { useState } from 'react';
import classNames from 'classnames';
import { SectionProps } from './../utils/SectionProps';



const propTypes = {
    ...SectionProps.types
}

const defaultProps = {
    ...SectionProps.defaults
}

const Books = ({
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    ...props
}) => {

    const outerClasses = classNames(
        'hero section center-content',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        className
    );

    const innerClasses = classNames(
        'hero-inner section-inner',
        topDivider && 'has-top-divider',
        bottomDivider && 'has-bottom-divider'
    );

    return (
        <section
            {...props}
            className={outerClasses}
        >
            <div className="container">
                <div className="container" style={{ minHeight: "400px" }}>
                    <h2 style={{ marginTop: "100px" }}><i>Coming soon...</i></h2>
                </div>
            </div>
        </section>
    );
}

Books.propTypes = propTypes;
Books.defaultProps = defaultProps;

export default Books;