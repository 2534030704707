import React from 'react';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';


import PropTypes from 'prop-types';
import Toolbar from '@mui/material/Toolbar'  //'@material-ui/core/Toolbar';
import { makeStyles } from '@mui/styles' //'@material-ui/core/styles';
import useScrollTrigger from '@mui/material/useScrollTrigger' //'@material-ui/core/useScrollTrigger';
import Fab from '@mui/material/Fab' //'@material-ui/core/Fab';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp' //'@material-ui/icons/KeyboardArrowUp';
import Zoom from '@mui/material/Zoom' //'@material-ui/core/Zoom';

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'fixed',
        //bottom: theme.spacing(2),
        //right: theme.spacing(2),
    },
    palette: {
        primary: {
            main: "#5658D" // This is an orange looking color
        },
        secondary: {
            main: "#ffcc80" //Another orange-ish color
        }
    },
}));

function ScrollTop(props) {
    const { children, window } = props;
    const classes = useStyles();
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
        disableHysteresis: true,
        threshold: 100,
    });

    const GoToTop = (event) => {
        const anchor = (event.target.ownerDocument || document).querySelector('#back-to-top-anchor');

        if (anchor) {
            anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    };

    return (
        <Zoom in={trigger}>
            <div onClick={GoToTop} role="presentation" className={classes.root}>
                {children}
            </div>
        </Zoom>
    );
}

ScrollTop.propTypes = {
    children: PropTypes.element.isRequired,
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};


const LayoutDefault = ({ children }, props) => (
    <>
        <Header navPosition="right" className="reveal-from-bottom" />

        <Toolbar id="back-to-top-anchor" />
        <main className="site-content">
            {children}
        </main>
        <Footer />
        <ScrollTop {...props}>
            <Fab color="primary" size="small" aria-label="scroll back to top">
                <KeyboardArrowUpIcon />
            </Fab>
        </ScrollTop>
    </>
);

export default LayoutDefault;



