
import React, { useState } from 'react';
import classNames from 'classnames';
import { SectionProps } from './../utils/SectionProps';



const propTypes = {
    ...SectionProps.types
}

const defaultProps = {
    ...SectionProps.defaults
}

const Terms = ({
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    ...props
}) => {

    const outerClasses = classNames(
        'hero section center-content',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        className
    );

    const innerClasses = classNames(
        'hero-inner section-inner',
        topDivider && 'has-top-divider',
        bottomDivider && 'has-bottom-divider'
    );

    return (
        <section
            {...props}
            className={outerClasses}
        >
            <div className="container-sm">
                <div className={innerClasses}>
                    <div className="hero-content">
                        <div className="container-sm">
                            <h3>Terms</h3>
                            <p style={{ textAlign: "justify", fontSize:"16px" }}>
                                <h5>1. BINDING EFFECT</h5>

                                This agreement (“Agreement”) is a binding agreement between you
                                (“you”) and AR Ventures, an Odisha based proprietorship company, (“Indane
                                Trader,” “Company,” “we” or “us”). By using the site at www.indanetrader.com
                                (the ‘Site’) or any information, materials, images, graphics, data, text, files, links,
                                software, messages, communications, content, organization, design, compilation,
                                magnetic translation, digital conversion, HTML, XML, Java code and other
                                content related to the Site (collectively ‘Content’) or services provided in
                                connection with the Site (the ‘Service’), you agree to abide by these Terms of Use,
                                as the Company may amend them from time to time in its sole discretion.
                                <br /><br />
                                YOU
                                AGREE THAT BY USING THE SERVICE YOU REPRESENT THAT YOU
                                ARE AT LEAST 18 YEARS OLD AND THAT YOU ARE LEGALLY ABLE TO
                                ENTER INTO THIS AGREEMENT.
                                <br /><br />
                                Notwithstanding anything else in this Agreement, the Company will not accept
                                your entering into this Agreement, or purchasing or using the Site, the Content or
                                the Service, if you are less than 18 Years of Age.

                                <h5>2. REFUND POLICY / CANCELLATION</h5>
                                Indane Trader is committed to providing each customer with exceptional service.
                                We want you to feel comfortable about transacting business with Indane
                                Trader. Due to the nature of the Indane Trader business and the accessibility of our
                                products immediately upon purchase, there is a strict 7-days refund policy, which
                                begins on the date of purchase. This 30 days refund policy is relevant to programs
                                only. Any subscription-based products such as the Indane Trader Membership,
                                Indane Trader Member promotional rates, Summit and/or event tickets are non-
                                refundable. However, you may cancel at any time by contacting Customer Support
                                via email at <a>connect@indanetrader.com</a>.

                                <h5>3. PRIVACY POLICY</h5>

                                The company respects your privacy and permits you to control the
                                treatment of your personal information. A complete statement of the Company’s
                                current privacy policy (the “Privacy Policy”) can be found by clicking here. The
                                company’s Privacy Policy is expressly incorporated into this Agreement by this
                                reference. When you are required to submit information to use or access the Site,
                                Content or Service, you must complete the registration process by providing the                                
                                information requested on the form. You agree to the terms in Company’s Privacy
                                Policy regarding the use of the information you submit.

                                <h5>4. MONTHLY SUBSCRIPTION / AUTOSHIP</h5>

                                If you purchase our products or services that are subscription-based (see
                                list below), you agree to pay, and authorize automatic recurring billing of the
                                subscription fee with your credit card, or other payment methods, until
                                subsequently cancelled by you. Your account will be charged every 30 days for
                                monthly subscriptions. You understand and agree that each automatic recurring
                                billing of the subscription fee is non-refundable and will not be prorated as the
                                service is deemed used when accessed. You authorize Company to initiate debit
                                entries from the account provided for the subscription fee, as well as any other
                                purchases made on the Site. You may cancel at any time by contacting Customer
                                Support at <a>connect@indanetrader.com</a>.

                                <h5>5. LICENSE</h5>

                                The company owns or licenses all intellectual property and other rights,
                                title, and interest in and to the Site, Content and Service, and the materials
                                accessible on the Site and Service, except as expressly provided for in the
                                Agreement, including without limitation the trademarks, copyrights and certain
                                technology used in making the Site, its Content and Service available. Except as
                                specifically allowed in this Agreement, the copying, redistribution, use or
                                publication by you of any Content or Service is strictly prohibited. We grant you a
                                limited revocable license to access and use the Site, Content and our Service for its
                                intended purposes, subject to your compliance with this Agreement, and if you are
                                an affiliate of Company, your Affiliate Agreement and Company Policies and
                                Procedures. The revocable license does not include the right to collect or use the
                                information contained on the Site or through the Service for purposes that
                                Company prohibits or to compete with Company.
                                <br /><br />
                                You acquire no ownership or other interest in, or other licenses to, any patent,
                                copyright, trademark, trade secret or other intellectual property right or to the
                                Content. You acquire no rights or licenses in or to any trademarks, service marks,
                                trade names or copyrights displayed on the Site. You may not reproduce, republish,
                                distribute, assign, sublicense, retransmit, sell, or prepare derivative works of the
                                Site or Content, or resell or make our Service available to others. All rights in and
                                to the Site, Service and our Content not expressly granted in this Agreement
                                remain in us or in our licensors.
                                <br /><br />
                                If you use the Site or our Service in a manner that exceeds the scope of this license
                                or breaches any relevant agreement, your license shall terminate immediately.

                                <h5>6. CONFIDENTIALITY</h5>
                                For the purposes of this Agreement, ‘Confidential Information’ shall be deemed to
                                include all the information and materials about the Company, the Site or the
                                Service that: (i) if in written format is marked as confidential, or (ii) if disclosed
                                verbally is noted as confidential at time of disclosure or (iii) in the absence of
                                either (i) or (ii) is information which a reasonable party would deem to be non-
                                public information and confidential, including, without limitation, all information
                                provided on or through the Site or Service, trade secrets, inventions, research
                                methods and projects, methods of compiling information, methods of creating
                                database, data processing programs, software, computer models, source and object
                                codes, product formulations, strategies and plans for future business, product and
                                service development and ideas, potential acquisitions or divestitures, marketing
                                ideas, financial information including with respect to costs, commissions, fees,
                                profits and sales, mailing lists, information concerning our affiliates and customers,
                                potential affiliates and customers and suppliers, and employee information
                                including their respective salaries, bonuses, benefits, qualifications, abilities and
                                contact information.
                                <br /><br />
                                You acknowledge and agree that the nature of the Confidential Information to
                                which you have, and will continue to have, access derives value from the fact that
                                it is not generally known and used by others in the highly competitive,
                                international industry in which the Company engages and operates.
                                You acknowledge that you are receiving the Confidential Information in
                                confidence, and you will not publish, copy or disclose any Confidential
                                Information without prior written consent from Company. You further agree that
                                you shall not attempt to reverse engineer, de-compile or try to ascertain the source
                                code to our software or any other software supplied hereunder. You acknowledge
                                that you will only use the Confidential Information to the extent necessary to use
                                the Service and promote your business in accordance with your agreements with
                                Company and that you will use best efforts to prevent unauthorized disclosure of
                                the Confidential Information to any third party.
                                You agree not to alone or in association with others use the Confidential
                                Information to (i) solicit, or facilitate any organization with which you are
                                associated in soliciting, any employee, affiliate or customer of ours to alter his, her
                                or its relationship with us; (ii) solicit for employment, hire, or engage as an
                                independent contractor, or facilitate any organization with which you are
                                associated in soliciting for employment, hire, or engagement as an independent
                                contractor, any person who was employed by or under contract with us at any time
                                during the term of this Agreement (provided, that this clause (ii) shall not apply to
                                any individual whose employment or contractual relationship with us was
                                terminated for a period of one year or longer); or (iii) solicit business from or
                                perform services for any customer, supplier, licensee, or business relation of ours;
                                induce or attempt to induce, any such person or entity to cease doing business with
                                us; or in any way interfere with the relationship between any such person or entity
                                and us.
                                <br /><br />
                                Other than as expressly provided for herein, you do not acquire any right or
                                interest, by license or otherwise, in or to the Confidential Information.
                                You agree that all originals and any copies of the Confidential Information remain
                                the property of the Company. You shall reproduce all copyright and other
                                proprietary notices, if any, in the same form that they appear on all the materials
                                provided by us, on all permitted copies of the Confidential Information made by
                                you. You agree to return all originals and copies of all Confidential Information in
                                your possession or control to us at its request.
                                <br /><br />
                                You shall be liable for any and all breaches of this Agreement and any
                                unauthorized use or disclosure of Confidential Information. If you become
                                compelled by applicable law, regulation or legal process to disclose any of the
                                Confidential Information, you shall promptly provide us with notice in order for us
                                to seek a protective order or other appropriate remedies. Further, if you become
                                compelled to disclose any of the Confidential Information, you must disclose only
                                that portion of the Confidential Information you are legally required to disclose as
                                confirmed by a legal opinion of your counsel at your expense.

                                <h5>7. USE OF THE SITE, CONTENT AND SERVICE</h5>
                                You may only use the Site, Content and Service to promote your existing business,
                                as expressly permitted in writing by us. You may not cause harm to the Site or
                                Service. Specifically, but without limitation, you may not:
                                <br />
                                (i) interfere with the
                                Site, Content or Service by using viruses, programs, or technology designed to
                                disrupt or damage any software or hardware, or which attempts to assess the
                                vulnerability of, or actually violates, any security feature;
                                <br />
                                (ii) access any content or
                                data not intended for you, or log into an account or server that you are not
                                authorized to access;
                                <br />
                                (iii) modify, create derivative works, reverse engineer,
                                decompile or disassemble any technology used to provide the Site or our Service;
                                <br />
                                (iv) use a robot, spider or other device or process to monitor the activity on or copy
                                pages from the Site or our Service, except in the operation or use of an Internet
                                ‘search engine,’ hit counters, or similar technology;
                                <br />
                                (v) collect electronic mail
                                addresses or other information from third parties by using the Site or our Service;
                                <br />
                                (vi) impersonate another person or entity;
                                <br />
                                (vii) engage in any activity that interferes
                                with another user’s ability to use or enjoy the Site, or content or our Service;
                                <br />
                                (viii)
                                assist or encourage any third party in engaging in any activity prohibited by this
                                Agreement;  <br />
                                (ix) co-brand the Site, or content or our Service;<br />
                                (x) frame the Site or
                                Service;  <br />
                                (xi) hyper-link to the Site or Service, without the express prior written
                                permission of an authorized representative of Company;  <br />
                                (xii) use the Site, Content
                                or Service, in whole or in part, for any purpose that is unlawful, immoral, or
                                prohibited by this Agreement or any applicable local, state, or federal law, rule, or
                                regulation; <br />
                                (xiii) use the Site, Content or Service in any manner that could damage,
                                disable, overburden, or impair the Site or Service;  <br />
                                (xiv) circumvent, or attempt to
                                circumvent, any security feature of the Site;  <br />
                                (xv) upload, e-mail or otherwise
                                transmit to or through the Site or Service, any advertising, promotional, or other
                                unauthorized communication, including, without limitation, ‘junk mail,’ ‘surveys,’
                                unsolicited e-mail, ‘spam,’ ‘chain letters,’ or ‘pyramid schemes;’ or  <br />
                                (xvi)
                                incorporate data from any of our databases into any emails or other ‘white pages’
                                products or services, whether browser- based, based on proprietary client-side
                                applications, or web-based, without our prior, express and written consent.
                                You agree that you will not make any derogatory statements, either oral or written,
                                or otherwise disparage us, our products, employees, services, work or employment,
                                and will take all reasonable steps to prevent others from making derogatory or
                                disparaging statements. You agree that it would be impossible, impractical or
                                extremely difficult to fix the actual damages suffered by reason of a breach of this
                                paragraph, and accordingly hereby agree that Company may determine to recover
                                damages sustained by reason of each such breach, without prejudice to Company’s
                                right to also seek injunctive or other equitable relief.
                                If you purchase any of our products or services, you agree that your use of the
                                product or service is limited by this Agreement as well.
                                Most areas of the Site are password restricted to registered users (‘Password-
                                Protected Areas’). If you have registered as an authorized user to gain access to
                                these Password-Protected Areas, you agree that you are entirely responsible for
                                maintaining the confidentiality of your password, and agree to notify us if the
                                password is lost, stolen, disclosed to an unauthorized third party, or otherwise may
                                have been compromised. You agree that you are entirely responsible for any and
                                all activities that occur under your account, including any fees that may be incurred
                                under your password-protected account, whether or not you are the individual who
                                undertakes such activities. You agree to immediately notify us of any unauthorized
                                use of your account or any other breach of security in relation to your password or
                                the Site that is known to you.
                                <br /><br />
                                All testimonials and endorsements must comply with all Indian rules and
                                guidelines and other applicable laws and regulations. Claims about a product’s
                                performance or quality must be based on actual experiences. You must disclose
                                connections between yourself and an advertiser that is unclear or unexpected to a
                                customer (for example, whether there is a financial arrangement, employment
                                arrangement, or ownership interest). Disclaimers and disclosures must be clear and
                                conspicuous when used and must be used properly to comply with Indian
                                guidelines.
                                <br /><br />
                                Statements about a product’s price and availability must be truthful and accurate.
                                Product pricing must show a product’s actual price, must be clearly and
                                conspicuously displayed, along with any material limitations (for example the cost
                                of shipping or refund restrictions). Falsely suggesting that a product previously
                                sold for a higher price is not permitted. False scarcity statements are not permitted
                                (for example, stating that supplies are limited when there is no actual limit).
                                You must:  <br />
                                (i) accurately state the amount of effort required to succeed with a
                                product or service;  <br />
                                (ii) only state that a product or service has qualifying criteria
                                when there are actual criteria that must be met; and  <br />
                                (iii) only use third party
                                trademarks and copyright protected materials with written permission.

                                <h5>8. PRIVATE TEXT “VIP CONNECTED” ALERTS AND NOTIFICATIONS</h5>
                                You may be presented with an opportunity to subscribe to our private text ‘VIP
                                Connected” alerts and notifications. When you do, please understand the
                                following:<br />
                                (i) Your phone number is 100% secure and you will never be spammed.<br />
                                (ii) Your phone number will never be shared or sold to any third-party person,
                                service, or company.<br />
                                (iii) You will only receive important VIP alerts and notifications when new
                                training becomes available or alerts about our live webinars, etc.<br />
                                (iv) You may receive up to 4 messages per week.<br />
                                (v) Message and data rates may apply. <br />
                                (vi) You may unsubscribe at any time by replying “STOP” or “HELP” for help.<br />
                                (vii) Wireless carriers are not liable for any delayed or undelivered messages.<br />

                                <h5>9. USER CONTENT</h5>

                                User Content means all content created by you, which includes but is not
                                limited to audio, video, images, photographs, logos, illustrations, animations,
                                tools, written posts, comments, data, text, software, graphics, scripts, themes,
                                and/or interactive features. Keep in mind that once you post something on a
                                blog thereby making it available on the Internet and to the public, it may be
                                practically impossible to take down all copies of it. By posting, downloading,
                                displaying, performing, transmitting, or otherwise distributing any User
                                Content to the Site or Service, you are granting us a transferrable, perpetual,
                                irrevocable, worldwide and royalty-free nonexclusive license (including the
                                right to sub-license), to use, possess, copy, transmit, publicly display,
                                distribute, sell, host, store, cache, disclose, perform, modify, edit, translate,
                                reformat, import, export and prepare derivative works of such User Content
                                through multiple tiers of distribution in any and all media now known or
                                hereafter invented (including, without limitation, the right to conform it to the
                                requirement of any networks, devices, services, or media through which the
                                Site or Services are available). The company will not pay you any
                                compensation for the use of your User Content as provided herein. We are
                                under no obligation to post or use any User Content you may provide and
                                may remove User Content at any time at our sole discretion. You agree that
                                Company may publish or otherwise disclose your name in connection with
                                your User Content. By posting User Content on the Site or Service, you
                                warrant and represent that you own the rights to the User Content or are
                                otherwise authorized to post, distribute, display, perform, transmit, or
                                otherwise distribute User Content.
                                <br /><br />
                                Except as otherwise set forth herein, you retain ownership of all intellectual
                                property rights in your User Content, and Company and/or third parties retain
                                ownership of all intellectual property rights in all Content other than User
                                Content. You retain ownership of any intellectual property, URLs, and/or
                                domain names you use or post or Service.

                                <h5>10. COMPLIANCE WITH INTELLECTUAL PROPERTY LAWS</h5>
                                When accessing the Site or using the Content or Service, you agree to obey
                                the law and to respect the intellectual property rights of others. Your use of
                                the Service, Content and Site is at all times governed by and subject to laws
                                regarding intellectual property ownership. You agree not to upload,
                                download, display, perform, transmit, or otherwise distribute any information
                                or content in violation of any third party’s copyrights, trademarks, or other
                                intellectual property or proprietary rights. You agree to abide by laws
                                regarding intellectual property ownership and use, and you shall be solely
                                responsible for any violations of any relevant laws and for any infringements
                                of third party rights caused by any content you provide or transmit, or that is
                                provided or transmitted using your user name. The burden of proving that any
                                Content does not violate any laws or third party rights rests solely with you.
                                <br /><br />
                                All trademarks, service marks, trade names and copyrights displayed on the
                                Site or in the Content are proprietary to us or their respective owners.
                                <h5>11. INAPPROPRIATE CONTENT</h5>

                                You agree not to upload, download, display, perform, transmit, or otherwise
                                distribute any material or content that <br />
                                (i) is libellous, defamatory, obscene,
                                pornographic, abusive, or threatening; or <br />
                                (ii) advocates or encourages
                                conduct that could constitute a criminal offence, give rise to civil liability, or
                                otherwise violate any applicable local, state, national, or foreign law or
                                regulation or this Agreement. The company reserves the right to terminate
                                your receipt, transmission or other distribution of any such material or
                                content using the Service, and, if applicable, to delete any such material or
                                content from its servers. The company intends to cooperate fully with any
                                law enforcement officials or agencies in the investigation of any violation of
                                this Agreement or of any applicable laws.
                                <br /><br />
                                The company reserves the right but does not assume the obligation, to
                                monitor transactions and communications that occur through the Site and
                                Service. If Company determines, in its sole and absolute discretion, that a
                                user has or will breach a term or condition of this Agreement or that such
                                transaction or communication is inappropriate, Company may cancel such
                                transaction or take any other action to restrict access to or the availability of
                                any material or content that may be considered objectionable, without any
                                liability to you or any third party.

                                <h5>12. COPYRIGHT INFRINGEMENT</h5>

                                The company has in place certain legally mandated procedures regarding
                                allegations of copyright infringement occurring on the Site, in the Content or
                                with the Service. The company has adopted a policy that provides for the
                                immediate suspension or termination of any Site or Service user who is found
                                to have infringed on the rights of the Company or a third party, or otherwise
                                violated any intellectual property laws or regulations. The company’s policy
                                is to investigate any allegations of copyright infringement brought to its
                                attention. If you have evidence, know, or have a good faith belief that your
                                rights or the rights of a third party have been violated and you want the
                                Company to delete, edit or disable the material in question, you must provide
                                the Company with all of the following information:
                                <br />
                                a. A physical or electronic signature of a person authorized to act on behalf of
                                the owner of the exclusive right that is allegedly infringed;<br />
                                b. Identification of the copyrighted work claimed to have been infringed, or if
                                multiple copyrighted works are covered by a single notification, a
                                representative list of such works;<br />
                                c. Identification of the material that is claimed to be infringed or to be the
                                subject of infringing activity and that is to be removed or access to which is
                                to be disabled, and information reasonably sufficient to permit Company to
                                locate the material;<br />
                                d. A statement that you have a good faith belief that use of the material in the
                                manner complained of is not authorized by the copyright owner, its agent, or
                                the law; and <br />
                                e. A statement that the information in the notification is accurate, and under
                                penalty of perjury, that you are authorized to act on behalf of the owner of an
                                exclusive right that is allegedly infringed.
                                For this notification to be effective, you must provide it to the Company’s
                                designated agent at:
                                <br />
                                Services will be provided by:
                                <br />

                                <b className="text-color-primary">Indane Trader</b> <br />
                                Plot no 106/219 <br />
                                Tankua<br />
                                Jeypore, Koraput<br />
                                Pin Code - 764003<br />
                                Odisha, India

                                <br />
                                Effective Date: March 15, 2022

                                <h5>13. ALLEGED VIOLATIONS</h5>

                                The company reserves the right to suspend or terminate your use of any
                                Service or the Content or Site. To ensure that Company provides a high-
                                quality experience for you and for other users of the Site, Content and
                                Service, you agree that Company or its representatives may access your
                                account and records on a case-by-case basis to investigate complaints or
                                allegations of abuse, infringement of third party rights or other unauthorized
                                uses of the Site, Content or Service. The company does not intend to disclose
                                the existence or occurrence of such an investigation unless required by law,
                                but Company reserves the right to suspend or terminate your account or your
                                access to the Site immediately, with or without prior notice to you, and
                                without liability to you, if Company believes that you have violated any of
                                this Agreement, furnished Company with false or misleading information, or
                                interfered with the use of the Site, Content or the Service by others.

                                <h5>14. FULL POWER AND AUTHORITY</h5>

                                You represent and warrant that: <br />
                                (i) you have the full power and authority to
                                enter into and perform under this Agreement; <br />
                                (ii) execution and performance
                                of this Agreement does not constitute a breach of, or conflict with, any other
                                agreement or arrangement by which you are bound; <br />
                                (iii) the terms of this
                                Agreement are legal, valid, and binding obligation, enforceable against you;
                                <br />
                                (iv) all content you create to promote Company, its Site, the Content or
                                Service was created without any contribution of any kind from Company
                                including, without limitation, editorial control or approval, that any
                                suggestions regarding content received from Company are made ‘as-is’ and
                                without any warranty; and that you have had all marketing materials created
                                by you reviewed by competent legal counsel and solely assume all
                                responsibility for it; <br />
                                (v) you are at least 18 years of age, <br />
                                (vi) your use of the
                                Site and Content is legal in, and does not violate any laws or rules of, the
                                jurisdictions in which you reside or from which you use or otherwise access
                                the Site, <br />
                                (vii) all information (if any) provided by you to us is correct,<br />
                                (viii)
                                your use of the Site, Content and Services shall be in accordance with this
                                Agreement and your Affiliate Agreement and the Policies and Procedures of

                                Company if you are an affiliate and <br />
                                (ix) you are capable of assuming, and do
                                assume, any risks related to the use of the Site, Content and Services.

                                <h5>15. DISCLAIMER OF WARRANTIES</h5>

                                THE COMPANY HEREBY MAKES NO REPRESENTATIONS AND
                                DISCLAIMS ALL WARRANTIES, ARISING OUT OF OR IN
                                CONNECTION WITH THE SITE, THE CONTENT AND THE SERVICE.
                                THIS INCLUDES, WITHOUT LIMITATION, ANY AND ALL
                                WARRANTIES EXPRESS AND IMPLIED, THE WARRANTIES OF
                                NON-INFRINGEMENT, MERCHANTABILITY, AND FITNESS FOR A
                                PARTICULAR PURPOSE; THOSE ARISING BY LAW, STATUTE,
                                USAGE OF TRADE OR COURSE OF DEALING; ANY LIABILITY
                                WITH REGARD TO THE SITE, CONTENT AND SERVICES; AND ANY
                                ACTIONS RESULTING FROM YOUR PARTICIPATION IN ANY
                                SERVICE.
                                <br /><br />
                                TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW,
                                THE SITE AND ALL CONTENT, SERVICES, SOFTWARE AND
                                PRODUCTS ARE PROVIDED ‘AS IS,’ ‘WITH ALL FAULTS,’ AND ‘AS
                                AVAILABLE.’ WE DISCLAIM ALL WARRANTIES.
                                <br /><br />
                                YOUR USE OF THE SITE, SERVICE AND CONTENT IS AT YOUR
                                SOLE RISK. ALTHOUGH OUR CONTENT MAY BE UPDATED FROM
                                TIME TO TIME, IT MAY BE OUT OF DATE AND/OR MAY CONTAIN
                                INACCURACIES OR TYPOGRAPHICAL ERRORS. WE ARE NOT
                                RESPONSIBLE FOR YOUR INABILITY OR FAILURE (FOR ANY
                                REASON) TO ACCESS THE SITE OR CONTENT OR OTHERWISE USE
                                OR RECEIVE INFORMATION OR SERVICE FROM OR REGARDING
                                THE SITE, CONTENT, OR YOUR PURCHASES FROM US. WE DO NOT
                                WARRANT THAT THE SITE OR SERVICE WILL BE COMPATIBLE
                                WITH ANY HARDWARE OR SOFTWARE SYSTEMS OR THAT THE
                                SITE OR SERVICE WILL BE UNINTERRUPTED OR ERROR FREE.
                                <br /><br />
                                YOU ASSUME THE RISK OF ANY AND ALL DAMAGE OR LOSS
                                FROM USE OF, OR INABILITY TO USE, THE SITE OR SERVICE.
                                <br /><br />
                                WE ARE NOT RESPONSIBLE OR LIABLE FOR MAINTAINING ANY
                                CONSUMER DATA OR FOR THE DELETION, CORRUPTION,
                                DESTRUCTION, DAMAGE, LOSS OR FAILURE OF ANY CONSUMER
                                DATA OR FOR ANY THIRD PARTY ACCESS TO ANY CONSUMER
                                DATA.
                                <br /><br />
                                WE MAKE NO WARRANTY OR REPRESENTATION AS TO THE
                                LEVEL OF SUCCESS, IF ANY, INDIVIDUALS MAY ACHIEVE BY
                                USING ANY OF OUR SERVICES. INDIVIDUAL RESULTS MAY VARY
                                AND DEPEND ON MANY FACTORS INCLUDING AN INDIVIDUAL’S
                                SPECIFIC FINANCIAL SITUATION, EFFORTS AND ACTIONS. YOU
                                SHOULD SEEK THE ADVICE OF QUALIFIED PROFESSIONALS SUCH
                                AS AN ACCOUNTANT, ATTORNEY AND/OR PROFESSIONAL
                                ADVISOR FOR SPECIFIC ADVICE FOR YOUR BUSINESS.

                                <h5>16. LIMITED LIABILITY</h5>

                                TO THE MAXIMUM EXTENT PERMITTED BY LAW, WE AND OUR
                                AFFILIATED PARTIES SHALL HAVE NO LIABILITY WHATSOEVER
                                FOR YOUR USE OF ANY CONTENT, THE SITE, THE SERVICE, OR
                                INFORMATION RELATED TO THE SITE OR SERVICE, AND SHALL
                                NOT BE LIABLE FOR ANY DIRECT, INDIRECT, SPECIAL,
                                INCIDENTAL, OR CONSEQUENTIAL DAMAGES (INCLUDING, BUT
                                NOT LIMITED TO, DAMAGES FOR LOSS OF BUSINESS, LOSS OF
                                PROFITS, OR LITIGATION), <br />
                                (I) ARISING FROM ANY DECISION
                                MADE OR ACTION TAKEN BY YOU IN RELIANCE UPON THE
                                CONTENT OR OUR PRODUCTS OR SERVICE, <br />
                                (II) ARISING OUT OF
                                OR IN ANY WAY CONNECTED WITH THE USE OR PERFORMANCE
                                OF THE SITE OR CONTENT, OR WITH THE DELAY OR INABILITY
                                TO USE THE SITE, CONTENT, OR RELATED SERVICE, OR FROM
                                THE USE OR MISUSE OF ANY INFORMATION, SOFTWARE,
                                PRODUCTS, SERVICES, RELATED GRAPHICS, AND CONTENT
                                OBTAINED THROUGH
                                THE SITE, <br />
                                (III) ANY INCORRECT OR MISSING INFORMATION OR
                                DATA, OR <br />
                                (IV) OTHERWISE ARISING OUT OR RESULTING FROM
                                LOSS OF YOUR DATA OR INFORMATION, WHETHER BASED ON
                                BREACH OF CONTRACT, BREACH OF WARRANTY, TORT
                                (INCLUDING, BUT NOT LIMITED TO, NEGLIGENCE), OR
                                OTHERWISE, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH
                                DAMAGES. OUR MAXIMUM LIABILITY, IF ANY, FOR ANY LOSS OR
                                DAMAGE RELATING TO OR ARISING OUT OF YOUR USE OF THE
                                SITE OR ANY CONTENT WILL NOT EXCEED THE LESSER OF YOUR
                                ACTUAL DAMAGES OR THE CHARGES PAID BY YOU TO US FOR
                                THE SITE FOR A PERIOD OF TWO MONTHS.
                                <br /><br />
                                WE ARE NOT RESPONSIBLE FOR INTERRUPTED, INACCESSIBLE
                                OR UNAVAILABLE NETWORKS, SERVERS, SATELLITES,
                                INTERNET SERVICE PROVIDERS, WEBSITES, OR OTHER
                                CONNECTIONS, OR FOR MISCOMMUNICATIONS, FAILED,
                                JUMBLED, SCRAMBLED, DELAYED, OR MISDIRECTED
                                COMPUTER, TELEPHONE OR CABLE TRANSMISSIONS, OR FOR
                                ANY TECHNICAL MALFUNCTIONS, FAILURES OR DIFFICULTIES.
                                <br /><br />
                                THE ABOVE LIMITATIONS AND EXCLUSIONS SHALL APPLY TO
                                YOU TO THE FULLEST EXTENT THAT APPLICABLE LAW PERMITS,
                                IN ALL ACTIONS OF ANY KIND, WHETHER BASED ON CONTRACT,
                                TORT (INCLUDING, WITHOUT LIMITATION, NEGLIGENCE) OR
                                ANY OTHER LEGAL OR EQUITABLE THEORY. ANY CLAUSE
                                DECLARED INVALID SHALL BE DEEMED SEVERABLE AND NOT
                                AFFECT THE VALIDITY OR ENFORCEABILITY OF THE
                                REMAINDER OF THESE TERMS OF USE.

                                <h5>17. INCOME DISCLOSURE</h5>

                                THE INCOME DISCLOSURE POSTED ON OUR WEBSITE IS
                                INCORPORATED HEREIN BY REFERENCE AND YOU HEREBY
                                REPRESENT THAT YOU HAVE READ AND UNDERSTAND IT.

                                <h5>18. ADDITIONAL RESTRICTIONS</h5>

                                YOU AGREE THAT:

                                You are the owner, director, manager or executive office of an existing
                                business, and you will use the Service, Content and Site only for purposes of
                                enhancing your existing business.
                                <br /><br />
                                You will not use the Site, the Service or the Content to start a new business.
                                You acknowledge that the Site, the Content and the Service are not intended
                                for use in starting a new business. Starting a new business involves
                                complexities that are not addressed by the Site, the Service or the Content.
                                <br /><br />
                                The Company does not and will not offer you advice regarding locations for
                                your business, provide customers or accounts for your business, or purchase
                                from you any services or products you create using the Site, the Content or
                                the Service.
                                <br /><br />
                                The Company does not, and will not, tell you or advise you how to operate
                                your business, including without limitation not providing you any sales or
                                marketing plan for your business.

                                <h5>19. RELATED SITES</h5>

                                The company has no control over, and no liability for, any third party sites or
                                materials (‘Third Party Sites’). The company works with a number of
                                partners and affiliates whose Internet sites may be linked with the Site.
                                Because Company has no control over the content and performance of these
                                Third Party Sites, Company makes no guarantees about the accuracy,
                                currency, content, or quality of the information provided by such Third Party
                                Sites, and Company assumes no responsibility for unintended, objectionable,
                                inaccurate, misleading, or unlawful content that may reside on those Third
                                Party Sites. Similarly, from time to time in connection with your use of the
                                Site, you may have access to content items (including, but not limited to,
                                websites) that are owned by third parties. You acknowledge and agree that
                                Company makes no representations, warranties or guarantees about, and
                                assumes no responsibility for, the accuracy, currency, content, or quality of
                                this Third Party Sites, and that unless expressly provided otherwise, this
                                Agreement shall govern your use of any and all Third-Party Sites.
                                <br /><br />
                                Your linking to such Third Party Sites is at your own risk. We do not
                                investigate, monitor, or check such Third Party Sites for accuracy or
                                completeness. We are not responsible for the availability of these Third-Party
                                Sites, nor are we responsible for the aesthetics, appeal, suitability to taste or
                                subjective quality of informational content, advertising, products or other
                                materials made available on or through such Third-Party Sites. We are
                                providing these links to you only as a convenience and may discontinue
                                providing such links at any time at our sole discretion without notice to you.
                                No endorsement of any third-party content, information, data, opinions,
                                advice, statements, goods, services or products is expressed or implied by any
                                information, material or content of any Third Party Site contained in, referred
                                to, included on, or linked from or to, the Site. Under no circumstances shall
                                we or any affiliated providers be held responsible or liable, directly or
                                indirectly, for any loss, injury, or damage caused or alleged to have been
                                caused to you in connection with the use of, or reliance on, any content,
                                information, data, opinions, advice, statements, goods, services, or products
                                available on such Third Party Sites. You should direct any concerns to the
                                respective Third Party Site administrator or webmaster. Any links to Third
                                Party Sites do not imply that we are legally authorized to use any trademark,
                                trade name, logo or copyright symbol displayed in or accessible through such
                                links, or that any linked Third Party Site is authorized to use any trademark,
                                trade name, logo or copyright symbol of ours.
                                <br /><br />
                                The company, its managers, or members may receive an affiliate commission
                                when you purchase some of the products or services that we recommend on
                                our Site or Service. By entering into this Agreement, you acknowledge that
                                you have been informed of such payments, consent to payments of affiliate
                                commission, and agree that such payments are fair and reasonable.

                                <h5>20. TERMINATION</h5>

                                You agree that Company may suspend or terminate your use of the Site or
                                Service, in its sole discretion, including without limitation, if Company
                                believes, in our sole and absolute discretion, that you have breached any term
                                of this Agreement or if you are an affiliate with Company, your Affiliate
                                Agreement or Company’s Policies and Procedures. You acknowledge and
                                agree that Company shall not be liable to you or any other party for said
                                suspension and termination.
                                <br /><br />
                                Upon termination, your license to use the Site, Content or Service and
                                everything accessible by or through the Site, Content or Service shall
                                terminate and the remainder of this Agreement shall survive indefinitely
                                unless and until we chose to terminate it.
                                <br /><br />
                                Upon termination of any part of this Agreement for any reason, we shall
                                make reasonable efforts to ensure that your User Content is inaccessible and
                                cease use of it; however, you acknowledge and agree that: <br />
                                (i) caching of,
                                copies of, or references to the User Content may not be immediately removed
                                or possible to remove; <br />
                                (ii) such removed User Content may continue in
                                backups (not available to others) for a relatively short period of time; and <br />
                                (iii) due to re-blogging capabilities, such removed User Content may continue to
                                be available (and stored on our servers) through the accounts of other
                                subscribers.

                                <h5>22. INDEMNITY</h5>

                                You agree to indemnify, defend, and hold Company, its managers, members,
                                officers, directors, employees, consultants, agents, and representatives
                                harmless from and against any and all actions, claims, demands, proceedings,
                                liabilities, damages, judgments, settlements, fines, penalties, costs, and
                                expenses, including attorney’s fees and related costs, which <br />
                                (i) arise or in part
                                from your negligence or wrongful act(s) or omission(s); <br />
                                (ii) arise from or are
                                related to a breach you have any express warranty contained herein; or <br />
                                (iii)
                                failure to comply with this Agreement. We have no duty to reimburse,
                                defend, indemnify, or hold you harmless resulting from, relating to, or arising
                                out of, this Agreement, the Site, Content or Service, or your access to or use
                                of the Site, Content or Service.
                                <br />
                                If an action is brought against Company in respect to any allegation for which
                                indemnity may be sought, Company will promptly notify you of any such
                                claim of which it becomes aware and will: <br />
                                (i) provide reasonable cooperation
                                to you at your expense in connection with the defence or settlement of any
                                such claim; and <br />
                                (ii) be entitled to participate at its own expense in the defence
                                of any such claim.
                                <br />
                                The company agrees that you will have sole and exclusive control over the
                                defence and settlement of any such third party claim. However, you agree not
                                to acquiesce to any judgment or enter into any settlement that adversely
                                affects the Company’s rights or interests without the prior written consent of
                                the Company.

                                <h5>23. GOVERNING LAW</h5>

                                This Agreement shall be construed in accordance with and governed by the
                                laws of India, without reference to their rules regarding conflicts of law. You
                                hereby irrevocably consent to the exclusive jurisdiction of the state or courts
                                in Odisha in all disputes arising out of or related to the use of the Site,
                                Content or Service. In the event of a dispute between an affiliate and
                                Company arising from or relating to the Agreement, or the rights and
                                obligations of either party, the parties shall attempt in good faith to resolve
                                the dispute through confidential, nonbinding mediation as more fully
                                described in the Policies and Procedures. The company shall not be obligated
                                to engage in mediation as a prerequisite to disciplinary action against an
                                Affiliate. If the parties are unsuccessful in resolving their dispute through
                                mediation, the dispute shall be settled totally and finally by arbitration as
                                more fully described in the Policies and Procedures.
                                <br /><br />
                                Notwithstanding the foregoing, either party may bring an action before the
                                courts seeking a restraining order, temporary or permanent injunction, or
                                other equitable relief to protect its intellectual property rights, including but
                                not limited to customer and/or affiliate lists as well as other trade secrets,
                                trademarks, trade names, patents, and copyrights. The parties may also seek
                                judicial enforcement of an arbitration award. You waive any requirement of
                                posting a bond in connection with such an action brought against you.
                                <br /><br />
                                These Terms of Use do not limit any rights or remedies that we or our
                                suppliers, licensors or other similar entities, may have under trade secret,
                                copyright, patent, trademark or other laws.

                                <h5>24. SEVERABILITY WAIVER </h5>

                                If for whatever reason, a court of competent jurisdiction finds any term or
                                condition in this Agreement to be unenforceable, all other terms and conditions
                                will remain unaffected and in full force and effect. No waiver of any breach of any
                                provision of this Agreement shall constitute a waiver of any prior, concurrent, or
                                subsequent breach of the same or any other provisions hereof, and no waiver shall
                                be effective unless made in writing and signed by an authorized representative of
                                the waiving party.

                                <h5>25. MODIFICATIONS</h5>

                                Company may, in its sole discretion and without prior notice, <br />
                                (i) revise this
                                Agreement; <br />
                                (ii) modify the Site, Content or the Service, and <br />
                                (iii) discontinue the
                                Site, Content or Service at any time for any reason. Company shall post any
                                revision to this Agreement to the Site, and the revision shall be effective
                                immediately upon such positing. In the event of substantive changes to this
                                Agreement, the new terms will be posted to the Site. If any modification is
                                unacceptable to you, your only recourse is not to use the Site and Service and to
                                request an immediate termination of your membership. Your continued use of the
                                Site, Content or the Service following positing of a change notice or new
                                Agreement on the Site will constitute binding acceptance of the changes.

                                <h5>26. MISCELLANEOUS</h5>

                                This Agreement will be binding upon each party hereto and its successors and
                                permitted assigns. This Agreement will not be assignable or transferable by you
                                without Company’s prior written consent. This Agreement, and the related policy
                                statements referred to herein, contains the entire understanding of the parties
                                regarding the use of the Site, Content and Service, and supersedes all prior and
                                <br /><br />
                                contemporaneous agreements and understandings between the parties regarding the
                                same. Any rights not expressly granted herein are reserved. No agency,
                                partnership, joint venture or employee-employer relationship is intended or created
                                by this Agreement. The provisions of this Agreement addressing disclaimers of
                                representations and warranties, indemnity obligations, intellectual property and
                                governing law shall survive the termination of this Agreement.
                                <br /><br />
                                <b>The Vision of this Community is to help people to achieve their FINANCIAL FREEDOM.</b>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

Terms.propTypes = propTypes;
Terms.defaultProps = defaultProps;

export default Terms;