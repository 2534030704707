
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';

import CoreValuesImg from '../../assets/images/core-values.png'

const propTypes = {
    ...SectionProps.types
}

const defaultProps = {
    ...SectionProps.defaults
}

const AboutUs = ({
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    ...props
}) => {

    const outerClasses = classNames(
        'hero section center-content',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        className
    );

    const innerClasses = classNames(
        'hero-inner section-inner',
        topDivider && 'has-top-divider',
        bottomDivider && 'has-bottom-divider'
    );

    useEffect(() => {
        const anchor = (document).querySelector('#back-to-top-anchor');

        if (anchor) {
            anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    });

    return (
        <section
            {...props}
            className={outerClasses}
        >
            <div className="container-sm">
                <div className={innerClasses}>
                    <div className="hero-content">

                        <div>
                            <h3 className="text-color-primary">Mission</h3>
                            <p>
                                With the vision to provide the highest standard of powerful knowledge on World Class Trading in a simplest way. Our mission begins with countries biggest Stock Market movement to coach
                               
                                <b className="text-color-primary"> 1 Million </b>
                                
                                people to take full control of their financial future.
                            </p>

                        </div>

                        <br />

                        <div>
                            <h3 className="text-color-primary">Core Values</h3>
                            <img src={CoreValuesImg} alt="Values" />
                            

                        </div>

                        {/*<br />*/}

                        {/*<div>*/}
                        {/*    <h3 className="text-color-primary" >Mentor</h3>*/}
                        {/*    <h4>Hi, my name is Santosh Choudhury</h4>*/}
                        {/*    <p><b>My Journey from a Plant Engineer to Successful Trader and Trainer</b></p>*/}
                        {/*    <p>*/}
                        {/*        As said by Colin R. Davis, <i>“The Road to Success and the Road to Failure are almost exactly the same”</i> and I chose the road to Success. */}
                        {/*    </p>*/}
                        {/*    <p>*/}
                        {/*        For about 12 years I started my career from Call Centre Executive to Plant Engineer. Life was on a difficult path but never stopped hustling or dreaming to become a Successful Trader.*/}
                        {/*    </p>*/}
                        {/*    <p>*/}
                        {/*        Later, I chose to quit my career at the age of 34 and pursue my passion for Stock Market Trading and Training.*/}
                        {/*    </p>*/}
                        {/*    <p>*/}
                        {/*        <b>My first exposure to the Stock Market was in 2013</b>, I started as a novice Investor by inspiring from Warren Buffet videos from the internet then become trader in the Stock Market and lost all my savings in no time, but somehow, I realized that I had a passion for trading*/}
                        {/*    </p>*/}
                        {/*    <p>*/}
                        {/*        <b>I completely failed in the first attempt</b>, then started on developing the skills required for stock trading, by spending a lot of money from taking loans and attending seminars and courses, I finally bounced back into stock market with full confidence.*/}
                        {/*    </p>*/}
                        {/*    <p>*/}
                        {/*        In these last 8 years, I had worked on many strategies, but they were not giving me any good results, later when I started working on Price Action, mindset and some advanced tools and software. I found exponential results by back-testing all the strategies.*/}
                        {/*    </p>                            */}
                        {/*</div>*/}

                        {/*<br/>*/}

                        {/*<div>*/}
                        {/*    <h3 className="text-color-primary">Carrier Growth</h3>*/}
                        {/*    <p>*/}
                        {/*        After implementing the strategies which I learned from the Top Traders in the World, I was so confident that I decided to take up Trading as a full-time profession. I knew that I had an edge and could perform well in almost all kinds of markets.*/}
                        {/*    </p>*/}

                        {/*    <p>*/}
                        {/*        <b>I quit my job in the year 2020</b>, I am a full-time trader & trainer now, I can trade on all kind of markets like Cash, Future, Options, Currency and Commodities. But actively trade on Stock Futures and Options only due to volatility and liquidity.*/}
                        {/*    </p>*/}

                        {/*    <p>*/}
                        {/*        I realised that very few retail traders have the proficiency in trading and almost all retails traders are losing money due to lack of mindset, knowledge, and trading skills. I have started coaching people on trading. iCAT (I can also Trade) is a simplified program designed in such a way a secondary school student can easily learn trading stocks, MOT (Master in Options Trading) is a program consists of bundle of strategies to trade Options in all kinds of markets. FTT (Full Time Trader) is a 9-month long mentorship program.*/}
                        {/*    </p>*/}
                        {/*</div>*/}

                        {/*<br />*/}

                        {/*<div>*/}
                        {/*    <h3 className="text-color-primary">Certification</h3>*/}
                        {/*    <p>*/}
                        {/*        I got certification on <i>Mutual Fund Distributor</i> and <i>Equity Derivatives</i> from National Institute of Security Markets (NISM).*/}
                        {/*    </p>*/}
                        {/*</div>*/}

                    </div>
                </div>
            </div>
        </section>
    );
}

AboutUs.propTypes = propTypes;
AboutUs.defaultProps = defaultProps;

export default AboutUs;