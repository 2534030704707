
export const ContactDetails = {
    Phone: "+91 9437 527 284",
    Email: "connect@indanetrader.com"
};

export const IcatEvent = {
    EventDate: "",   
    EventLink: "https://rzp.io/l/icanalsotrade", //"https://rzp.io/l/UoKekRiEd"
    EventTime: "7:00 PM to 10:00 PM",
    EventPrice:"₹25000/-",
    EventPriceDiscounted: "₹5100/-"
};

export const MotEvent = {
    EventDate: "",
    EventLink: ""
};

export const FttEvent = {
    EventDate: "",
    EventLink: ""
};

export const ArCourses = [
    "iCAT",
    "OD",
    "OG",
    "TMP"
]
export const ArRoles = [
    "App User",
    "Admin"
]

export const ArCourseStatus = [
    "Upcoming",
    "Completed"
]